import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

interface DrawerHeaderProps {
  handleDeleteAddress: () => void;
  type: 'add' | 'change';
}

const DrawerHeader: FC<DrawerHeaderProps> = ({ handleDeleteAddress, type }) => {
  const { LOGO_LIGHT } = useAppSelector(selectAppSettings);
  return (
    <>
      {type === 'add' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ alignSelf: 'flex-end', marginBottom: '40px' }}>
            <Box component="img" src={LOGO_LIGHT} />
          </Box>
          <Typography
            sx={{ fontSize: '24px', lineHeight: '29px' }}
            variant="h4"
          >
            Delivery address
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ alignSelf: 'flex-end', marginBottom: '40px' }}>
            <Box component="img" src={LOGO_LIGHT} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '28px' }}
              variant="h4"
            >
              Delivery address
            </Typography>
            <Button
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                textTransform: 'capitalize',
                padding: '3px 10px',
              }}
              onClick={handleDeleteAddress}
            >
              Delete
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DrawerHeader;
