import React, { FC } from 'react';
import { Banner, Booking, Info } from 'components/reserve';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectReservePageData } from 'store/selectors/pageSelectors';
import { useScrollToBlock } from 'hooks';

const Reserve: FC = () => {
  useScrollToBlock();
  const {
    info_title,
    info_text,
    info_image,
    page_title,
    banner_image,
    form_image,
  } = useAppSelector(selectReservePageData);

  return (
    <>
      <Banner title={page_title} src={banner_image} />
      <Booking src={form_image} />
      <Info title={info_title} text={info_text} src={info_image} />
    </>
  );
};

export default Reserve;
