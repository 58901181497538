import React, { FC } from 'react';
import { Box } from '@mui/material';
import { MenuProduct } from 'types/Menu';
import { Regular, Combo } from 'components/modals/ProductModal/Drawer/Content';

interface ContentProps {
  isCombo: boolean;
  productData: MenuProduct;
  selectedDescription: string;
}

const Content: FC<ContentProps> = ({
  isCombo,
  productData,
  selectedDescription,
}) => {
  return (
    <Box>
      {isCombo ? (
        <Combo data={productData} selectedDescription={selectedDescription} />
      ) : (
        <Regular data={productData} />
      )}
    </Box>
  );
};

export default Content;
