import React, { FC, memo } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Parser from 'html-react-parser';

interface WelcomeProps {
  title: string;
  text: string;
}

const Welcome: FC<WelcomeProps> = ({ title, text }) => {
  return (
    <Container>
      <Box
        sx={{
          marginBottom: { xs: '20px', sm: '25px', md: '32px' },
          paddingTop: { xs: '30px', sm: '0px' },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: { xs: '20px', md: '30px' },
            lineHeight: { xs: '23px', md: '34px' },
            fontWeight: '400',
            textAlign: { xs: 'left', sm: 'center' },
            marginBottom: { xs: '13px', md: '32px' },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            marginBottom: '30px',
            fontWeight: '300',
            fontSize: { xs: '14px', md: '21px' },
            lineHeight: { xs: '18px', md: '22px' },
          }}
        >
          {Parser(text)}
        </Typography>
      </Box>
    </Container>
  );
};

export default memo(Welcome);
