import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectSocialMediaReducer = (state: RootState) => state.socialMediaReducer;
/**
 * Returns social media
 */
export const selectSocialMedia = createSelector(
  [selectSocialMediaReducer],
  (socialMediaReducer) => socialMediaReducer.socialMedia
);
