import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CalculateBasketParams, CalculatedBasket } from 'types/Basket';

export const calculateBasket = createAsyncThunk(
  'basket/calculateBasket',
  async (params: CalculateBasketParams, thunkAPI) => {
    const { api_key, platform, brand_id, body } = params;
    try {
      const response = await axios.post<CalculatedBasket>(
        `${process.env.REACT_APP_V3}/order/calculate_basket/`,
        body,
        {
          params: {
            api_key,
            platform,
            brand_id,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось сформировать корзину');
    }
  }
);
