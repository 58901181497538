import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectCityReducer = (state: RootState) => state.cityReducer;
const selectLocationCityId = (state: RootState) =>
  state.appSettingsReducer.city_id;
/**
 * Returns cities
 */
export const selectCities = createSelector(
  [selectCityReducer],
  (cityReducer) => cityReducer.cities
);
/**
 * Select city data for the select field
 */
export const selectCitiesDataForSelectField = createSelector(
  [selectCityReducer],
  (cityReducer) =>
    cityReducer.cities.map((city) => {
      return { label: city.name, value: city.id };
    })
);
/**
 * Select the data of the selected location city
 */
export const selectLocationCity = createSelector(
  [selectCityReducer, selectLocationCityId],
  (cityReducer, cityId) =>
    cityReducer.cities.filter((city) => city.id === cityId)[0]
);
/**
 * Select a city name by id
 */
export const selectCityNameById = (id: string | number | undefined) =>
  createSelector([selectCityReducer], (citiesReducer) => {
    const selectedCity = citiesReducer.cities.filter(
      (city) => city.id === Number(id)
    );
    if (selectedCity.length > 0) {
      return selectedCity[0].name;
    }
    return '';
  });
/**
 * Select city longitude/latitude
 */
export const selectSelectedCityLongLat = createSelector(
  [selectCityReducer, selectLocationCityId],
  (cityReducer, cityId) => {
    const selectedCity = cityReducer.cities.filter(
      (city) => city.id === cityId
    )[0];

    return {
      longitude: selectedCity?.longitude,
      latitude: selectedCity?.latitude,
    };
  }
);
