import React, { FC } from 'react';
import {
  CircularProgress,
  InputAdornment,
  TextField as MUITextField,
} from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface TextFieldProps {
  control: Control<FieldValues>;
  name: string;
  label?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  requiredText?: string;
  mask?: string;
  maskPlaceholder?: string | null;
  pattern?: RegExp;
  defaultValue?: string | number | null;
  loading?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  disabled?: boolean;
}

const TextField: FC<TextFieldProps> = ({
  control,
  name,
  label,
  required,
  size,
  requiredText = '',
  mask,
  maskPlaceholder,
  pattern,
  defaultValue,
  loading,
  type,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputMask
          mask={mask || ''}
          maskPlaceholder={maskPlaceholder}
          onChange={onChange}
          value={value || ''}
          alwaysShowMask={false}
          disabled={disabled}
        >
          <MUITextField
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: size === 'small' ? '11px 14px' : '13.5px 14px',
              },
              '& .MuiInputLabel-root': {
                color: '#BDBDBD',
                lineHeight: size === 'small' ? '28px' : '20px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'secondary.main',
              },
              '& .MuiInputLabel-shrink': {
                color: 'secondary.main',
                backgroundColor: 'primary.main',
                padding: '0px 7px',
                left: '-5px',
                fontWeight: '600',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '& fieldset': {
                  border: '2px solid',
                  borderColor: 'primary.main',
                },
                '&:hover fieldset': {
                  borderColor: 'secondary.main',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'secondary.main',
                },
              },
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                top: '45px',
                left: '0',
              },
            }}
            fullWidth
            size={size}
            label={label || ''}
            variant="outlined"
            error={!!error}
            type={type}
            helperText={error ? error.message : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading && <CircularProgress size={25} color="primary" />}
                </InputAdornment>
              ),
            }}
          />
        </InputMask>
      )}
      rules={{
        required: required || requiredText,
        pattern,
      }}
    />
  );
};

export default TextField;
