import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateOrder,
  CreateOrderAddress,
  CreateOrderClient,
  ResponseCreateOrder,
  OrderPromotion,
  OrderHistoryItem,
} from 'types/Order';
import { createOrder, getOrderHistory } from './ActionCreators';

interface OrderState {
  order: CreateOrder;
  orderHistory: OrderHistoryItem[];
  orderResponse: ResponseCreateOrder;
  isLoading: boolean;
  error: string;
}

const initialState: OrderState = {
  order: {
    delivery_type: 0,
    platform_type: 2,
    change_from: 0,
    order_type: 0,
    call_back: 0,
    cutlery: 0,
    comment: '',
    pickup_time: null,
    client: null,
    address: null,
    payment: {
      payment_type: 0,
      points: 0,
    },
    restaurant_id: null,
    basket: [],
    actions: [],
  },
  orderHistory: [],
  orderResponse: {
    data: null,
    reason: '',
    success: false,
    message_type: 0,
    message: '',
  },
  isLoading: false,
  error: '',
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    selectDeliveryType: (state, action: PayloadAction<number>) => {
      state.order.delivery_type = action.payload;
    },
    addClientData: (state, action: PayloadAction<CreateOrderClient>) => {
      state.order.client = action.payload;
    },
    addAddress: (state, action: PayloadAction<CreateOrderAddress>) => {
      state.order.address = action.payload;
    },
    removeAddress: (state) => {
      state.order.address = null;
    },
    selectPaymentType: (state, action: PayloadAction<number>) => {
      state.order.payment.payment_type = action.payload;
    },
    addPoints: (state, action: PayloadAction<number>) => {
      state.order.payment.points = action.payload;
    },
    addBasket: (state, action: PayloadAction<any>) => {
      state.order.basket = action.payload;
    },
    addRestaurant: (state, action: PayloadAction<number>) => {
      state.order.restaurant_id = action.payload;
    },
    removeRestaurant: (state) => {
      state.order.restaurant_id = null;
    },
    addPromotion: (state, action: PayloadAction<OrderPromotion>) => {
      const isPromotion = state.order.actions.find(
        (promotion) => promotion.action_id === action.payload.action_id
      );
      if (!isPromotion) {
        state.order.actions.push(action.payload);
      }
    },
    removePromotion: (state, action: PayloadAction<number>) => {
      state.order.actions = state.order.actions.filter(
        (promotion) => promotion.action_id !== action.payload
      );
    },
    addCutlery: (state) => {
      state.order.cutlery += 1;
    },
    removeCutlery: (state) => {
      if (state.order.cutlery > 0) {
        state.order.cutlery -= 1;
      }
    },
    addPickupTime: (state, action: PayloadAction<string>) => {
      state.order.pickup_time = action.payload;
    },
    addComment: (state, action: PayloadAction<string>) => {
      state.order.comment = action.payload;
    },
    clearOrder: (state) => {
      state.order = {
        delivery_type: 0,
        platform_type: 2,
        change_from: 0,
        order_type: 0,
        call_back: 0,
        cutlery: 0,
        comment: '',
        pickup_time: null,
        client: null,
        address: null,
        payment: {
          payment_type: 0,
          points: 0,
        },
        restaurant_id: null,
        basket: [],
        actions: [],
      };
    },
    clearBonuses: (state) => {
      state.order.payment.points = 0;
    },
  },
  extraReducers: {
    [createOrder.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseCreateOrder>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.orderResponse = action.payload;
    },
    [createOrder.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createOrder.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getOrderHistory.fulfilled.type]: (
      state,
      action: PayloadAction<OrderHistoryItem[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.orderHistory = action.payload;
    },
    [getOrderHistory.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getOrderHistory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default orderSlice.reducer;
