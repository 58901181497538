import React, { FC, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useWorkTime } from 'hooks';
import { DatePicker, SelectField } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectServerTime } from 'store/selectors/appSelectors';
import { selectLocationCity } from 'store/selectors/citySelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';

const DateTimePicker: FC = () => {
  const dispatch = useAppDispatch();
  const { addPickupTime } = orderSlice.actions;
  const { control, watch } = useFormContext();
  const dayValue = watch('day');
  const timeValue = watch('time');
  const serverTime = useAppSelector(selectServerTime);
  const selectedLocationCity = useAppSelector(selectLocationCity);
  const availableTime = useWorkTime(dayValue, selectedLocationCity);
  const { t } = useTranslation();

  const dateIsValid = (date: Date) => {
    return !Number.isNaN(new Date(date).getTime());
  };

  useEffect(() => {
    const currentTime = new Date(dayValue);
    const parts = timeValue?.match(/\b\d+\b/g);
    const hours = parts && parts[0];
    const minutes = parts && parts[1];
    currentTime.setHours(Number(hours));
    currentTime.setMinutes(Number(minutes));

    if (dateIsValid(currentTime)) {
      dispatch(addPickupTime(currentTime.toISOString()));
    }
  }, [dayValue, timeValue]);

  return (
    <Stack
      direction="row"
      sx={{ marginBottom: { xs: '13px', sm: '30px' }, width: '100%' }}
      spacing={1.6}
    >
      <DatePicker
        control={control}
        name="day"
        label={t('day')}
        minDate={new Date(serverTime)}
        defaultValue={new Date(serverTime)}
        required
      />

      <SelectField
        control={control}
        name="time"
        label={t('time')}
        required
        selectItem={availableTime}
        defaultValue=""
      />
    </Stack>
  );
};

export default DateTimePicker;
