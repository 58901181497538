import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import Parser from 'html-react-parser';

interface AdvantageProps {
  title: string;
  text: string;
  icon: string;
}

const Advantage: FC<AdvantageProps> = ({ title, text, icon }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: { xs: '8px', sm: '15px' },
        }}
      >
        <Box sx={{ marginRight: '20px' }} component="img" src={icon} />
        <Typography
          sx={{
            fontSize: { xs: '20px', sm: '23px' },
            lineHeight: { xs: '23px', sm: '27px' },
            fontWeight: '300',
            maxWidth: { xs: 'auto', sm: '120px' },
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '20px' },
          lineHeight: { xs: '16px', sm: '23px' },
          fontWeight: '300',
        }}
      >
        {Parser(text)}
      </Typography>
    </Box>
  );
};

export default memo(Advantage);
