import React, { useState, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { AddressModal } from 'components/modals';
import { useTranslation } from 'react-i18next';

const AddAddressButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  return (
    <>
      <Button
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          border: 'none',
          background: 'transparent',
          padding: '2px',
          textDecoration: 'none',
          textTransform: 'inherit',
        }}
        onClick={handleOpenModal}
      >
        <AddIcon color="secondary" />
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '26px' },
            color: 'secondary.main',
            fontWeight: '300',
            marginLeft: '5px',
          }}
        >
          {t('add_new_address')}
        </Typography>
      </Button>
      <AddressModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type="add"
      />
    </>
  );
};

export default AddAddressButton;
