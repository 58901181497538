import React, { FC } from 'react';
import { Typography, Box } from '@mui/material';
import { useSrcImage } from 'hooks';
import { MenuProduct } from 'types/Menu';
import {
  CookingOptions,
  GroupModifiers,
  Ingredients,
  Options,
} from 'components/modals/ProductModal/Buttons';
import { RegularSlider } from 'components/modals/ProductModal/Drawer/Content/Regular';

interface RegularProps {
  data: MenuProduct;
}

const Regular: FC<RegularProps> = ({ data }) => {
  const {
    src,
    name,
    options,
    cooking_options,
    ingredients,
    group_modifiers,
    description,
    additional_images,
  } = data;
  const image = useSrcImage(src);

  return (
    <Box>
      {additional_images.length > 0 ? (
        <RegularSlider images={additional_images} />
      ) : (
        <Box sx={{ height: '320px' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            component="img"
            src={image}
          />
        </Box>
      )}

      <Box sx={{ padding: '20px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: '400',
            marginBottom: '20px',
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '300',
            marginBottom: '30px',
          }}
        >
          {description}
        </Typography>
        {options.length > 1 && <Options options={options} />}
        {cooking_options.length > 0 && (
          <CookingOptions cookingOptions={cooking_options} />
        )}
        {ingredients.length > 0 && <Ingredients ingredients={ingredients} />}
        {group_modifiers.length > 0 && (
          <GroupModifiers groupModifiers={group_modifiers} />
        )}
      </Box>
    </Box>
  );
};

export default Regular;
