import React, { FC } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { AuthSteps } from 'components/modals/AuthModal/Modal';
import { FormProvider } from 'react-hook-form';

interface ModalProps {
  isLoadingName: boolean;
  step: number;
  isTimer: boolean;
  timer: number;
  currentTypeData: string;
  methods: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  nextStep: () => void;
  prevStep: () => void;
  handleGetCode: () => void;
}

const Modal: FC<ModalProps> = ({
  isLoadingName,
  step,
  isTimer,
  timer,
  currentTypeData,
  methods,
  isOpen,
  onClose,
  onSubmit,
  nextStep,
  prevStep,
  handleGetCode,
}) => {
  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          padding: '32px',
          width: '540px',
          height: '432px',
          overflowY: 'inherit',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={onClose}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>

      <Box component={FormProvider} {...methods}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <AuthSteps
            isLoadingName={isLoadingName}
            step={step}
            isTimer={isTimer}
            timer={timer}
            currentTypeData={currentTypeData}
            onClose={onClose}
            nextStep={nextStep}
            prevStep={prevStep}
            handleGetCode={handleGetCode}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
