import { Box, Container } from '@mui/material';
import React, { FC } from 'react';
import ErrorMessage from 'components/generic/ErrorMessage/ErrorMessage';

/**
 * Page where customer is redirected in case of an error
 */
const ErrorPage: FC = () => {
  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ErrorMessage />
        </Box>
      </Container>
    </Box>
  );
};

export default ErrorPage;
