import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowLeft as ArrowLeft,
  KeyboardArrowUp as ArrowUp,
  KeyboardArrowRight as ArrowRight,
} from '@mui/icons-material';

interface BackButtonProps {
  direction: 'left' | 'right' | 'up' | 'down';
}

const BackButton: FC<BackButtonProps> = ({ direction }) => {
  const directionIcon = (directionValue: string) => {
    switch (directionValue) {
      case 'right':
        return <ArrowRight fontSize="large" color="secondary" />;
      case 'up':
        return <ArrowUp fontSize="large" color="secondary" />;
      case 'down':
        return <ArrowDown fontSize="large" color="secondary" />;
      default:
        return <ArrowLeft fontSize="large" color="secondary" />;
    }
  };
  return (
    <IconButton
      sx={{
        display: { xs: 'inline-flex', md: 'none' },
        width: '50px',
        height: '50px',
        '&.MuiButtonBase-root': {
          backgroundColor: 'primary.main',
        },
        border: '3px solid',
        borderColor: '#fff',
      }}
    >
      {directionIcon(direction)}
    </IconButton>
  );
};

export default BackButton;
