import React, { FC, useEffect, useState } from 'react';
import { AutocompleteField, SelectField, TextField } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAddressById } from 'store/selectors/addressSelectors';
import {
  selectCitiesDataForSelectField,
  selectLocationCity,
} from 'store/selectors/citySelectors';
import { useCookies } from 'react-cookie';
import { KladrResponse } from 'types/Kladr';
import axios from 'axios';
import { useDebounce } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectAppSettings } from 'store/selectors/appSelectors';

interface AddressFormRUProps {
  addressId?: number;
}

const AddressFormRU: FC<AddressFormRUProps> = ({ addressId }) => {
  const { control, watch } = useFormContext();
  const [cookies] = useCookies(['locality']);
  const streetValue = watch('street');
  const cityValue = watch('city');
  const citiesData = useAppSelector(selectCitiesDataForSelectField);
  const defaultFormValues = useAppSelector(selectAddressById(addressId));
  const { KLADR_ACCESS_TOKEN, IS_APARTMENT_REQUIRED } =
    useAppSelector(selectAppSettings);
  const selectedLocationCity = useAppSelector(selectLocationCity);
  const { t } = useTranslation();
  const [options, setOptions] = useState<KladrResponse>({
    result: [],
    searchContext: {
      cityId: '',
      contentType: '',
      limit: 0,
      query: null,
    },
  });

  const debouncedValue = useDebounce<string>(streetValue, 200);

  useEffect(() => {
    let isMounted = true;
    const getKladrStreet = () => {
      try {
        axios
          .get('https://kladr-api.ru/api.php', {
            params: {
              cityId: selectedLocationCity?.okod,
              contentType: 'street',
              parentType: 'city',
              query: debouncedValue,
              limit: '5',
              parentId: selectedLocationCity?.okod,
            },
          })
          .then((res) => {
            if (isMounted) {
              setOptions(res.data);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    getKladrStreet();
    return () => {
      isMounted = false;
    };
  }, [debouncedValue]);

  useEffect(() => {
    axios
      .get('https://kladr-api.ru/api.php', {
        params: {
          cityId: selectedLocationCity?.okod,
          contentType: 'street',
          parentType: 'city',
          query: debouncedValue,
          limit: '5',
          parentId: selectedLocationCity?.okod,
        },
      })
      .then((res) => {
        console.log(res);
      });
  }, [debouncedValue]);

  return (
    <Box>
      <Box sx={{ marginBottom: '20px' }}>
        <TextField
          control={control}
          name="name"
          label="Short address name"
          defaultValue={defaultFormValues?.name || null}
          size="small"
        />
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <SelectField
          control={control}
          name="city"
          selectItem={citiesData}
          label="Town/City"
          defaultValue={defaultFormValues?.city || cookies.locality}
          size="small"
          required
        />
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <AutocompleteField
          control={control}
          name="street"
          label={t('street')}
          size="small"
          defaultValue=""
          options={[]}
        />
        {/* <TextField
          control={control}
          name="street"
          label="Street address"
          size="small"
          defaultValue={defaultFormValues?.street || null}
          required
        /> */}
      </Box>
      <Stack sx={{ marginBottom: '20px' }} direction="row" spacing={2}>
        <TextField
          control={control}
          name="apartment"
          label="Apt/Suit/Office Nr"
          size="small"
          required={IS_APARTMENT_REQUIRED}
          pattern={/^[0-9]+$/}
          defaultValue={defaultFormValues?.apartment || null}
        />
        <TextField
          control={control}
          name="entrance"
          label="Подъезд"
          size="small"
          pattern={/^[0-9]+$/}
          defaultValue={defaultFormValues?.entrance || null}
        />
      </Stack>
      <Stack sx={{ marginBottom: '20px' }} direction="row" spacing={2}>
        <Box sx={{ width: '30%' }}>
          <TextField
            control={control}
            name="floor"
            label="Floor Nr"
            size="small"
            pattern={/^[0-9]+$/}
            defaultValue={defaultFormValues?.floor || null}
          />
        </Box>
        <Box>
          <TextField
            control={control}
            name="house"
            label="House Nr"
            size="small"
            required
            pattern={/^[0-9]+$/}
            defaultValue={defaultFormValues?.house || null}
          />
        </Box>
        <Box sx={{ width: '30%' }}>
          <TextField
            control={control}
            name="corpus"
            label="Корпус"
            size="small"
            pattern={/^[0-9]+$/}
            defaultValue={defaultFormValues?.corpus || null}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default AddressFormRU;
