import React, { FC, useCallback, memo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { ItemImage } from 'components/basket/BasketList/BasketItem';
import { usePrice } from 'hooks';
import { useTranslation } from 'react-i18next';

interface PromoProps {
  id: number;
  price: number;
  src: string;
  title: string;
  description: string;
}

const Promo: FC<PromoProps> = ({ id, price, src, title, description }) => {
  const dispatch = useAppDispatch();
  const { removePromotion } = orderSlice.actions;
  const currentPrice = usePrice(price);
  const { t } = useTranslation();

  const handleRemove = useCallback(() => {
    dispatch(removePromotion(id));
  }, [id]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: '6px', sm: '10px', md: '20px' },
        alignItems: { xs: 'auto', md: 'center' },
        width: '100%',
        padding: { xs: '10px 0', md: '20px 0' },
        borderBottom: '1px solid',
        borderColor: 'grey.50',
      }}
    >
      <Box
        sx={{ display: 'flex', flexGrow: 1, gap: { xs: '13px', sm: '20px' } }}
      >
        <ItemImage src={src} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'space-between' },
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', sm: 'flex-end', md: 'center' },
            flexGrow: { xs: 0, sm: 1 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: {
                xs: 'flex-start',
                sm: 'flex-end',
                md: 'flex-start',
              },
              marginBottom: { xs: '10px', sm: '0' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: { xs: '15px', sm: '20px' },
                lineHeight: { xs: '17px', sm: '23px' },
                marginBottom: '5px',
              }}
              variant="h6"
              color="text.main"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontWeight: '300',
                fontSize: { xs: '12px', sm: '14px' },
                lineHeight: { xs: '15px', sm: '18px' },
              }}
              color="grey.50"
            >
              {description}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '20px' },
              lineHeight: { xs: '17px', sm: '24px' },
              marginRight: '3px',
              fontWeight: '300',
              color: 'grey.50',
              whiteSpace: 'nowrap',
            }}
          >
            {currentPrice}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: '13px', sm: '20px' },
        }}
      >
        <Box
          sx={{
            width: { xs: '117px', sm: '110px', md: '140px' },
            textAlign: 'center',
            height: '45px',
          }}
        >
          <Box
            sx={{
              fontWeight: '300',
              fontSize: { xs: '16px', sm: '19px' },
              lineHeight: { xs: '19px', sm: '21px' },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              widht: '100%',
              backgroundColor: 'primary.main',
              borderRadius: '6px',
              color: 'secondary.main',
            }}
          >
            1
          </Box>
        </Box>

        <Box
          sx={{
            whiteSpace: 'nowrap',
            minWidth: '135px',
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              fontSize: '18px',
              lineHeight: '21px',
              backgroundColor: '#FBBC04',
              textAlign: 'center',
              borderRadius: '5px',
              color: 'common.white',
              padding: '3px',
            }}
          >
            {t('promo')}
          </Box>
        </Box>

        <Box sx={{ minWidth: '28px', marginLeft: { xs: 'auto', md: '0' } }}>
          <IconButton
            onClick={handleRemove}
            sx={{ border: '2px solid #777777', padding: '0' }}
            aria-label="remove"
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Promo);
