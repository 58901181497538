import { createSelector } from '@reduxjs/toolkit';
import {
  OrderHistoryItem,
  PaymentTypeOption,
  PAYMENT_TYPES,
} from 'types/Order';
import { selectAppSettings } from 'store/selectors/appSelectors';
import {
  selectBenefit,
  selectDeliveryPrice,
  selectResultTotalPrice,
} from 'store/selectors/basketSelectors';
import { RootState } from 'store/store';

const selectBasket = (state: RootState) => state.basketReducer.basket;
const selectOrderReducer = (state: RootState) => state.orderReducer;
const selectOrderHistory = (state: RootState) =>
  state.orderReducer.orderHistory;
/**
 * Returns order data
 */
export const selectOrder = createSelector(
  [selectOrderReducer],
  (orderReducer) => orderReducer.order
);
/**
 * Returns basket data
 */
export const selectBasketData = createSelector([selectBasket], (basketItems) =>
  basketItems.map((basketProduct) => {
    return {
      id: basketProduct.id,
      count: basketProduct.count,
      ingredients: basketProduct.ingredients,
      group_modifiers: basketProduct.group_modifiers,
      cooking_option_id: basketProduct.cooking_option_id || null,
    };
  })
);
/**
 * Returns the converted data for displaying the order on the payment page
 */
export const selectGeneratedOrder = createSelector(
  [selectBasket, selectDeliveryPrice, selectBenefit, selectResultTotalPrice],
  (basketItems, deliveryPriceKop, benefitKop, resultTotalPriceKop) => {
    const orderItems = basketItems.map((basketProduct) => {
      return {
        id: basketProduct.id,
        title: basketProduct.title,
        count: basketProduct.count,
        priceKop: basketProduct.total_price,
      };
    });

    return {
      deliveryPriceKop,
      benefitKop,
      resultTotalPriceKop,
      items: orderItems,
    };
  }
);
/**
 * Returns order payment type.
 */
export const selectOrderPaymentType = createSelector(
  [selectOrder],
  (order) => order.payment?.payment_type
);
/**
 * Returns only active orders
 */
export const selectActiveOrdersData = createSelector(
  [selectOrderHistory],
  (orderHistory: OrderHistoryItem[]) => {
    const activeOrders = orderHistory.filter(
      (order) => order.status !== 3 && order.status !== 10
    );
    return activeOrders;
  }
);
/**
 * Returns a variable for delivery (boolean)
 */
export const selectIsDelivery = createSelector(
  [selectOrder],
  (order) => order.delivery_type === 0
);
/**
 * Returns order promotions
 */
export const selectOrderPromotions = createSelector(
  [selectOrder],
  (order) => order.actions
);
/**
 * Returns payment type
 */
export const selectPayment = createSelector(
  [selectOrder],
  (order) => order.payment
);
/**
 * Returns first available payment type or null if no type specified.
 * ToDo: maybe set default type at configuration type
 */
export const selectDefaultPaymentType = createSelector(
  [selectOrder, selectAppSettings],
  (order, settings) => {
    const isDelivery = order.delivery_type === 0;

    // Currently default delivery type is the first type available
    // Regarding delivery type
    if (isDelivery) {
      if (settings.PAYMENT_CARD) {
        return PAYMENT_TYPES.PAYMENT_CARD;
      }

      if (settings.PAYMENT_CARD_COURIER) {
        return PAYMENT_TYPES.PAYMENT_CARD_COURIER;
      }

      if (settings.PAYMENT_CASH) {
        return PAYMENT_TYPES.PAYMENT_CASH;
      }

      if (settings.PAYMENT_ON_ACCOUNT) {
        return PAYMENT_TYPES.PAYMENT_ON_ACCOUNT;
      }
    }

    // In case of self pick-up
    if (settings.PAYMENT_PICKUP_CARD) {
      return PAYMENT_TYPES.PAYMENT_CARD;
    }

    if (settings.PAYMENT_CARD_COURIER) {
      return PAYMENT_TYPES.PAYMENT_CARD_COURIER;
    }

    if (settings.PAYMENT_PICKUP_CASH) {
      return PAYMENT_TYPES.PAYMENT_CASH;
    }

    if (settings.PAYMENT_PICKUP_ON_ACCOUNT) {
      return PAYMENT_TYPES.PAYMENT_ON_ACCOUNT;
    }

    return null;
  }
);

export const selectPaymentTypeOptions = createSelector(
  [selectAppSettings, selectIsDelivery],
  (settings, isDelivery): PaymentTypeOption[] => {
    const paymentTypes: PaymentTypeOption[] = [];
    // In case of delivery method
    if (isDelivery) {
      if (settings.PAYMENT_CARD) {
        paymentTypes.push({
          label: settings.PAYMENT_TYPE_CARD_LABEL,
          value: PAYMENT_TYPES.PAYMENT_CARD,
        });
      }

      if (settings.PAYMENT_CARD_COURIER) {
        paymentTypes.push({
          label: settings.PAYMENT_CARD_COURIER_LABEL,
          value: PAYMENT_TYPES.PAYMENT_CARD_COURIER,
        });
      }

      if (settings.PAYMENT_CASH) {
        paymentTypes.push({
          label: settings.PAYMENT_CASH_LABEL,
          value: PAYMENT_TYPES.PAYMENT_CASH,
        });
      }

      if (settings.PAYMENT_ON_ACCOUNT) {
        paymentTypes.push({
          label: settings.PAYMENT_TYPE_ON_ACCOUNT_LABEL,
          value: PAYMENT_TYPES.PAYMENT_ON_ACCOUNT,
        });
      }

      return paymentTypes;
    }

    // In case of self pick-up
    if (settings.PAYMENT_PICKUP_CARD) {
      paymentTypes.push({
        label: settings.PAYMENT_TYPE_CARD_LABEL,
        value: PAYMENT_TYPES.PAYMENT_CARD,
      });
    }

    if (settings.PAYMENT_CARD_COURIER) {
      paymentTypes.push({
        label: settings.PAYMENT_CARD_COURIER_LABEL,
        value: PAYMENT_TYPES.PAYMENT_CARD_COURIER,
      });
    }

    if (settings.PAYMENT_PICKUP_CASH) {
      paymentTypes.push({
        label: settings.PAYMENT_CASH_LABEL,
        value: PAYMENT_TYPES.PAYMENT_CASH,
      });
    }

    if (settings.PAYMENT_PICKUP_ON_ACCOUNT) {
      paymentTypes.push({
        label: settings.PAYMENT_TYPE_ON_ACCOUNT_LABEL,
        value: PAYMENT_TYPES.PAYMENT_ON_ACCOUNT,
      });
    }

    return paymentTypes;
  }
);
/**
 * Returns total price for payment page
 */
export const selectOrderTotalPrice = createSelector(
  [selectResultTotalPrice, selectOrder],
  (resultTotalPrice, order) => {
    const { points } = order.payment;
    if (points) {
      return resultTotalPrice - points;
    }
    return resultTotalPrice;
  }
);
