import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import image1 from 'assets/images/offer1.png';
import image2 from 'assets/images/offer2.png';
import image3 from 'assets/images/offer3.png';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WebOffers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSendFlowers = () => {
    navigate('/menu#148');
  };

  return (
    <Box>
      <Container>
        <Box
          sx={{
            display: 'grid',
            columnGap: { xs: 1, sm: 2, md: 4, lg: 5, xl: 5 },
            gridTemplateColumns: {
              xs: 'repeat(3, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: '12%', right: '0px' }}>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '30px',
                  lineHeight: '33px',
                  fontWeight: '600',
                  color: '#D5DDF4',
                  padding: '10px 27px',
                  backgroundColor: '#9CA4BC',
                  marginBottom: '17px',
                  textTransform: 'uppercase',
                }}
              >
                {t('home_page.offer_title_1')}
              </Typography>
              <Button
                sx={{
                  fontSize: '23px',
                  color: 'secondary.main',
                  fontWeight: '300',
                  textTransform: 'inherit',
                  borderRadius: '10px',
                  marginRight: '12px',
                  backgroundColor: 'common.white',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                to="/menu"
                component={Link}
                variant="contained"
              >
                {t('order_now')}
              </Button>
            </Box>

            <Box
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              component="img"
              src={image1}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '30%',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '30px',
                  lineHeight: '33px',
                  fontWeight: '600',
                  color: '#FFFFFF',
                  padding: '10px 27px',
                  backgroundColor: '#B1A476',
                  marginBottom: '17px',
                  textTransform: 'uppercase',
                }}
              >
                {t('home_page.offer_title_2')}
              </Typography>
              <Button
                sx={{
                  fontSize: '23px',
                  color: 'secondary.main',
                  fontWeight: '300',
                  textTransform: 'inherit',
                  borderRadius: '10px',
                  boxShadow: 'none',
                  marginRight: '15px',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
                onClick={handleSendFlowers}
              >
                {t('home_page.send_flowers')}
              </Button>
            </Box>
            <Box
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              component="img"
              src={image2}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '5%',
                left: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '30px',
                  lineHeight: '33px',
                  fontWeight: '600',
                  color: '#B1A476',
                  padding: '10px 27px',
                  backgroundColor: '#DAD7DF',
                  marginBottom: '17px',
                  textTransform: 'uppercase',
                }}
              >
                {t('home_page.offer_title_3')}
              </Typography>
              <Button
                sx={{
                  fontSize: '23px',
                  color: 'secondary.main',
                  fontWeight: '300',
                  textTransform: 'inherit',
                  borderRadius: '10px',
                  backgroundColor: 'common.white',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    color: '#ffffff',
                  },
                }}
                variant="outlined"
              >
                {t('pre-order')}
              </Button>
            </Box>
            <Box
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              component="img"
              src={image3}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default WebOffers;
