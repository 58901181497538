import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { City } from 'types/City';
import { getCities } from './ActionCreators';

interface CityState {
  cities: City[];
  isLoading: boolean;
  error: string;
}

const initialState: CityState = {
  cities: [],
  isLoading: false,
  error: '',
};

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {},
  extraReducers: {
    [getCities.fulfilled.type]: (state, action: PayloadAction<City[]>) => {
      state.isLoading = false;
      state.error = '';
      state.cities = action.payload;
    },
    [getCities.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getCities.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default citySlice.reducer;
