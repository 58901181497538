import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addressGeocodeBody, addressGeocode } from 'types/Geocode';

export const getAddressGeocode = createAsyncThunk(
  'address/addressGeocode',
  async (body: addressGeocodeBody, thunkAPI) => {
    try {
      const response = await axios.post<addressGeocode>(
        `${process.env.REACT_APP_V3}/address_geocode/`,
        body
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Не удалось получить геокод с ошибкой ${e}`
      );
    }
  }
);
