import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectAppSettingsReducer = (state: RootState) => state.appSettingsReducer;
const selectRestrestaurants = (state: RootState) => state.restaurantReducer;
const selectServerTimeReducer = (state: RootState) => state.serverTimeReducer;

/**
 * Returns app settings
 */
export const selectAppSettings = createSelector(
  [selectAppSettingsReducer],
  (appSettingsReducer) => appSettingsReducer.appSettings
);
/**
 * Returns auth service type
 */
export const selectAuthType = createSelector(
  [selectAppSettings],
  (appSettings) => appSettings.AUTH_SERVICE_TYPE
);
/**
 * Returns app version
 */
export const selectAppVersion = createSelector(
  [selectAppSettingsReducer],
  (appSettingsReducer) => appSettingsReducer.app_version
);
/**
 * Returns phone first restaurant
 */
export const selectPhoneNumber = createSelector(
  [selectRestrestaurants],
  (restaurants) => restaurants.restaurantList[0].phones
);
/**
 * Returns serverTime
 */
export const selectServerTime = createSelector(
  [selectServerTimeReducer],
  (serverTimeReducer) => serverTimeReducer.serverTime
);

/**
 * Returns the display setting of the promo code field (boolean)
 */
export const selectIsPromoCode = createSelector(
  [selectAppSettings],
  (appSettings) => appSettings.ACTION_PROMOCODE
);
/**
 * Returns selected city id
 */
export const selectCityId = createSelector(
  [selectAppSettingsReducer],
  (appSettingsReducer) => appSettingsReducer.city_id
);
