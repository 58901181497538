import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Lottie from 'react-lottie';
import animationData from 'lotties/cartEmpty.json';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmptyBasket: FC = () => {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '25px', sm: '36px' },
          fontWeight: '600',
          color: 'primary.main',
          marginBottom: '30px',
        }}
        variant="h3"
      >
        {t('your_cart_is_empty')}
      </Typography>
      <Box sx={{ maxWidth: '300px', marginBottom: '40px' }}>
        <Lottie options={defaultOptions} />
      </Box>

      <Button
        sx={{
          textTransform: 'inherit',
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          padding: '13px 25px',
          color: 'secondary.main',
          boxShadow: 'none',
        }}
        component={NavLink}
        to="/menu"
        variant="contained"
      >
        {t('add_something')}
      </Button>
    </Box>
  );
};

export default EmptyBasket;
