import { createSelector } from '@reduxjs/toolkit';
import { Address } from 'types/Address';
import { RootState } from '../store';

const selectAddressReducer = (state: RootState) => state.addressReducer;
const selectedAddressId = (state: RootState) =>
  state.addressReducer.selectedAddressId;
/**
 * Returns addresses
 */
export const selectAddresses = createSelector(
  [selectAddressReducer],
  (addressReducer) => addressReducer.addresses
);
/**
 * Returns address by ID
 */
export const selectAddressById = (id: string | number | undefined) =>
  createSelector(
    [selectAddresses],
    (addresses) =>
      addresses.filter((address: Address) => address.id === Number(id))[0]
  );
/**
 * Returns converted address data to display in the address list
 */
export const selectConvertedAddress = createSelector(
  [selectAddresses],
  (addresses) => {
    const addressList = addresses.map((address: Address) => {
      const cityName = address.city_name ? `${address.city_name}, ` : '';
      const street = address.street ? `${address.street}, ` : '';
      const house = address.house ? `${address.house}, ` : '';
      const apartment = address.apartment ? `${address.apartment}, ` : '';
      const entrance = address.entrance ? `${address.entrance}, ` : '';
      const floor = address.floor ? `${address.floor}, ` : '';
      const corpus = address.corpus ? `${address.corpus}, ` : '';

      const finalItem =
        cityName + street + house + apartment + entrance + floor + corpus;

      return {
        id: address.id,
        name: address.name ? `${address.name}: ` : '',
        item: `${finalItem.slice(0, -2)}.`,
      };
    });

    return addressList;
  }
);
/**
 * Returns data for the select field on the basket page
 */
export const selectAddressesList = createSelector(
  [selectAddresses, selectConvertedAddress],
  (addresses, convertedAddresses) =>
    addresses.map((address) => {
      const addressConverted = convertedAddresses.filter(
        (item) => item.id === address.id
      )[0].item;

      return {
        label: address.name ? address.name : addressConverted,
        value: address.id,
      };
    })
);
/**
 * Returns address data for basket calculation
 */
export const selectAddressForCalculateBasket = createSelector(
  [selectAddresses, selectedAddressId],
  (addresses, addressId) => {
    const selectedAddress: Address =
      addresses.filter((address: Address) => address.id === addressId)[0] ||
      addresses[0];

    return {
      city_id: selectedAddress?.city,
      street: selectedAddress?.street,
      house: selectedAddress?.house,
      building: selectedAddress?.building,
      corpus: selectedAddress?.corpus,
      apartment: selectedAddress?.apartment,
      entrance: selectedAddress?.entrance,
      floor: selectedAddress?.floor,
      latitude: selectedAddress?.latitude,
      longitude: selectedAddress?.longitude,
    };
  }
);
