import { createSelector } from '@reduxjs/toolkit';
import { FormValues } from 'types/HookForms';
import { Ingredient, MenuCategory } from 'types/Menu';
import { RootState } from '../store';
import { selectPromotions } from './promotionSelectors';

const selectMenuReducer = (state: RootState) => state.menuReducer;
/**
 * returns menu data
 */
export const selectMenu = createSelector(
  [selectMenuReducer],
  (menuReducer) => menuReducer.menu
);
/**
 * returns selected category
 */
export const selectCategory = (categoryId: number) =>
  createSelector(
    [selectMenu],
    (menu) =>
      menu.filter((category: MenuCategory) => categoryId === category.id)[0]
  );
/**
 * returns items by category name
 */
export const selectItemsByCategoryName = (categoryId: number) =>
  createSelector([selectMenu], (menu) => {
    const category = menu.filter(
      (menuCategory: MenuCategory) => menuCategory.id === categoryId
    )[0];

    return category?.items;
  });
/**
 * returns selected menu product
 */
export const selectMenuProduct = (itemId: number, categoryId: number) =>
  createSelector(
    [selectCategory(categoryId)],
    (menuCategory: MenuCategory) =>
      menuCategory?.items.filter((item) => item.id === itemId)[0]
  );

/**
 * returns price selected option
 */
export const selectOptionPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const selectedOption = product?.options.find((option) => {
      if (selectedValues.option) {
        return option.id === Number(selectedValues.option);
      }
      return option.id;
    });
    if (selectedOption) {
      return selectedOption.price_kop;
    }
    return 0;
  });
/**
 * returns total price selected modifiers
 */
export const selectModifiersPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    if (product) {
      const selectedModifiers = product.group_modifiers.map((group) => {
        const selectedModifier = group.modifiers.find(
          (modifier) => modifier.id === Number(selectedValues[group.id])
        );
        const defaultSelectedModifiers = group.modifiers[0].price_kop;
        return selectedModifier
          ? selectedModifier.price_kop
          : defaultSelectedModifiers;
      });

      return selectedModifiers.reduce((sum, price) => sum + price, 0);
    }
    return 0;
  });
/**
 * returns total price selected ingredients
 */
export const selectIngredientsPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const values = selectedValues.ingredients || [];
    const selectedIngredients = product?.ingredients.filter(
      (ingredient: Ingredient) => values.includes(ingredient.id.toString())
    );

    return (
      selectedIngredients?.reduce(
        (sum, ingredient: Ingredient) => sum + ingredient.price_kop,
        0
      ) || 0
    );
  });
/**
 * returns total price product
 */
export const selectCalculateProductPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector(
    [
      selectOptionPrice(selectedValues, itemId, categoryId),
      selectModifiersPrice(selectedValues, itemId, categoryId),
      selectIngredientsPrice(selectedValues, itemId, categoryId),
    ],
    (optionPrice: number, modifiers: number, ingredientPrice: number) =>
      optionPrice + modifiers + ingredientPrice
  );
/**
 * returns card price
 */
export const selectMenuCardPrice = (itemId: number, categoryId: number) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const defaultPrice = product.options[0].price_kop;
    const defaultModifiersPrice = product.group_modifiers
      .map((group) => group.modifiers[0].price_kop)
      .reduce((prev, current) => prev + current, 0);
    return defaultPrice + defaultModifiersPrice;
  });
/**
 * returns converted data for display menu with promo
 */
export const selectMenuWithOffers = (promoCategoryName: string) =>
  createSelector([selectMenu, selectPromotions], (menu, promotions) => {
    const menuWithOffers = menu.map((category) => {
      return {
        id: category.id,
        items: category.items,
        name: category.name,
        orderBy: category.order_by,
      };
    });

    if (promotions.length > 0) {
      return [
        { id: 'promo', items: [], name: promoCategoryName, orderBy: 0 },
        ...menuWithOffers,
      ];
    }

    return menuWithOffers;
  });
/**
 * returns converted data for display menu tabs with promo
 */
export const selectMenuTabs = (promoCategoryName: string) =>
  createSelector([selectMenu, selectPromotions], (menu, promotions) => {
    const convertedMenu = menu.map((category) => {
      return {
        id: category.id,
        name: category.name,
        orderBy: category.order_by,
      };
    });

    if (promotions.length > 0) {
      return [
        { id: 'promo', name: promoCategoryName, orderBy: 0 },
        ...convertedMenu,
      ];
    }
    return convertedMenu;
  });
/**
 * returns menu card product data for display
 */
export const selectCardProductData = (itemId: number, categoryId: number) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (menuItem) => {
    return {
      src: menuItem.src,
      name: menuItem.name,
      description: menuItem.description,
      id: menuItem.id,
      options: menuItem.options,
      ingredients: menuItem.ingredients,
      cooking_options: menuItem.cooking_options,
      group_modifiers: menuItem.group_modifiers,
      is_only_pickup: menuItem.is_only_pickup,
    };
  });
