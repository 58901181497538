import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  useAddAddressQueryData,
  useAddressView,
  useUpdateAddressQueryData,
} from 'hooks/address';
import {
  addAddress,
  updateAddress,
} from 'store/reducers/AddressSlice/ActionCreators';
import { selectSuccessDelivery } from 'store/selectors/deliverySelectors';
import {
  Success,
  Error,
  Loading,
} from 'components/modals/AddressModal/Modal/Content/CheckDelivery';
import { selectDelieryIsLoading } from 'store/selectors/isLoadingSelectors';

interface CheckDeliveryProps {
  handleBackToForm: () => void;
  isAddAddress: boolean;
  formValues: {
    [x: string]: any;
  };
  addressId?: number;
}

const CheckDelivery: FC<CheckDeliveryProps> = ({
  handleBackToForm,
  isAddAddress,
  formValues,
  addressId,
}) => {
  const dispatch = useAppDispatch();
  const updateAddressQueryData = useUpdateAddressQueryData(
    formValues,
    addressId
  );
  const addAddressQueryData = useAddAddressQueryData(formValues);
  const addressView = useAddressView(formValues);
  const isLoading = useAppSelector(selectDelieryIsLoading);
  const isSuccess = useAppSelector(selectSuccessDelivery);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      if (isAddAddress) {
        dispatch(addAddress(addAddressQueryData));
      } else {
        dispatch(updateAddress(updateAddressQueryData));
      }
    }
  }, [isSuccess, isLoading]);

  return (
    <Box sx={{ height: '387px' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isSuccess ? (
            <Success />
          ) : (
            <Error
              handleBackToForm={handleBackToForm}
              addressView={addressView}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default CheckDelivery;
