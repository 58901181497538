import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetCodeParams,
  ResponseAuthCode,
  ResponseVerifyCode,
  CheckVerifyCode,
} from 'types/Auth';

export const getCode = createAsyncThunk(
  'code/getCode',
  async (params: GetCodeParams, thunkAPI) => {
    const { login, service_type } = params;
    try {
      const response = await axios.post<ResponseAuthCode>(
        `${process.env.REACT_APP_V3}/account/send_auth_code/`,
        {
          service_type,
          login,
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't get the auth code. ${e}`);
    }
  }
);

export const verifyCode = createAsyncThunk(
  'code/verifyCode',
  async (params: CheckVerifyCode, thunkAPI) => {
    const { uid, code } = params;
    try {
      const response = await axios.post<ResponseVerifyCode>(
        `${process.env.REACT_APP_V3}/account/verify_auth_code/`,
        {
          uid,
          code,
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't verify the auth code. ${e}`);
    }
  }
);
