import React, { FC } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useOptionLabel } from 'hooks/menu';

interface OptionRadioButtonProps {
  label: string;
  group: string | number;
  value: number;
  price?: number;
}

const OptionRadioButton: FC<OptionRadioButtonProps> = ({
  label,
  group,
  value,
  price,
}) => {
  const { register } = useFormContext();
  const [labelWithPrice] = useOptionLabel(label, price);
  return (
    <FormControlLabel
      sx={{
        position: 'relative',
        color: 'grey.50',
        margin: '0 7px 10px 0',
        borderRadius: '30px',
        '& .MuiFormControlLabel-label': {
          userSelect: 'none',
          padding: '10px 15px',
          fontWeight: '300',
          zIndex: '2',
        },
        '& .Mui-checked+.MuiFormControlLabel-label': {
          color: 'secondary.main',
        },
      }}
      label={labelWithPrice}
      control={
        <Radio
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            '&.MuiRadio-root': {
              backgroundColor: 'grey.300',
              borderRadius: '30px',
              '& span, input, svg': {
                opacity: '0',
                height: '0',
                width: '0',
              },
              '&.Mui-checked': {
                backgroundColor: 'primary.main',
              },
            },
          }}
          {...register(group.toString())}
          value={value}
        />
      }
    />
  );
};

export default OptionRadioButton;
