import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectCityNameById } from 'store/selectors/citySelectors';
import { selectApiKey } from 'store/selectors/authSelectors';
import { selectAccountId } from 'store/selectors/accountSelectors';

interface FormFields {
  [x: string]: any;
}

const useUpdateAddressQueryData = (
  formValues: FormFields,
  addressId: number | string | undefined
) => {
  const accountId = useAppSelector<number>(selectAccountId);
  const api_key = useAppSelector<string>(selectApiKey);
  const cityName = useAppSelector<string>(selectCityNameById(formValues.city));

  return {
    api_key,
    addressId,
    body: {
      id: Number(addressId),
      name: formValues.name,
      city_name: cityName,
      street: formValues.street,
      house: formValues.house,
      building: formValues.building,
      corpus: formValues.corpus || null,
      apartment: formValues.apartment,
      entrance: formValues.entrance || null,
      floor: formValues.floor || null,
      comment: formValues.comment,
      street_classifier_id: '',
      longitude: null,
      latitude: null,
      account: accountId,
      city: formValues.city,
    },
  };
};

export default useUpdateAddressQueryData;
