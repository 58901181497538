import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { CounterButton } from 'components/generic';
import { Restaurant as RestaurantIcon } from '@mui/icons-material';
import { selectCutlery } from 'store/selectors/basketSelectors';
import { useTranslation } from 'react-i18next';

const Cutlery = () => {
  const dispatch = useAppDispatch();
  const { addCutlery, removeCutlery } = orderSlice.actions;
  const cutleryCount = useAppSelector(selectCutlery);
  const { t } = useTranslation();

  const handleAddCount = () => {
    dispatch(addCutlery());
  };

  const handleRemoveCount = () => {
    dispatch(removeCutlery());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row-reverse', md: 'row' },
        justifyContent: { xs: 'flex-end', sm: 'space-between' },
        gap: { xs: '0px', md: '20px' },
        width: '100%',
        padding: { xs: '10px 0', md: '30px 0' },
        borderBottom: '1px solid',
        borderColor: 'grey.50',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: { xs: '0', md: '1' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: { xs: 'auto', md: '110px' },
            marginRight: '20px',
          }}
        >
          <RestaurantIcon fontSize="large" />
        </Box>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: { xs: '15px', sm: '20px' },
            lineHeight: { xs: '17px', sm: '23px' },
          }}
          variant="h6"
          color="text.main"
        >
          {t('cutlery')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: '117px', sm: '110px', md: '140px' },
          textAlign: 'center',
          height: '45px',
          marginRight: { xs: '13px', md: '203px' },
        }}
      >
        <CounterButton
          count={cutleryCount}
          addCount={handleAddCount}
          removeCount={handleRemoveCount}
        />
      </Box>
    </Box>
  );
};

export default Cutlery;
