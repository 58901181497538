import React, {
  memo,
  MouseEvent,
  FC,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Button as MUIButton } from '@mui/material';
import { CounterButton } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { basketSlice } from 'store/reducers/BasketSlice/BasketSlice';
import { selectDeleteId } from 'store/selectors/basketSelectors';

interface ButtonProps {
  isCountModeButton: boolean;
  handleAddProductToBasket: () => void;
  optionId: number;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  isCountModeButton,
  handleAddProductToBasket,
  optionId,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { addProductCount, removeProductCount } = basketSlice.actions;
  const deleteId = useAppSelector(selectDeleteId(optionId)) as number;
  const { basket } = useAppSelector((state) => state.basketReducer);
  const [count, setCount] = useState<number>(0);

  const isCount = useMemo(() => {
    return count <= 0;
  }, [count]);

  const basketProduct = useMemo(() => {
    return basket.find((product) => product.id === optionId);
  }, [basket]);

  const handleAddProductCount = useCallback(() => {
    dispatch(addProductCount(deleteId));
  }, [deleteId]);

  const handleRemoveProductCount = useCallback(() => {
    dispatch(removeProductCount(deleteId || 0));
  }, [deleteId, count]);

  useEffect(() => {
    if (basketProduct) {
      setCount(basketProduct.count);
    }
  }, [basketProduct]);

  const stopPropagation = (
    event: MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    if (isCountModeButton) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  return (
    <Box sx={{ height: '100%' }} onClick={(event) => stopPropagation(event)}>
      {!isCountModeButton || isCount ? (
        <MUIButton
          sx={{
            textTransform: 'inherit',
            width: '100%',
            height: '100%',
            borderRadius: '6px',
            color: 'secondary.main',
            fontSize: { xs: '15px', sm: '18px' },
            lineHeight: { xs: '17px', sm: '21px' },
            fontWeight: '300',
            border: { xs: '1px solid', sm: '2px solid' },
            '&:hover': {
              border: { xs: '1px solid', sm: '2px solid' },
              borderColor: { xs: 'primary.main', sm: 'primary.main' },
            },
            borderColor: { xs: 'primary.main', sm: 'primary.main' },
            whiteSpace: 'nowrap',
            boxShadow: 'none',
          }}
          type="submit"
          onClick={handleAddProductToBasket}
        >
          {children}
        </MUIButton>
      ) : (
        <CounterButton
          count={count}
          addCount={handleAddProductCount}
          removeCount={handleRemoveProductCount}
        />
      )}
    </Box>
  );
};

export default memo(Button);
