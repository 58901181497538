import React, { FC } from 'react';
import { Box, IconButton, Link } from '@mui/material';
import { FacebookIcon, InstagramIcon, TwitterIcon } from 'components/icons';

interface SocialLink {
  link: string;
  name: string;
}

const SocialLink: FC<SocialLink> = ({ link, name }) => {
  const renderSocialIcon = (socialName: string) => {
    switch (socialName) {
      case 'instagram':
        return <InstagramIcon fontSize="large" />;
      case 'vkontakte':
        return <Box>1</Box>;
      case 'odnoklassniki':
        return <Box>2</Box>;
      case 'facebook':
        return <FacebookIcon fontSize="large" />;
      default:
        return <TwitterIcon fontSize="large" />;
    }
  };

  return (
    <>
      {link ? (
        <IconButton
          aria-label="instagram"
          color="secondary"
          href={link}
          target="_blank"
          component={Link}
          rel="noopener noreferrer"
        >
          {renderSocialIcon(name)}
        </IconButton>
      ) : (
        <></>
      )}
    </>
  );
};

export default SocialLink;
