import React, { FC, memo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';

type BannerType = '1' | '2' | '3' | undefined;

interface BannerProps {
  title: string;
  src: string;
  logo?: string;
  type?: BannerType;
  handleClick?: () => void;
  text?: string;
}

const Banner: FC<BannerProps> = ({
  title,
  logo,
  src,
  type,
  handleClick,
  text,
}) => {
  const { t } = useTranslation();

  const bannerType = (value: BannerType) => {
    switch (value) {
      case '1':
        return (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                width: '100%',
                height: '100%',
                zIndex: '-1',
              }}
            >
              {!src ? (
                <Skeleton
                  sx={{ height: '60vh', width: '100%' }}
                  animation="wave"
                  variant="rectangular"
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  component="img"
                  src={src}
                />
              )}
            </Box>

            <Container sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', sm: 'column' },
                  alignItems: 'flex-end',
                  justifyContent: { xs: 'space-between', sm: 'center' },
                  height: '100%',
                  paddingBottom: { xs: '15px', sm: '0px' },
                }}
              >
                {!title ? (
                  <CircularProgress color="primary" />
                ) : (
                  <>
                    <Box
                      sx={{
                        maxHeight: { xs: '115px', sm: '140px', md: '200px' },
                        padding: { xs: '0 50px', sm: '0' },
                        marginBottom: { xs: '10px', sm: '20px' },
                      }}
                      component="img"
                      src={logo}
                    />
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '20px', sm: '50px' },
                        lineHeight: { xs: '24px', sm: '60px' },
                        fontWeight: '700',
                        color: 'secondary.main',
                        marginBottom: { xs: '0px', sm: '15px' },
                        zIndex: '2',
                      }}
                    >
                      {title}
                    </Typography>
                  </>
                )}

                <Button
                  sx={{
                    textTransform: 'inherit',
                    fontSize: { xs: '18px', sm: '20px' },
                    lineHeight: { xs: '20px', sm: '23px' },
                    fontWeight: '300',
                    padding: '13px 25px',
                    color: 'secondary.main',
                    boxShadow: 'none',
                  }}
                  variant="contained"
                  onClick={handleClick}
                >
                  {t('order_now')}
                </Button>
              </Box>
            </Container>
          </>
        );

      case '2':
        return (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                width: '100%',
                height: '100%',
                zIndex: '-1',
              }}
            >
              {!src ? (
                <Skeleton
                  sx={{ height: '60vh', width: '100%' }}
                  animation="wave"
                  variant="rectangular"
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  component="img"
                  src={src}
                />
              )}
            </Box>

            <Container sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-start', sm: 'center' },
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-end',
                  padding: { xs: '30px 0 85px 0', sm: '0px' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: { xs: 'space-between', sm: 'center' },
                    width: { xs: '100%', sm: '70%' },
                    height: '100%',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '20px', sm: '30px', md: '50px' },
                        lineHeight: { xs: '24px', sm: '34px', md: '60px' },
                        fontWeight: '700',
                        color: 'secondary.main',
                        marginBottom: { xs: '10px', sm: '15px' },
                      }}
                      component="div"
                    >
                      {title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', sm: '20px' },
                        lineHeight: { xs: '17px', sm: '23px' },
                        fontWeight: '300',
                        marginBottom: { xs: '20px', sm: '30px', md: '50px' },
                      }}
                      component="div"
                    >
                      {Parser(text || '')}
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      alignSelf: { xs: 'flex-end', sm: 'auto' },
                      textTransform: 'inherit',
                      fontSize: { xs: '18px', sm: '20px' },
                      lineHeight: { xs: '21px', sm: '23px' },
                      fontWeight: '300',
                      padding: '13px 25px',
                      color: 'secondary.main',
                      boxShadow: 'none',
                    }}
                    variant="contained"
                    onClick={handleClick}
                  >
                    {t('home_page.order_flowers')}
                  </Button>
                </Box>
              </Box>
            </Container>
          </>
        );

      default:
        return (
          <>
            <Box
              sx={{
                position: 'absolute',
                zIndex: '2',
                transform: 'translate(-50%, -45%)',
                top: '50%',
                left: '50%',
                textAlign: 'center',
              }}
            >
              {!title ? (
                <CircularProgress color="primary" />
              ) : (
                <>
                  <Box
                    sx={{
                      maxHeight: { xs: '115px', sm: '140px', md: '200px' },
                      padding: { xs: '0 50px', sm: '0' },
                      marginBottom: { xs: '10px', sm: '20px' },
                    }}
                    component="img"
                    src={logo}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '16px', sm: '25px', md: '36px' },
                      lineHeight: { xs: '16px', sm: '36px', md: '43px' },
                      color: '#FFF',
                      fontWeight: '600',
                      textTransform: 'uppercase',
                    }}
                  >
                    {title}
                  </Typography>
                </>
              )}
            </Box>

            {!src ? (
              <Skeleton
                sx={{ height: '60vh', width: '100%' }}
                animation="wave"
                variant="rectangular"
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                component="img"
                src={src}
              />
            )}
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        zIndex: '0',
        marginBottom: { xs: '20px', sm: '25px', md: '32px' },
        height: { xs: '200px', sm: '60vh' },
      }}
    >
      {bannerType(type)}
    </Box>
  );
};

export default memo(Banner);
