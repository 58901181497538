import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { DrawerButton } from 'components/modals/AddressModal/Drawer/Content';
import { useDisableBodyScroll, useDelayUnmount } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/menu';
import { BackButton, Portal } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { checkDelivery } from 'store/reducers/DeliverySlice/ActionCreators';
import { deleteAddress } from 'store/reducers/AddressSlice/ActionCreators';
import { useCheckDeliveryQueryData } from 'hooks/address';
import { Content } from 'components/modals/AddressModal/Drawer';
import { selectApiKey } from 'store/selectors/authSelectors';

interface DrawerProps {
  onClose: () => void;
  isOpen: boolean;
  type: 'add' | 'change';
  addressId?: number;
  isRuForm: boolean;
}

const Drawer: FC<DrawerProps> = ({
  onClose,
  isOpen,
  addressId,
  type,
  isRuForm,
}) => {
  useDisableBodyScroll(isOpen);
  const dispatch = useAppDispatch();
  const [isCheckDelivery, setIsCheckDelivery] = useState<boolean>(false);
  const methods = useForm();
  const formValues = methods.watch();
  const checkAddressQueryData = useCheckDeliveryQueryData(formValues);
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const api_key = useAppSelector<string>(selectApiKey);

  const onSubmit = () => {
    dispatch(checkDelivery(checkAddressQueryData));
    setIsCheckDelivery(true);
  };

  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
  }));

  const openDrawer = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };

  const closeDrawer = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
    if (isOpen) {
      onClose();
      setIsCheckDelivery(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      openDrawer();
    } else {
      closeDrawer();
    }
  }, [isOpen]);

  const handleDeleteAddress = () => {
    dispatch(deleteAddress({ api_key, addressId }));
    closeDrawer();
  };

  const handleBack = () => {
    setIsCheckDelivery(false);
  };

  return (
    <Portal>
      {isMount && (
        <Box component={FormProvider} {...methods}>
          <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <animated.div
              style={{
                position: 'fixed',
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                height: '150vh',
                background: '#ffffff',
                y,
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: '24px',
                  left: '20px',
                  zIndex: '100',
                }}
                onClick={closeDrawer}
              >
                <BackButton direction="down" />
              </Box>
              <Box
                sx={{
                  height: '100vh',
                  width: '100%',
                  overflow: 'auto',
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 100px)',
                }}
              >
                <Content
                  type={type}
                  isCheckDelivery={isCheckDelivery}
                  formValues={formValues}
                  addressId={addressId}
                  handleDeleteAddress={handleDeleteAddress}
                  isRuForm={isRuForm}
                />
              </Box>
            </animated.div>
            <animated.div
              style={{
                position: 'fixed',
                bottom: '-10px',
                left: '0px',
                right: '0px',
                touchAction: 'none',
                zIndex: '200',
                boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.25)',
                y,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '24px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 34px)',
                  display: 'flex',
                  flexDirection: 'column',
                  zIndex: '1',
                  backgroundColor: 'rgba(255, 255, 255, .15)',
                  backdropFilter: 'blur(3px)',
                }}
              >
                <DrawerButton
                  isCheckDelivery={isCheckDelivery}
                  handleBack={handleBack}
                  closeDrawer={closeDrawer}
                />
              </Box>
            </animated.div>
          </Box>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;
