import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const Button: FC<ButtonProps> = ({ isActive, onClick }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      sx={{
        height: { xs: '34px', md: '50px ' },
        textTransform: 'inherit',
        width: '100%',
        borderRadius: '6px',
        color: 'secondary.main',
        fontSize: { xs: '14px', md: '20px' },
        lineHeight: { xs: '16px', md: '23px' },
        fontWeight: '300',
        border: '2px solid',
        '&:hover': {
          border: '2px solid',
          borderColor: isActive ? 'primary.main' : 'transparent',
        },
        borderColor: 'primary.main',
        whiteSpace: 'nowrap',
        boxShadow: 'none',
      }}
      variant={isActive ? 'outlined' : 'contained'}
      onClick={onClick}
    >
      {isActive ? t('cancel') : t('apply')}
    </LoadingButton>
  );
};

export default Button;
