import React, { FC, memo, useMemo } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { Card } from 'components/menu';
import { useMediaQuery } from 'react-responsive';
import { selectMenuIsLoading } from 'store/selectors/isLoadingSelectors';
import { selectItemsByCategoryName } from 'store/selectors/menuSelectors';

interface ReccommendationsProps {
  title: string;
  categoryId: number;
}

const Recommendations: FC<ReccommendationsProps> = ({ title, categoryId }) => {
  const isLoading = useAppSelector(selectMenuIsLoading);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const products = useAppSelector(selectItemsByCategoryName(categoryId));

  const numberOfCards = useMemo<number>(() => {
    if (isMobile) {
      return 2;
    }
    return 4;
  }, [isMobile]);

  return (
    <Container>
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: { xs: '16px', sm: '20px', md: '30px' },
          lineHeight: { xs: '19px', sm: '26px', md: '36px' },
          fontWeight: '400',
          marginBottom: { xs: '17px', sm: '20px', md: '22px' },
          textAlign: 'center',
          textTransform: 'capitalize',
        }}
      >
        {title}
      </Typography>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '448px', sm: '785px', md: '578px' },
            width: '100%',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'grid',
            columnGap: { xs: 1, sm: 2, md: 4, lg: 5, xl: 5 },
            rowGap: { xs: 2, sm: 3, md: 4, lg: 5, xl: 5 },
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
              xl: 'repeat(4, 1fr)',
            },
            marginBottom: { xs: '20px', sm: '32px', md: '48px' },
          }}
        >
          {products &&
            products
              .slice(0, numberOfCards)
              .map((product) => (
                <Card
                  key={product.id}
                  productId={product.id}
                  categoryId={product.parent_menu}
                  isIntersecting
                />
              ))}
        </Box>
      )}
    </Container>
  );
};

export default memo(Recommendations);
