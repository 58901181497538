import { createSelector } from '@reduxjs/toolkit';
import { selectLocationCity } from 'store/selectors/citySelectors';
import { RootState } from '../store';
import { selectMenu } from './menuSelectors';
import { selectPromotions } from './promotionSelectors';

const selectBasketReducer = (state: RootState) => state.basketReducer;
const selectOrderReducer = (state: RootState) => state.orderReducer;

/**
 * Returns the basket
 */
export const selectBasket = createSelector(
  [selectBasketReducer],
  (basketReducer) => basketReducer.basket
);
/**
 * Returns the basket after calculation
 */
export const selectCalculatedBasket = createSelector(
  [selectBasketReducer],
  (basketReducer) => basketReducer.calculatedBasket
);
/**
 * Returns the cutlery
 */
export const selectCutlery = createSelector(
  [selectOrderReducer],
  (orderReducer) => orderReducer.order.cutlery
);
/**
 * Returns the length of the basket array after calculation
 */
export const selectCalculatedBasketLength = createSelector(
  [selectCalculatedBasket],
  (calculatedBasket) => {
    const promoDishesLength = calculatedBasket?.data?.presents.length;
    const basketItemsLength = calculatedBasket?.data?.basket_items.length;
    return basketItemsLength + promoDishesLength;
  }
);
/**
 * Returns length of basket array with promo dishes
 */
export const selectBasketLength = createSelector(
  [selectBasket, selectCalculatedBasket],
  (basket, calculatedBasket) => {
    const basketLength = basket.length;
    const promoDishesLength = calculatedBasket?.data?.presents.length;
    const basketItemsLength = calculatedBasket?.data?.basket_items.length;
    return promoDishesLength + basketItemsLength || basketLength;
  }
);
/**
 * Returns a variable to display an empty bucket
 */
export const selectIsEmptyBasket = createSelector(
  [selectBasket, selectCalculatedBasket],
  (basket, calculatedBasket) => {
    const basketLength = basket.length;
    const promoDishesLength = calculatedBasket?.data?.presents.length;
    const basketItemsLength = calculatedBasket?.data?.basket_items.length;
    return promoDishesLength + basketItemsLength <= 0 || basketLength <= 0;
  }
);
/**
 * Returns type of receiving, (Boolean) isDelivery
 */
export const selectIsDelivery = createSelector(
  [selectOrderReducer],
  (orderReducer) => {
    const deliveryType = orderReducer.order.delivery_type;
    if (deliveryType === 0) {
      return true;
    }
    return false;
  }
);
/**
 * Returns result total price basket
 */
export const selectResultTotalPrice = createSelector(
  [selectBasketReducer],
  (basketReducer) => {
    const staticPrice = basketReducer.basket.reduce(
      (sum, currentBasketItem) =>
        sum + currentBasketItem.total_price * currentBasketItem.count,
      0
    );
    const calculatedPrice =
      basketReducer.calculatedBasket?.data?.result_total_price_kop;
    if (calculatedPrice) {
      return calculatedPrice;
    }
    return staticPrice || 0;
  }
);
/**
 * Returns total price basket
 */
export const selectTotalPrice = createSelector(
  [selectBasketReducer],
  (basketReducer) => {
    const staticPrice = basketReducer.basket.reduce(
      (sum, currentBasketItem) =>
        sum + currentBasketItem.total_price * currentBasketItem.count,
      0
    );
    const calculatedPrice =
      basketReducer.calculatedBasket?.data?.total_price_kop;
    if (calculatedPrice) {
      return calculatedPrice;
    }
    return staticPrice || 0;
  }
);
/**
 * Returns delivery cost
 */
export const selectDeliveryPrice = createSelector(
  [selectCalculatedBasket],
  (calculatedBasket) => calculatedBasket.data?.delivery_price || 0
);
/**
 * Returns the remaining amount for free delivery
 */
export const selectForFreeDelivery = createSelector(
  [selectLocationCity, selectTotalPrice],
  (selectedLocationcity, totalPrice) => {
    const freeDeliveryPrice = selectedLocationcity?.free_delivery_price;
    return freeDeliveryPrice * 100 - totalPrice || 0;
  }
);
/**
 * Returns benefit
 */
export const selectBenefit = createSelector(
  [selectResultTotalPrice, selectTotalPrice, selectCalculatedBasket],
  (resultPrice, totalPrice, calculatedBasket) => {
    const deliveryPrice = calculatedBasket?.data?.delivery_price;
    return totalPrice - resultPrice + deliveryPrice || 0;
  }
);
/**
 * Returns the count of the selected item in the basket
 */
export const selectProductCount = (itemId: number) =>
  createSelector(
    [selectBasket],
    (basket) => basket.find((item) => item.id === itemId)?.count
  );
/**
 * Returns product data for the promo to display in the list of products
 */
export const selectDataDispalyPresents = createSelector(
  [selectCalculatedBasket, selectPromotions, selectMenu],
  (calculatedBasket, promotions, menuData) => {
    const presentActionId = calculatedBasket?.data?.presents[0]?.action;
    const presentItemOption = calculatedBasket?.data?.presents[0]?.item_option;
    const currentPromo = promotions.filter(
      (promo) => promo.id === presentActionId
    )[0];

    const promoDish = currentPromo?.promo_dishes.filter(
      (item) => item.item_option === presentItemOption
    )[0];

    const presentProductCategory = menuData.filter(
      (category) => category.id === promoDish?.menu
    )[0];

    const presentItem = presentProductCategory?.items.filter(
      (item) => item.id === promoDish?.item
    );

    const data = presentItem?.map((item) => {
      return {
        id: item?.id,
        title: item?.name,
        description: item?.description,
        src: item?.src,
        price: calculatedBasket?.data?.presents[0]?.result_price_kop,
        actionId: calculatedBasket?.data?.presents[0]?.action,
      };
    });

    return data;
  }
);
/**
 * Returns calculated basket items
 */
export const selectCalculatedBasketItems = createSelector(
  [selectBasketReducer],
  (basketReducer) => basketReducer.calculatedBasket?.data?.basket_items
);
/**
 * Returns calculated basket active promo
 */
export const selectActivePromo = (id: number) =>
  createSelector([selectOrderReducer], (orderReducer) =>
    orderReducer.order.actions.some((item) => item.action_id === id)
  );
/**
 * Returns converted data for request available bonuses
 */
export const selectBasketItemsForAvailableBonuses = createSelector(
  [selectBasketReducer],
  (basketReducer) =>
    basketReducer.basket.map((item) => {
      return {
        id: item.id,
        cooking_option_id: item.cooking_option_id,
        count: item.count,
        ingrs: item.ingredients,
        group_modifiers: item.group_modifiers,
      };
    })
);
/**
 * Returns the id for deleting an item from the basket
 */
export const selectDeleteId = (id: number) =>
  createSelector(
    [selectBasket],
    (basket) => basket.filter((item) => item.id === id)[0]?.deleteId
  );
