import React, { FC, memo } from 'react';
import {
  Regular,
  Combo,
  Footer,
} from 'components/modals/ProductModal/Modal/Content';
import { Box, Divider } from '@mui/material';
import { MenuProduct } from 'types/Menu';

interface ContentProps {
  product: MenuProduct;
  isCombo: boolean;
  selectedDescription: string;
  totalPrice: number;
}

const Content: FC<ContentProps> = ({
  product,
  isCombo,
  selectedDescription,
  totalPrice,
}) => {
  return (
    <>
      <Box
        sx={{
          // overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.400',
            borderRadius: '20px',
          },
        }}
      >
        {isCombo ? (
          <Combo data={product} selectedDescription={selectedDescription} />
        ) : (
          <Regular data={product} />
        )}
      </Box>

      <Divider />
      <Footer price={totalPrice} description={product.description} />
    </>
  );
};

export default memo(Content);
