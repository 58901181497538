import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckDeliveryArgs, CheckDeliveryResponse } from 'types/Delivery';

export const checkDelivery = createAsyncThunk(
  'delivery/check_delivery',
  async (params: CheckDeliveryArgs, thunkAPI) => {
    const { api_key, body } = params;
    try {
      const response = await axios.post<CheckDeliveryResponse>(
        `${process.env.REACT_APP_V3}/order/check_delivery/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Не удалось проверить возможность доставки, ${e}`
      );
    }
  }
);
