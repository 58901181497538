import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppSettings } from 'types/AppSettings';

export const getAppSettings = createAsyncThunk(
  'app/getAppSettings',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<AppSettings>(
        `${process.env.REACT_APP_V3}/delivery_app_settings/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось получить данные');
    }
  }
);
