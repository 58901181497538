import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectApiKey } from 'store/selectors/authSelectors';
import { selectOrder } from 'store/selectors/orderSelectors';
import { CreateOrder } from 'types/Order';

const useCreateOrder = () => {
  const order = useAppSelector<CreateOrder>(selectOrder);
  const api_key = useAppSelector<string>(selectApiKey);

  return {
    api_key,
    body: order,
  };
};

export default useCreateOrder;
