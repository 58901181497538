import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketProduct, CalculatedBasket } from 'types/Basket';
import { calculateBasket } from './ActionCreators';

interface BasketState {
  basket: BasketProduct[];
  calculatedBasket: CalculatedBasket;
  isLoading: boolean;
  error: string;
}

const initialState: BasketState = {
  basket: [],
  calculatedBasket: {
    data: {
      actions: [],
      available_actions: [],
      basket_items: [],
      delivery_price: 0,
      presents: [],
      result_total_price_kop: 0,
      total_price_kop: 0,
    },
    reason: null,
    success: false,
    message_type: 0,
    message: null,
  },
  isLoading: false,
  error: '',
};

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addProduct(state, action: PayloadAction<BasketProduct>) {
      const product = state.basket.find((item) => {
        const productId = item.id === action.payload.id;
        const isSameIngredients =
          JSON.stringify(item.ingredients) ===
          JSON.stringify(action.payload.ingredients);
        const isSameCookingOption =
          item.cooking_option_id === action.payload.cooking_option_id;
        const isSameModifiers =
          JSON.stringify(item.group_modifiers) ===
          JSON.stringify(action.payload.group_modifiers);
        if (
          productId &&
          isSameIngredients &&
          isSameCookingOption &&
          isSameModifiers
        ) {
          return item;
        }
        return false;
      });

      if (product) {
        product.count += 1;
      } else {
        state.basket.push(action.payload);
      }
    },
    removeProduct(state, action: PayloadAction<number>) {
      state.basket = state.basket.filter(
        (item) => item.deleteId !== action.payload
      );
    },
    addProductCount(state, action: PayloadAction<number>) {
      state.basket.map((product) => {
        if (product.deleteId === action.payload) {
          product.count += 1;
        }
        return product;
      });
    },
    removeProductCount(state, action: PayloadAction<number>) {
      state.basket.map((product) => {
        if (product.deleteId === action.payload) {
          product.count -= 1;
        }
        return product;
      });
    },
    clearBasket(state) {
      state.basket = [];
    },
  },
  extraReducers: {
    [calculateBasket.fulfilled.type]: (
      state,
      action: PayloadAction<CalculatedBasket>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.calculatedBasket = action.payload;
    },
    [calculateBasket.pending.type]: (state) => {
      state.isLoading = true;
    },
    [calculateBasket.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default basketSlice.reducer;
