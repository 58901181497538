import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectCityNameById } from 'store/selectors/citySelectors';
import { selectAccountId } from 'store/selectors/accountSelectors';
import { selectApiKey } from 'store/selectors/authSelectors';

interface FormFields {
  [x: string]: any;
}

const useAddAddressQueryData = (formValues: FormFields) => {
  const id = useAppSelector<number>(selectAccountId);
  const cityName = useAppSelector<string>(selectCityNameById(formValues.city));
  const api_key = useAppSelector<string>(selectApiKey);

  return {
    api_key,
    body: {
      name: formValues.name,
      city_name: cityName,
      street: formValues.street,
      house: formValues.house,
      building: formValues.building,
      corpus: formValues.corpus || null,
      apartment: formValues.apartment || null,
      entrance: formValues.entrance || null,
      floor: formValues.floor || null,
      comment: formValues.comment,
      street_classifier_id: '',
      longitude: null,
      latitude: null,
      account: id,
      city: formValues.city,
    },
  };
};

export default useAddAddressQueryData;
