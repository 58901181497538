import { createSelector } from '@reduxjs/toolkit';
import { Promotion } from 'types/Promotion';
import { RootState } from 'store/store';

const selectPromotionsData = (state: RootState) => state.promotionReducer;
const selectBasketData = (state: RootState) => state.basketReducer;
/**
 * Returns all promo
 */
export const selectPromotions = createSelector(
  [selectPromotionsData],
  (promotionsData) => promotionsData.promotions.slice(0, 4)
);
export const selectOtherPromotions = (promotionId: number) =>
  createSelector([selectPromotions], (promotions: Promotion[]) =>
    promotions.filter((promotion: Promotion) => promotion.id !== promotionId)
  );
/**
 * Returns filtered promo for the basket
 */
export const selectBasketPromotion = createSelector(
  [selectPromotions],
  (promotions: Promotion[]) =>
    promotions.filter(
      (promotion) =>
        promotion.is_show_basket === true &&
        promotion.type !== 0 &&
        promotion.type !== 3
    )
);
/**
 * Returns available promo
 */
export const selectAvailablePromotions = createSelector(
  [selectBasketPromotion, selectBasketData],
  (promotions: Promotion[], basketData) =>
    promotions.filter((promotion: Promotion) => {
      const availablepromotions =
        basketData.calculatedBasket.data?.available_actions;
      const basketPromotions = basketData.calculatedBasket.data?.actions;

      if (basketPromotions?.length <= 0) {
        return promotion;
      }
      return (
        availablepromotions?.includes(promotion.id) ||
        basketPromotions?.includes(promotion.id)
      );
    })
);
