import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { getAccount } from 'store/reducers/AccountSlice/ActionCreators';
import { getAddresses } from 'store/reducers/AddressSlice/ActionCreators';
import { getAppSettings } from 'store/reducers/AppSettingsSlice/ActionCreators';
import { appSettingsSlice } from 'store/reducers/AppSettingsSlice/AppSettingsSlice';
import { getCities } from 'store/reducers/CitySlice/ActionCreators';
import { getMenu } from 'store/reducers/MenuSlice/ActionCreators';
import { getPages } from 'store/reducers/PagesSlice/ActionCreators';
import { getPromotions } from 'store/reducers/PromotionSlice/ActionCreators';
import { getRestaurantList } from 'store/reducers/RestaurantSlice/ActionCreators';
import { getServerTime } from 'store/reducers/ServerTimeSlice/ActionCreators';
import { getSocialMedia } from 'store/reducers/SocialMediaSlice/ActionCreators';
import { useTranslation } from 'react-i18next';
import { getBlogPosts } from 'store/reducers/BlogSlice/ActionCreators';
import { useAuth } from 'hooks';
import { selectApiKey } from 'store/selectors/authSelectors';

const useInitApp = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  const [cookies] = useCookies(['locality']);
  const { selectCityId } = appSettingsSlice.actions;
  const { i18n } = useTranslation();
  const lang = useMemo(() => {
    return i18n.language.slice(0, 2);
  }, [i18n.language]);
  const api_key = useAppSelector(selectApiKey);

  useEffect(() => {
    if (isAuth) {
      dispatch(getAccount(api_key));
      dispatch(getAddresses(api_key));
    }
  }, [api_key, isAuth]);

  useEffect(() => {
    dispatch(getCities(lang));
    dispatch(getRestaurantList(lang));
    dispatch(getServerTime());
    dispatch(getAppSettings(lang));
    dispatch(getPages(lang));
    dispatch(getMenu(lang));
    dispatch(getSocialMedia());
    dispatch(selectCityId(Number(cookies.locality)));
    dispatch(getBlogPosts(lang));
    dispatch(
      getPromotions({
        api_key,
        platform: 2,
        brand_id: 0,
        lang,
      })
    );
  }, [lang]);
};

export default useInitApp;
