import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectData } from 'types/SelectData';

interface RestaurantState {
  timeList: SelectData[];
  isLoading: boolean;
  error: string;
}

const initialState: RestaurantState = {
  timeList: [],
  isLoading: false,
  error: '',
};

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    addTimeList(state, action: PayloadAction<SelectData[]>) {
      state.timeList = action.payload;
    },
  },
  extraReducers: {},
});

export default timeSlice.reducer;
