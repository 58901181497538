import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectSuccessDelivery } from 'store/selectors/deliverySelectors';
import {
  Loading,
  Success,
  Error,
} from 'components/modals/AddressModal/Drawer/Content/CheckDelivery';
import {
  useAddAddressQueryData,
  useAddressView,
  useUpdateAddressQueryData,
} from 'hooks/address';
import { selectDelieryIsLoading } from 'store/selectors/isLoadingSelectors';
import {
  addAddress,
  updateAddress,
} from 'store/reducers/AddressSlice/ActionCreators';

interface CheckDeliveryProps {
  formValues: {
    [x: string]: any;
  };
  type: 'add' | 'change';
  addressId?: number;
}

const CheckDelivery: FC<CheckDeliveryProps> = ({
  formValues,
  type,
  addressId,
}) => {
  const dispatch = useAppDispatch();
  const updateAddressQueryData = useUpdateAddressQueryData(
    formValues,
    addressId
  );
  const addAddressQueryData = useAddAddressQueryData(formValues);
  const addressView = useAddressView(formValues);
  const isLoading = useAppSelector(selectDelieryIsLoading);
  const isSuccess = useAppSelector(selectSuccessDelivery);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      if (type === 'add') {
        dispatch(addAddress(addAddressQueryData));
      } else {
        dispatch(updateAddress(updateAddressQueryData));
      }
    }
  }, [isSuccess, isLoading]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>{isSuccess ? <Success /> : <Error addressView={addressView} />}</>
      )}
    </>
  );
};

export default CheckDelivery;
