import React, { FC, useEffect, useState } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useFormContext } from 'react-hook-form';
import {
  selectCityIsLoading,
  selectServerTimeIsLoading,
} from 'store/selectors/isLoadingSelectors';
import { SelectField } from 'components/generic';
import { DateTimePicker } from 'components/basket/Receiving/DeliveryTimeForm';
import { useTranslation } from 'react-i18next';
import { useReadyTime } from 'hooks/basket';

const DeliveryTimeForm: FC = () => {
  const { control, watch } = useFormContext();
  const readyTime = watch('delivery_time');
  const cityLoading = useAppSelector(selectCityIsLoading);
  const isServerTimeLoading = useAppSelector(selectServerTimeIsLoading);
  const [isSelectTimeFrom, setIsSelectTimeForm] = useState<boolean>(false);
  const { t } = useTranslation();
  const selectTimeData = useReadyTime();

  useEffect(() => {
    if (readyTime === 2) {
      setIsSelectTimeForm(true);
    } else {
      setIsSelectTimeForm(false);
    }
  }, [readyTime]);

  return (
    <>
      <Box
        sx={{
          marginBottom: { xs: '16px', sm: '30px' },
          width: { xs: '100%', sm: '49%' },
        }}
      >
        <SelectField
          control={control}
          name="delivery_time"
          label={t('delivery_time')}
          required
          size="small"
          selectItem={selectTimeData}
          defaultValue={1}
        />
      </Box>
      {isSelectTimeFrom && (
        <Stack
          sx={{ marginBottom: { xs: '16px', sm: '30px' } }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1.6}
        >
          {cityLoading && isServerTimeLoading ? (
            <Skeleton variant="rectangular" height={50} />
          ) : (
            <DateTimePicker />
          )}
        </Stack>
      )}
    </>
  );
};

export default DeliveryTimeForm;
