import React, { FC, useEffect, useState } from 'react';
import { Box, Typography, Button as MUIButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Buttons,
  CheckDelivery,
} from 'components/modals/AddressModal/Modal/Content';
import { useCheckDeliveryQueryData } from 'hooks/address';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { deleteAddress } from 'store/reducers/AddressSlice/ActionCreators';
import { checkDelivery } from 'store/reducers/DeliverySlice/ActionCreators';
import { AddressForm, AddressFormRU } from 'components/account/forms';
import { useTranslation } from 'react-i18next';
import { selectApiKey } from 'store/selectors/authSelectors';

interface ContentProps {
  onClose: () => void;
  type: 'add' | 'change';
  addressId?: number;
  isRuForm: boolean;
}

const Content: FC<ContentProps> = ({ onClose, type, addressId, isRuForm }) => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { watch, handleSubmit } = methods;
  const formValues = watch();
  const [isAddAddress, setIsAddAddress] = useState<boolean>(false);
  const [isCheckDelivery, setIsCheckDelivery] = useState<boolean>(false);
  const checkAddressQueryData = useCheckDeliveryQueryData(formValues);
  const api_key = useAppSelector(selectApiKey);
  const { t } = useTranslation();

  useEffect(() => {
    if (type) {
      setIsAddAddress(type === 'add');
    }
  }, [type]);

  const onSubmit = () => {
    dispatch(checkDelivery(checkAddressQueryData));
    setIsCheckDelivery(true);
  };

  const handleDeleteAddress = (id: number | undefined) => () => {
    dispatch(deleteAddress({ api_key, addressId: id }));
    onClose();
  };

  const handleBackToForm = () => {
    setIsCheckDelivery(false);
  };

  return (
    <Box sx={{ width: '530px' }}>
      {isAddAddress ? (
        <Typography
          sx={{
            marginBottom: '30px',
            fontSize: '30px',
            lineHeight: '33px',
            fontWeight: '400',
          }}
          variant="h4"
        >
          {t('delivery_address')}
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '30px',
          }}
        >
          <Typography
            sx={{
              fontSize: '30px',
              lineHeight: '33px',
              fontWeight: '400',
            }}
            variant="h4"
          >
            {t('change_delivery_address')}
          </Typography>
          <MUIButton
            sx={{
              fontSize: '15px',
              fontWeight: '500',
              textTransform: 'capitalize',
              padding: '3px 10px',
            }}
            onClick={handleDeleteAddress(addressId)}
          >
            {t('delete')}
          </MUIButton>
        </Box>
      )}
      {isCheckDelivery ? (
        <CheckDelivery
          formValues={formValues}
          handleBackToForm={handleBackToForm}
          isAddAddress={isAddAddress}
          addressId={addressId}
        />
      ) : (
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            {isRuForm ? (
              <AddressFormRU addressId={addressId} />
            ) : (
              <AddressForm addressId={addressId} type={type} />
            )}
            <Buttons onClose={onClose} />
          </Box>
        </FormProvider>
      )}
    </Box>
  );
};

export default Content;
