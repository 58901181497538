import React, { FC, memo, useEffect } from 'react';
import { Box, Collapse, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { BasketItem, Cutlery, Promo } from 'components/basket/BasketList';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { selectBasketData } from 'store/selectors/orderSelectors';
import { TransitionGroup } from 'react-transition-group';
import {
  selectBasket,
  selectDataDispalyPresents,
} from 'store/selectors/basketSelectors';
import { selectAppSettings } from 'store/selectors/appSelectors';

const BasketList: FC = () => {
  const dispatch = useAppDispatch();
  const basket = useAppSelector(selectBasket);
  const { addBasket } = orderSlice.actions;
  const basketData = useAppSelector(selectBasketData);
  const presents = useAppSelector(selectDataDispalyPresents);
  const { IS_CUTLERY_DISPLAY } = useAppSelector(selectAppSettings);

  useEffect(() => {
    dispatch(addBasket(basketData));
  }, [basketData]);

  return (
    <Box sx={{ padding: { xs: '0px', md: '0 25px' } }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Divider sx={{ backgroundColor: 'grey.50' }} />
        <TransitionGroup>
          {basket.map((product) => (
            <Collapse key={product.deleteId}>
              <BasketItem
                id={product.id}
                title={product.title}
                src={product.image}
                price={product.total_price}
                sumPrice={product.total_price * product.count}
                itemCount={product.count}
                description={product.description}
                deleteId={product.deleteId}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        <TransitionGroup>
          {presents &&
            presents.map((product) => (
              <Collapse key={product?.id}>
                <Promo
                  id={product?.actionId}
                  price={product?.price}
                  src={product?.src}
                  title={product?.title}
                  description={product?.description}
                />
              </Collapse>
            ))}
        </TransitionGroup>
        {IS_CUTLERY_DISPLAY && <Cutlery />}
      </Box>
    </Box>
  );
};

export default memo(BasketList);
