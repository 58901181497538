import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { usePrice } from 'hooks';

interface OrderItemProps {
  title: string;
  count: number;
  priceKop: number;
}

const OrderItem: FC<OrderItemProps> = ({ title, count, priceKop }) => {
  const priceConverted = usePrice(priceKop);

  return (
    <>
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '20px' },
          lineHeight: { xs: '19px', sm: '23px' },
          fontWeight: { xs: '600', sm: '400' },
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '20px' },
          lineHeight: { xs: '16px', sm: '23px' },
          fontWeight: '500',
          justifySelf: 'center',
        }}
      >
        {count}
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '20px' },
          lineHeight: { xs: '16px', sm: '23px' },
          fontWeight: '300',
          justifySelf: 'flex-end',
        }}
      >
        {priceConverted}
      </Typography>
    </>
  );
};

export default OrderItem;
