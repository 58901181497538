import React, { FC } from 'react';
import { Alert, AlertColor, Snackbar, Typography } from '@mui/material';

interface SnackBarProps {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  severity: AlertColor;
  autoHideDuration: number;
}

const SnackBar: FC<SnackBarProps> = ({
  isOpen,
  onClose,
  text,
  severity,
  autoHideDuration,
}) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        elevation={24}
        variant="filled"
      >
        <Typography>{text}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
