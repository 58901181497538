import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { WebOffers, MobileOffers } from 'components/home/Offers';
import Parser from 'html-react-parser';

interface OffersProps {
  title: string;
  text: string;
}

const Offers: FC<OffersProps> = ({ title, text }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={{ marginBottom: { xs: '50px', sm: '60px', md: '70px' } }}>
      <Container>
        <Box sx={{ textAlign: 'center', marginBottom: '70px' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: { xs: '16px', sm: '20px', md: '30px' },
              lineHeight: { xs: '19px', sm: '26px', md: '36px' },
              fontWeight: '400',
              marginBottom: '22px',
              textTransform: 'capitalize',
            }}
            component="div"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px' },
              lineHeight: { xs: '16px', sm: '20px', md: '23px' },
              fontWeight: '300',
              marginBottom: '22px',
            }}
            component="div"
          >
            {Parser(text)}
          </Typography>
        </Box>
      </Container>
      {isMobile ? <MobileOffers /> : <WebOffers />}
    </Box>
  );
};

export default Offers;
