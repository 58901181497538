import React, { FC } from 'react';
import { Stack, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Links: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1.8, sm: 3 }}>
      <Link
        sx={{
          textDecoration: 'none',
          color: 'text.primary',
        }}
        to="/delivery"
        component={NavLink}
      >
        {t('delivery')}
      </Link>
      <Link
        sx={{
          textDecoration: 'none',
          color: 'text.primary',
        }}
        to="/privacy_policy"
        component={NavLink}
      >
        {t('privacy_policy')}
      </Link>
    </Stack>
  );
};

export default Links;
