import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSettings } from 'types/AppSettings';
import { getAppSettings } from 'store/reducers/AppSettingsSlice/ActionCreators';

interface AppSettingsState {
  appSettings: AppSettings;
  city_id: number;
  app_version: string;
  isLoading: boolean;
  error: string;
}

const initialState: AppSettingsState = {
  appSettings: {
    AUTH_SERVICE_TYPE: 0,
    BONUS_PROGRAM: true,
    IS_CUTLERY_DISPLAY: true,
    SUPERUSER_ONLY_BONUS_PROGRAM: true,
    DELIVERY: true,
    CARRY_OUT: true,
    ONLY_CARRY_OUT: true,
    ONLY_DELIVERY: true,
    BOOKING_TABLE: true,
    BOOKING_WITH_PRE_ORDER: true,
    STORE: true,
    CURRENCY_SYMBOL: '',
    ACTION_PROMOCODE: true,
    PAYMENT_ANDROID_MERCHANT_ID: '',
    PAYMENT_APPLE_MERCHANT_ID: '',
    PAYMENT_SYSTEM_TYPE: '',
    PAYMENT_CARD: true,
    PAYMENT_CARD_COURIER: true,
    PAYMENT_CASH: true,
    PAYMENT_BONUS: true,
    PAYMENT_APPLE_PAY: true,
    PAYMENT_ANDROID_PAY: true,
    PAYMENT_SAMSUNG_PAY: true,
    PAYMENT_ON_ACCOUNT: true,
    PAYMENT_PICKUP_CARD: '',
    PAYMENT_PICKUP_CARD_COURIER: '',
    PAYMENT_PICKUP_CASH: '',
    PAYMENT_PICKUP_BONUS: '',
    PAYMENT_PICKUP_APPLE_PAY: '',
    PAYMENT_PICKUP_ANDROID_PAY: '',
    PAYMENT_PICKUP_SAMSUNG_PAY: '',
    PAYMENT_PICKUP_ON_ACCOUNT: '',
    PAYMENT_HALL_CARD: '',
    PAYMENT_HALL_CARD_COURIER: '',
    PAYMENT_HALL_CASH: '',
    PAYMENT_HALL_BONUS: '',
    PAYMENT_HALL_APPLE_PAY: '',
    PAYMENT_HALL_ANDROID_PAY: '',
    PAYMENT_HALL_SAMSUNG_PAY: '',
    PAYMENT_HALL_ON_ACCOUNT: '',
    PAYMENT_CASH_LABEL: '',
    PAYMENT_TYPE_CARD_LABEL: '',
    PAYMENT_CARD_COURIER_LABEL: '',
    PAYMENT_BONUS_LABEL: '',
    PAYMENT_TYPE_APPLE_PAY_LABEL: '',
    PAYMENT_TYPE_ANDROID_PAY_LABEL: '',
    PAYMENT_TYPE_SAMSUNG_PAY_LABEL: '',
    PAYMENT_TYPE_ON_ACCOUNT_LABEL: '',
    COLOR_PRIMARY: '',
    COLOR_SECONDARY: '',
    COLOR_TEXT_ON_PRIMARY_COLOR: '',
    COLOR_TEXT_ON_SECONDARY_COLOR: '',
    LOGO_LIGHT: '',
    LOGO_DARK: '',
    ITEM_PLACEHOLDER_IMAGE: '',
    MAPBOX_ACCESS_TOKEN: '',
    HEREMAP_ACCESS_TOKEN: '',
    PAY_PUBLIC_ID: '',
    KLADR_ACCESS_TOKEN: '',
    IS_APARTMENT_REQUIRED: true,
    IS_CUTLERY_REQUIRED: true,
    IS_OWN_WAITER: true,
    MESSAGE_INFO_TYPE: 0,
    MESSAGE_INFO_TEXT: '',
    IS_DELIVERY_STOP: false,
    DELIVERY_STOP_MESSAGE: '',
    ANDROID_BUNDLE: '',
    APPSTORE_ID: '',
    FEEDBACK_LINK: '',
    DISPLAY_BRAND_SCREEN_ADDRESS: true,
    CHOOSE_DELIVERY_ADDRESS_CITY: true,
  },
  city_id: 0,
  app_version: '1.0',
  isLoading: false,
  error: '',
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    selectCityId(state, action: PayloadAction<number>) {
      state.city_id = action.payload;
    },
  },
  extraReducers: {
    [getAppSettings.fulfilled.type]: (
      state,
      action: PayloadAction<AppSettings>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.appSettings = action.payload;
    },
    [getAppSettings.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAppSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default appSettingsSlice.reducer;
