import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BackButton, TextField } from 'components/generic';
import { useTranslation } from 'react-i18next';

interface StepThreeProps {
  onClose: () => void;
}

const StepThree: FC<StepThreeProps> = ({ onClose }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: '100px 20px 20px 20px' }}>
      <Box
        sx={{ position: 'fixed', top: '24px', left: '20px', zIndex: '100' }}
        onClick={onClose}
      >
        <BackButton direction="down" />
      </Box>
      <Typography sx={{ marginBottom: '11px' }} variant="h4">
        {t("let's_get_acquainted")}
      </Typography>
      <Typography
        sx={{
          marginBottom: '17px',
        }}
      >
        {t('auth_tip_7')}
      </Typography>
      <Box>
        <Box sx={{ marginBottom: '33px' }}>
          <TextField
            control={control}
            name="first_name"
            label={t('name')}
            required
          />
        </Box>

        <Button
          sx={{
            padding: '15px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            boxShadow: 'none',
            fontWeight: '300',
            color: 'secondary.main',
          }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default StepThree;
