import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { AccountForm } from 'components/account/forms';
import { useTranslation } from 'react-i18next';

const PersonalInfo: FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ marginBottom: { xs: '25px', sm: '30px' } }}>
      <Box sx={{ marginBottom: { xs: '25px', sm: '30px' } }}>
        <AccountForm />
      </Box>
      <Button
        sx={{
          height: { xs: '43px', sm: '50px' },
          fontSize: { xs: '18px', sm: '20px' },
          lineHeight: { xs: '21px', sm: '23px' },
          fontWeight: '300',
          color: 'secondary.main',
          width: '100%',
          boxShadow: 'none',
          borderRadius: '6px',
        }}
        variant="contained"
        color="primary"
        type="submit"
      >
        {t('save_changes')}
      </Button>
    </Box>
  );
};

export default PersonalInfo;
