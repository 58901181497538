import React, { FC, useMemo } from 'react';
import { Modal, Drawer } from 'components/modals/ProductModal';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectMenuProduct,
  selectCalculateProductPrice,
} from 'store/selectors/menuSelectors';
import { useForm } from 'react-hook-form';
import { basketSlice } from 'store/reducers/BasketSlice/BasketSlice';
import { useCalculateProduct } from 'hooks/menu';

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
  categoryId: number;
}

const ProductModal: FC<ProductModalProps> = ({
  isOpen,
  onClose,
  productId,
  categoryId,
}) => {
  const dispatch = useAppDispatch();
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const productData = useAppSelector(selectMenuProduct(productId, categoryId));
  const methods = useForm({
    defaultValues: {
      option: `${productData.options[0].id}`,
      ingredients: [],
      cooking_options: productData.cooking_options[0]?.id || null,
      [productData.group_modifiers[0]?.id]:
        productData.group_modifiers[0]?.modifiers[0]?.id,
    },
  });
  const { watch } = methods;
  const formValues = watch();
  const { addProduct } = basketSlice.actions;

  const randomId = useMemo(() => {
    return Math.floor(Math.random() * productId);
  }, [isOpen]);

  const totalPrice = useAppSelector(
    selectCalculateProductPrice(formValues, productId, categoryId)
  );

  const calculatedProduct = useCalculateProduct(
    formValues,
    totalPrice,
    productData,
    randomId
  );

  const isCombo = useMemo(() => {
    return (
      productData.options.length <= 1 &&
      productData.ingredients.length <= 0 &&
      productData.cooking_options.length <= 0 &&
      productData.group_modifiers.length > 1
    );
  }, [productData]);

  const onSubmit = () => {
    dispatch(addProduct(calculatedProduct));
    onClose();
  };

  return (
    <>
      {isDrawer ? (
        <Drawer
          isCombo={isCombo}
          methods={methods}
          isOpen={isOpen}
          onClose={onClose}
          productData={productData}
          onSubmit={onSubmit}
          selectedDescription={calculatedProduct.description}
          totalPrice={totalPrice}
        />
      ) : (
        <Modal
          isCombo={isCombo}
          methods={methods}
          isOpen={isOpen}
          onClose={onClose}
          productData={productData}
          onSubmit={onSubmit}
          selectedDescription={calculatedProduct.description}
          totalPrice={totalPrice}
        />
      )}
    </>
  );
};

export default ProductModal;
