import React, { FC, useRef, useState, useEffect } from 'react';
import { Switch as MUISwitch, Box, Typography } from '@mui/material';
import { useWindowDimensions } from 'hooks/menu';

interface SwitchProps {
  firstValue: string;
  secondValue: string;
  value: boolean;
  setValue: (arg: boolean) => () => void;
}

const Switch: FC<SwitchProps> = ({
  firstValue,
  secondValue,
  value,
  setValue,
}) => {
  const valueRef = useRef<any>(null);
  const [valuehWidth, setValueWidth] = useState<number>(0);
  const { pageWidth } = useWindowDimensions();

  useEffect(() => {
    setValueWidth(valueRef.current.clientWidth);
  }, [valueRef, pageWidth]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        padding: '12px 0',
      }}
    >
      <Typography
        sx={{
          zIndex: '1',
          color: value ? 'grey.600' : 'common.white',
          cursor: 'pointer',
          userSelect: 'none',
          width: '50%',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          '&:focus-visible': {
            outline: '0 !important',
            boxShadow: 'none !important',
          },
          WebkitTapHighlightColor: 'transparent',
        }}
        onClick={setValue(false)}
        ref={valueRef}
      >
        {firstValue}
      </Typography>
      <MUISwitch
        sx={{
          padding: 0,
          width: '100%',
          height: '40px',
          position: 'absolute',
          '& .MuiSwitch-switchBase': {
            padding: 0,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(100%)',
          },
          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: 'grey.300',
          },
          '& .MuiSwitch-track': {
            zIndex: 0,
            borderRadius: '20px',
            backgroundColor: 'grey.300',
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: 'primary.main',
            borderRadius: '20px',
            boxShadow: 'none',
            width: `${valuehWidth}px`,
            height: '40px',
            margin: 0,
          },
        }}
        checked={value}
        onChange={(e) => setValue(e.target.checked)}
      />
      <Typography
        sx={{
          zIndex: '0',
          color: value ? 'common.white' : 'grey.600',
          cursor: 'pointer',
          userSelect: 'none',
          width: '50%',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          WebkitTapHighlightColor: 'transparent',
        }}
        onClick={setValue(true)}
      >
        {secondValue}
      </Typography>
    </Box>
  );
};

export default Switch;
