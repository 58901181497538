import React, { FC } from 'react';
import { Drawer } from 'components/modals/NavModal';
import { MenuTab } from 'types/MenuTabs';

interface NavModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthModalOpen: () => void;
  onSelectCityModalOpen: () => void;
  onLanguageModalOpen: () => void;
  menuTabs: MenuTab[];
}

const NavModal: FC<NavModalProps> = ({
  isOpen,
  onClose,
  onAuthModalOpen,
  onSelectCityModalOpen,
  onLanguageModalOpen,
  menuTabs,
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      onAuthModalOpen={onAuthModalOpen}
      onSelectCityModalOpen={onSelectCityModalOpen}
      onLanguageModalOpen={onLanguageModalOpen}
      menuTabs={menuTabs}
    />
  );
};

export default NavModal;
