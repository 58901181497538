import React, { FC } from 'react';
import { Button as MUIButton } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBookingIsLoading } from 'store/selectors/isLoadingSelectors';
import { useTranslation } from 'react-i18next';

const BookingButton: FC = () => {
  const isBookingLoading = useAppSelector(selectBookingIsLoading);
  const { t } = useTranslation();

  return (
    <MUIButton
      sx={{
        fontSize: '20px',
        fontWeight: '300',
        color: 'secondary.main',
        boxShadow: 'none',
        backgroundColor: 'primary.main',
        textTransform: 'inherit',
        borderRadius: '6px',
        width: { xs: '100%', sm: 'auto' },
      }}
      type="submit"
      variant="contained"
      disabled={isBookingLoading}
    >
      {t('make_a_reservation')}
    </MUIButton>
  );
};

export default BookingButton;
