import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Ingredient } from 'types/Menu';
import { OptionCheckbox } from 'components/generic';
import { useTranslation } from 'react-i18next';

interface IngredientsProps {
  ingredients: Ingredient[];
}

const Ingredients: FC<IngredientsProps> = ({ ingredients }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '20px' },
          lineHeight: { xs: '19px', sm: '23px' },
          marginBottom: '10px',
        }}
      >
        {t('ingredients')}
      </Typography>
      {ingredients.map((ingredient) => (
        <OptionCheckbox
          key={ingredient.id}
          label={ingredient.name}
          group="ingredients"
          value={ingredient.id}
          price={ingredient.price_kop}
        />
      ))}
    </Box>
  );
};

export default Ingredients;
