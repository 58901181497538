import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckDeliveryResponse } from 'types/Delivery';
import { checkDelivery } from './ActionCreators';

interface DeliveryState {
  checkDelivery: CheckDeliveryResponse;
  isLoading: boolean;
  error: string;
}

const initialState: DeliveryState = {
  checkDelivery: {
    data: null,
    message: '',
    message_type: 0,
    reason: '',
    success: false,
  },
  isLoading: false,
  error: '',
};

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {},
  extraReducers: {
    [checkDelivery.fulfilled.type]: (
      state,
      action: PayloadAction<CheckDeliveryResponse>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.checkDelivery = action.payload;
    },
    [checkDelivery.pending.type]: (state) => {
      state.isLoading = true;
    },
    [checkDelivery.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default deliverySlice.reducer;
