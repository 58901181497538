import React, { FC } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { AddAddressButton } from 'components/account';

import { Address } from 'components/account/AddressList';
import { useTranslation } from 'react-i18next';
import { selectConvertedAddress } from 'store/selectors/addressSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const AddressList: FC = () => {
  // const addressList = useAddressList();
  const addressList = useAppSelector(selectConvertedAddress);
  const { t } = useTranslation();

  return (
    <>
      <Typography
        sx={{
          marginBottom: { xs: '15px', sm: '30px' },
        }}
        variant="h5"
      >
        {t('delivery_address')}
      </Typography>
      <Box sx={{ marginBottom: { xs: '12px', sm: '5px' } }}>
        <TransitionGroup>
          {addressList.map((address) => (
            <Collapse key={address.id}>
              <Address
                addressId={address.id}
                addressName={address.name}
                addressContent={address.item}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </Box>
      <AddAddressButton />
    </>
  );
};

export default AddressList;
