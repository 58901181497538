import { createSelector } from '@reduxjs/toolkit';
import { Account } from 'types/Account';
import { BasketProduct } from 'types/Basket';
import { RootState } from '../store';

const selectBasket = (state: RootState) => state.basketReducer.basket;
const selectAccountReducer = (state: RootState) => state.accountReducer;

export const selectAccount = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.account
);
/**
 * Returns basket data for creating an order
 */
export const selectBasketToOrder = createSelector(
  [selectBasket],
  (basketItems) =>
    basketItems.map((basketProduct: BasketProduct) => {
      return {
        id: basketProduct.id,
        count: basketProduct.count,
        ingredients: basketProduct.ingredients,
        group_modifiers: basketProduct.group_modifiers,
      };
    })
);
/**
 * Returns data default client values
 */
export const selectClientDefaulValues = createSelector(
  [selectAccount],
  (account: Account) => {
    return {
      first_name: account.data.first_name,
      last_name: account.data.last_name,
      phone: account.data.phone,
      email: account.data.email,
      sex: account.data.sex,
      birthday: account.data.birthday,
    };
  }
);
/**
 * Returns account ID
 */
export const selectAccountId = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.account?.data?.id
);
/**
 * Returns first name
 */
export const selectName = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.account.data?.first_name
);
/**
 * Returns whether the account data has been successfully uploaded
 */
export const selectAccountIsSuccess = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.account.success
);
/**
 * Returns an error when uploading account data
 */
export const selectAccountError = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.error
);
/**
 * Returns the bonuses
 */
export const selectBonus = createSelector(
  [selectAccountReducer],
  (accountReducer) =>
    accountReducer.account?.data?.bonus_accounts[0]?.balance / 100 || 0
);
/**
 * Returns available bonuses
 */
export const selectAvailableBonus = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.availablePoints?.points / 100
);
