import React, { FC } from 'react';
import {
  CheckDelivery,
  DrawerHeader,
} from 'components/modals/AddressModal/Drawer/Content';
import { AddressForm, AddressFormRU } from 'components/account/forms';
import { Box } from '@mui/material';

interface ContentProps {
  isCheckDelivery: boolean;
  formValues: {
    [x: string]: any;
  };
  addressId?: number;
  type: 'add' | 'change';
  handleDeleteAddress: () => void;
  isRuForm: boolean;
}

const Content: FC<ContentProps> = ({
  isCheckDelivery,
  addressId,
  formValues,
  type,
  handleDeleteAddress,
  isRuForm,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 20px 100px 20px',
      }}
    >
      <DrawerHeader handleDeleteAddress={handleDeleteAddress} type={type} />
      {isCheckDelivery ? (
        <CheckDelivery
          formValues={formValues}
          type={type}
          addressId={addressId}
        />
      ) : (
        <>
          {isRuForm ? (
            <AddressFormRU addressId={addressId} />
          ) : (
            <AddressForm addressId={addressId} type={type} />
          )}
        </>
      )}
    </Box>
  );
};

export default Content;
