import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectBlogReducer = (state: RootState) => state.blogReducer;

/**
 * Returns the blogPosts
 */
export const selectBlogPosts = createSelector(
  [selectBlogReducer],
  (blogReducer) => blogReducer.blogPosts
);
