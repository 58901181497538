import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import { useSrcImage } from 'hooks';

interface SlideProps {
  src: string;
}

const Slide: FC<SlideProps> = ({ src }) => {
  const image = useSrcImage(src);

  return (
    <Box
      sx={{
        height: '380px',
        width: '380px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        component="img"
        src={image}
      />
    </Box>
  );
};

export default memo(Slide);
