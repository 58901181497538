import React, { FC, memo } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';

interface InfoProps {
  title: string;
  text: string;
  src: string;
}

const Info: FC<InfoProps> = ({ title, text, src }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleNavToContactUs = () => {
    navigation('/about#contact_us');
  };

  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'block' },
        backgroundColor: 'secondary.main',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            padding: { xs: '30px 0', md: '70px 0' },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box
            sx={{
              height: { xs: '250px', md: 'auto' },
              width: { xs: '100%', md: '50%' },
              marginBottom: { xs: '30px', md: '0' },
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              component="img"
              src={src}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', md: '50%' },
              padding: { xs: '0', md: '0 60px' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '20px', sm: '30px' },
                color: '#ffffff',
                marginBottom: { xs: '15px', sm: '20px' },
                fontWeight: '400',
              }}
              component="div"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '18px', sm: '20px' },
                color: '#ffffff',
                lineHeight: { xs: '20px', sm: '23px' },
              }}
              component="div"
            >
              {Parser(text)}
            </Typography>

            <Button
              sx={{
                fontSize: '20px',
                fontWeight: '300',
                color: 'secondary.main',
                boxShadow: 'none',
                backgroundColor: 'primary.main',
                textTransform: 'inherit',
                borderRadius: '6px',
              }}
              variant="contained"
              onClick={handleNavToContactUs}
            >
              {t('contact_us')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default memo(Info);
