import React, { FC, useMemo } from 'react';
import { TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/lab';
import { enIN, ru, ar } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

interface DatePickerProps {
  control: Control<FieldValues>;
  name: string;
  label?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({
  control,
  name,
  label,
  required,
  size,
  defaultValue,
  minDate,
  maxDate,
  disabled,
}) => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => {
    if (i18n.language === 'ar-AE') {
      return ar;
    }
    if (i18n.language === 'ru-RU') {
      return ru;
    }
    return enIN;
  }, [i18n.language]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue?.toString() || null}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <MUIDatePicker
            value={value}
            label={label}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& .MuiOutlinedInput-input': {
                    padding: size === 'small' ? '11px 14px' : '13.5px 14px',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#BDBDBD',
                    lineHeight: size === 'small' ? '28px' : '20px',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'secondary.main',
                  },
                  '& .MuiInputLabel-shrink': {
                    color: 'secondary.main',
                    backgroundColor: 'primary.main',
                    padding: '0px 7px',
                    left: '-5px',
                    fontWeight: '600',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                    '& fieldset': {
                      border: '2px solid',
                      borderColor: error?.ref ? 'error.main' : 'primary.main',
                    },
                    '&:hover fieldset': {
                      borderColor: 'secondary.main',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'secondary.main',
                    },
                  },
                }}
                size={size}
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      )}
      rules={{
        required,
      }}
    />
  );
};

export default DatePicker;
