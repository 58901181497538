import React, { FC, useMemo } from 'react';
import { Button, Box, Skeleton, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAuth } from 'hooks';
import { SelectField } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import { selectAddressesList } from 'store/selectors/addressSelectors';
import { useTranslation } from 'react-i18next';
import { selectAddressIsLoading } from 'store/selectors/isLoadingSelectors';

interface AddressFormProps {
  onAddressModalOpen: () => void;
  onAuthModalOpen: () => void;
}

const AddressForm: FC<AddressFormProps> = ({
  onAddressModalOpen,
  onAuthModalOpen,
}) => {
  const { control } = useFormContext();
  const { isAuth } = useAuth();
  const addressesList = useAppSelector(selectAddressesList);
  const isLoading = useAppSelector(selectAddressIsLoading);
  const { t } = useTranslation();

  const handleButton = useMemo(() => {
    if (isAuth) {
      return onAddressModalOpen;
    }
    return onAuthModalOpen;
  }, [isAuth]);

  return (
    <>
      {isAuth && (
        <Box sx={{ marginBottom: { xs: '12px', sm: '20px' } }}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={45} />
          ) : (
            <SelectField
              control={control}
              name="place"
              label={t('select_delivery_address')}
              required
              size="small"
              selectItem={addressesList}
              defaultValue=""
            />
          )}
        </Box>
      )}
      <Button
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          border: 'none',
          background: 'transparent',
          padding: '2px',
          textDecoration: 'none',
          WebkitTapHighlightColor: 'transparent',
          textTransform: 'inherit',
        }}
        onClick={handleButton}
      >
        <AddIcon color="secondary" sx={{ marginRight: '10px' }} />
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            fontWeight: '300',
            color: 'secondary.main',
          }}
        >
          {t('add_new_address')}
        </Typography>
      </Button>
    </>
  );
};

export default AddressForm;
