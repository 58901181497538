import React, { FC, useState, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Slide } from 'components/modals/ProductModal/Modal/Content/Regular/Slider';

interface SliderProps {
  images: any[];
}

const Slider: FC<SliderProps> = ({ images }) => {
  const [slideActive, setSlideActive] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(slideActive);
    }
  }, [swiper, slideActive]);

  const isSlideActive = (slide: SwiperCore) => {
    return setSlideActive(slide.activeIndex);
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: '20px',
          overflow: 'hidden',
        }}
      >
        <Swiper onSwiper={setSwiper} onSlideChange={isSlideActive}>
          {images.map((slide) => (
            <SwiperSlide key={slide.id}>
              <Slide src={slide.src} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
        }}
      >
        {images.map((item, index) => (
          <Box
            sx={{
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              backgroundColor:
                slideActive === index ? 'secondary.main' : 'grey.400',
              margin: '0 6px',
              cursor: 'pointer',
            }}
            key={item.index}
            onClick={() => setSlideActive(index)}
          />
        ))}
      </Box>
    </>
  );
};

export default memo(Slider);
