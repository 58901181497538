import { useState, useMemo, useEffect } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectServerTime } from 'store/selectors/appSelectors';
import { selectIsDelivery } from 'store/selectors/basketSelectors';
import { City } from 'types/City';
import { DateTimeFormatOptions } from 'types/DateTimeFormat';
import { SelectData } from 'types/SelectData';

const useWorkTime = (dayValue: string, selectedCity: City) => {
  const isDelivery = useAppSelector(selectIsDelivery);
  const serverTime = useAppSelector(selectServerTime);
  const [availableTimeList, setAvailableTimeList] = useState<SelectData[]>([]);
  const [range] = useState<number>(900000);
  const [options] = useState<DateTimeFormatOptions>({
    hour: 'numeric',
    minute: 'numeric',
  });
  const [days] = useState([
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]);

  const day = useMemo(() => {
    if (dayValue) {
      return new Date(dayValue).getDay();
    }
    return new Date().getDay();
  }, [dayValue, selectedCity]);

  const workDay = useMemo(() => {
    const workDaySchedule = selectedCity?.work_hours.find(
      (item) => item.week_day === days[day]
    );
    return workDaySchedule;
  }, [selectedCity, days, day, dayValue]);

  const deliveryStart = useMemo(() => {
    if (workDay) {
      return workDay.delivery_start || '';
    }
    return selectedCity?.delivery_start || '';
  }, [workDay, dayValue]);

  const deliveryEnd = useMemo(() => {
    if (workDay) {
      return workDay.delivery_end || '';
    }
    return selectedCity?.delivery_end || '';
  }, [workDay, dayValue]);

  const orderStart = useMemo(() => {
    if (workDay) {
      return workDay.order_start || '';
    }
    return selectedCity?.order_start || '';
  }, [workDay, dayValue]);

  const orderEnd = useMemo(() => {
    if (workDay) {
      return workDay.order_end || '';
    }
    return selectedCity?.order_end || '';
  }, [workDay, dayValue]);

  const startTime = useMemo(() => {
    if (isDelivery) {
      return deliveryStart;
    }
    return orderStart;
  }, [isDelivery, deliveryStart, orderStart, deliveryEnd, orderEnd]);

  const endTime = useMemo(() => {
    if (isDelivery) {
      return deliveryEnd;
    }
    return orderEnd;
  }, [isDelivery, deliveryStart, orderStart, deliveryEnd, orderEnd]);

  const selectTimeStamp = (time: string) => {
    const currentTime = new Date(serverTime);
    const parts = time?.match(/\b\d+\b/g);
    const hours = parts && parts[0];
    const minutes = parts && parts[1];

    currentTime.setHours(Number(hours));
    currentTime.setMinutes(Number(minutes));

    return currentTime.getTime();
  };

  useEffect(() => {
    const list = [];
    const timeStampStart = selectTimeStamp(startTime);
    const timeStampEnd = selectTimeStamp(endTime);

    for (let i = timeStampStart; i <= timeStampEnd; i += range) {
      const timeInterval = new Date(i).toLocaleString('ru', options);
      list.push({ label: timeInterval, value: timeInterval });
    }

    setAvailableTimeList(list);
  }, [startTime, endTime, serverTime, range]);

  return availableTimeList;
};

export default useWorkTime;
