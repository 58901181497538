import React, { FC } from 'react';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { MenuProduct } from 'types/Menu';
import { ComboSlider } from 'components/modals/ProductModal/Drawer/Content/Combo';
import { useSrcImage } from 'hooks';

interface ComboProps {
  data: MenuProduct;
  selectedDescription: string;
}

const Combo: FC<ComboProps> = ({ data, selectedDescription }) => {
  const { name, group_modifiers, src } = data;
  const image = useSrcImage(src);

  return (
    <Box
      sx={{
        padding: '0',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          height: '320px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          component="img"
          src={image}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          padding: '20px 20px 40px 20px',
        }}
      >
        <Typography
          sx={{
            marginBottom: '20px',
            fontSize: '28px',
            fontWeight: '600',
          }}
        >
          {name}
        </Typography>
        <Typography sx={{ fontWeight: '600', marginBottom: '20px' }}>
          {selectedDescription}
          <Divider />
        </Typography>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: 7,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: '20px',
            },
          }}
        >
          {group_modifiers.map((group) => (
            <Box>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '23px',
                  fontWeight: '400',
                }}
              >
                {group.choice_name}
              </Typography>
              <ComboSlider modifiers={group.modifiers} id={group.id} />
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Combo;
