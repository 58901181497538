import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectAuthReducer = (state: RootState) => state.authReducer;
/**
 * Returns auth code response
 */
export const selectAuthCodeResponse = createSelector(
  [selectAuthReducer],
  (authReducer) => {
    return {
      reason: authReducer.codeResponse.reason,
      uid: authReducer.codeResponse.uid,
      isSuccess: authReducer.codeResponse.success,
      error: authReducer.error,
    };
  }
);
/**
 * Returns auth verify code response
 */
export const selectAuthVerifyCodeResponse = createSelector(
  [selectAuthReducer],
  (authReducer) => {
    return {
      reason: authReducer.verifyResponse.reason,
      responseApiKey: authReducer.verifyResponse.api_key,
      isSuccess: authReducer.verifyResponse.success,
      error: authReducer.error,
    };
  }
);
/**
 * Returns api_key
 */
export const selectApiKey = createSelector(
  [selectAuthReducer],
  (authReducer) => authReducer.api_key
);
