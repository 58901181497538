import React, { FC, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { MenuProduct } from 'types/Menu';
import { Content } from 'components/modals/ProductModal/Drawer';
import { BackButton, Portal } from 'components/generic';
import { useDisableBodyScroll, useDelayUnmount, usePrice } from 'hooks';
import { FormProvider } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/menu';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';

interface DrawerProps {
  isOpen: boolean;
  productData: MenuProduct;
  methods: any;
  isCombo: boolean;
  totalPrice: number;
  selectedDescription: string;
  onClose: () => void;
  onSubmit: () => void;
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  methods,
  isCombo,
  totalPrice,
  productData,
  onSubmit,
  selectedDescription,
}) => {
  useDisableBodyScroll(isOpen);
  const convertedTotalPrice = usePrice(totalPrice);
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const { t } = useTranslation();

  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
    opacity: 0,
  }));

  const open = () => {
    api.start({
      y: 0,
      opacity: 1,
      immediate: false,
    });
  };

  const close = () => {
    api.start({
      y: pageHeight,
      opacity: 0,
      immediate: false,
    });
    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  return (
    <Portal>
      {isMount && (
        <>
          <animated.div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            <Box
              sx={{
                position: 'fixed',
                top: '24px',
                left: '20px',
                zIndex: '100',
              }}
              onClick={close}
            >
              <BackButton direction="down" />
            </Box>
            <Box
              sx={{
                height: '100vh',
                width: '100%',
                overflow: 'auto',
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 100px)',
              }}
            >
              <Box component={FormProvider} {...methods}>
                <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                  <Content
                    productData={productData}
                    isCombo={isCombo}
                    selectedDescription={selectedDescription}
                  />
                </Box>
              </Box>
            </Box>
          </animated.div>
          <animated.div
            style={{
              position: 'fixed',
              bottom: '-10px',
              left: '0px',
              right: '0px',
              touchAction: 'none',
              zIndex: '200',
              boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.25)',
              y,
            }}
          >
            <Box
              sx={{
                width: '100%',
                paddingTop: '9px',
                paddingLeft: '21px',
                paddingRight: '21px',
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 33px)',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '1',
                backgroundColor: 'rgba(255, 255, 255, .15)',
                backdropFilter: 'blur(3px)',
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  marginRight: '3px',
                  fontWeight: '300',
                  fontSize: '24px',
                  textAlign: 'right',
                }}
              >
                {convertedTotalPrice}
              </Typography>

              <Button
                sx={{
                  textTransform: 'inherit',
                  width: '100%',
                  borderRadius: '6px',
                  fontSize: '16px',
                  lineHeight: '16px',
                  height: '100%',
                  padding: '15px 0',
                  whiteSpace: 'nowrap',
                  border: '2px solid',
                  color: 'secondary.main',
                  '&:hover': {
                    border: '2px solid',
                  },
                  borderColor: 'primary.main',
                  boxShadow: 'none',
                  background: '#ffffff',
                }}
                type="submit"
                variant="outlined"
                color="primary"
                onClick={close}
              >
                {t('add_to_cart')}
              </Button>
            </Box>
          </animated.div>
        </>
      )}
    </Portal>
  );
};

export default Drawer;
