import React, { FC } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { usePrice, useTrimmed } from 'hooks';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  price: number;
  description: string;
}

const Footer: FC<FooterProps> = ({ price, description }) => {
  const trimmedDescription = useTrimmed(description, 160);
  const currentPrice = usePrice(price);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'inherit', md: 'center' },
        width: '100%',
        paddingTop: { xs: '20px', md: '30px' },
        maxHeight: { xs: 'auto', md: '100px' },
      }}
    >
      <Typography
        sx={{
          maxWidth: { xs: 'auto', md: '310px' },
          flexGrow: 1,
          lineHeight: '1.1',
          overflow: 'hidden',
          marginBottom: { xs: '20px', md: '0' },
        }}
        variant="body1"
      >
        {trimmedDescription}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            lineHeight: '38px',
            whiteSpace: 'nowrap',
            fontWeight: '300',
            marginRight: '15px',
          }}
        >
          {currentPrice}
        </Typography>

        <Button
          sx={{
            width: '140px',
            height: '50px',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'secondary.main',
            boxShadow: 'none',
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          {t('add_to_cart')}
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
