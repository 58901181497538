import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MenuCategory } from 'types/Menu';

export const getMenu = createAsyncThunk(
  'menu/getMenu',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<MenuCategory[]>(
        `${process.env.REACT_APP_V2}/menu/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't get the menu list. Error: ${e}`);
    }
  }
);
