import { useState, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { basketSlice } from 'store/reducers/BasketSlice/BasketSlice';
import { selectCardProductData } from 'store/selectors/menuSelectors';

const useProductCard = (categoryId: number, productId: number) => {
  const dispatch = useAppDispatch();
  const { addProduct } = basketSlice.actions;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    name,
    src,
    description,
    options,
    ingredients,
    cooking_options,
    group_modifiers,
    is_only_pickup,
  } = useAppSelector(selectCardProductData(productId, categoryId));

  const randomId = useMemo(() => {
    return Math.floor(Math.random() * options[0].id);
  }, [options]);

  const isCountButton = useMemo(() => {
    return (
      options.length <= 1 &&
      ingredients.length <= 0 &&
      cooking_options.length <= 0 &&
      group_modifiers.length <= 0
    );
  }, [options, ingredients, cooking_options, group_modifiers]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  const handleAddProductToBasket = useCallback(() => {
    if (isCountButton) {
      dispatch(
        addProduct({
          id: options[0].id,
          title: name,
          image: src,
          cooking_option_id: cooking_options[0]?.id || 0,
          total_price: options[0].price_kop,
          count: 1,
          group_modifiers,
          ingredients,
          description,
          is_only_pickup,
          deleteId: randomId,
        })
      );
    }
  }, [isCountButton]);

  return {
    isModalOpen,
    isCountButton,
    handleOpenModal,
    handleCloseModal,
    handleAddProductToBasket,
  };
};

export default useProductCard;
