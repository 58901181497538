import React, { FC, useEffect, useState } from 'react';
import { Box, Fade, Skeleton, Stack } from '@mui/material';
import { SelectField, SubTitle, TextArea } from 'components/generic';
import { ClientForm } from 'components/basket/Receiving';
import { DateTimePicker } from 'components/basket/Receiving/DeliveryTimeForm';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectAvailableRestaurantsList } from 'store/selectors/restaurantSelectors';
import {
  selectCityIsLoading,
  selectServerTimeIsLoading,
} from 'store/selectors/isLoadingSelectors';
import { useTranslation } from 'react-i18next';
import { useReadyTime } from 'hooks/basket';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';

const Pickup: FC = () => {
  const dispatch = useAppDispatch();
  const { addRestaurant, removeAddress } = orderSlice.actions;
  const { control, watch } = useFormContext();
  const [isSelectTimeFrom, setIsSelectTimeForm] = useState<boolean>(false);
  const selectTimeData = useReadyTime();
  const readyTime = watch('ready');
  const restaurantId = watch('restaurant');
  const selectRestaurants = useAppSelector(selectAvailableRestaurantsList);
  const cityLoading = useAppSelector(selectCityIsLoading);
  const isServerTimeLoading = useAppSelector(selectServerTimeIsLoading);
  const { t } = useTranslation();

  useEffect(() => {
    if (readyTime === 2) {
      setIsSelectTimeForm(true);
    } else {
      setIsSelectTimeForm(false);
    }
  }, [readyTime]);

  useEffect(() => {
    dispatch(addRestaurant(Number(restaurantId)));
    dispatch(removeAddress());
  }, [restaurantId]);

  return (
    <Fade in timeout={400}>
      <Box sx={{ maxWidth: '620px' }}>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <ClientForm />
        </Box>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <SubTitle>{t('pick_a_restaurant')}</SubTitle>
        </Box>
        <Box sx={{ marginBottom: { xs: '16px', sm: '30px' } }}>
          <SelectField
            control={control}
            name="restaurant"
            label={t('pick_a_restaurant')}
            required
            size="small"
            selectItem={selectRestaurants}
            defaultValue=""
          />
        </Box>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <SubTitle>{t('order_by_time')}</SubTitle>
        </Box>
        <Box
          sx={{
            marginBottom: { xs: '16px', sm: '30px' },
            width: { xs: '100%', sm: '49%' },
          }}
        >
          <SelectField
            control={control}
            name="ready"
            label={t('ready_time')}
            required
            size="small"
            selectItem={selectTimeData}
            defaultValue={1}
          />
        </Box>
        {isSelectTimeFrom && (
          <Stack
            sx={{ marginBottom: { xs: '16px', sm: '30px' } }}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1.6}
          >
            {cityLoading && isServerTimeLoading ? (
              <Skeleton variant="rectangular" height={50} />
            ) : (
              <DateTimePicker />
            )}
          </Stack>
        )}

        <Box>
          <TextArea
            control={control}
            name="comment"
            label="Comment on the order"
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default Pickup;
