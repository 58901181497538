import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { BackButton } from 'components/delivery';
import { SubTitle } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectDeliveryPageData } from 'store/selectors/pageSelectors';
import Iframe from 'react-iframe';

const Delivery: FC = () => {
  const {
    operating_hours,
    delivery_time_on_weekdays,
    delivery_time_on_weekends_and_holidays,
    minimum_order,
    minimum_order_amount_for_free_delivery,
    contact_phone,
    delivery_zones,
  } = useAppSelector(selectDeliveryPageData);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box sx={{ marginBottom: '20px' }}>
          <BackButton />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <SubTitle>Delivery</SubTitle>
        </Box>

        <Box sx={{ padding: '0px 25px' }}>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Operating hours:
            </Typography>
            <Typography
              sx={{ fontSize: '23px', lineHeight: '27px', fontWeight: '600' }}
            >
              {operating_hours}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Delivery time on weekdays:
            </Typography>
            <Typography
              sx={{ fontSize: '23px', lineHeight: '27px', fontWeight: '600' }}
            >
              {delivery_time_on_weekdays}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Delivery time on weekends and holidays:
            </Typography>
            <Typography
              sx={{ fontSize: '23px', lineHeight: '27px', fontWeight: '600' }}
            >
              {delivery_time_on_weekends_and_holidays}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Minimum order:
            </Typography>
            <Typography
              sx={{ fontSize: '23px', lineHeight: '27px', fontWeight: '600' }}
            >
              {minimum_order}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Minimum order amount for free delivery:
            </Typography>
            <Typography
              sx={{ fontSize: '23px', lineHeight: '27px', fontWeight: '600' }}
            >
              {minimum_order_amount_for_free_delivery}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Delivery zones:
            </Typography>
            <Box
              dangerouslySetInnerHTML={{
                __html: `<iframe src=${delivery_zones} width="100%" height="400px" frameBorder={0} />`,
              }}
            />
            {/* <Iframe
              url={delivery_zones}
              width="100%"
              height="400px"
              position="relative"
              frameBorder={0}
            /> */}
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '23px', lineHeight: '27px' }}>
              Contact phone
            </Typography>
            <Typography
              sx={{ fontSize: '23px', lineHeight: '27px', fontWeight: '600' }}
            >
              {contact_phone}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Delivery;
