import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Restaurant } from 'types/Restaurant';

export const getRestaurantList = createAsyncThunk(
  'restaurant/getRestaurantList',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<Restaurant[]>(
        `${process.env.REACT_APP_V3}/restaurant/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось получить список ристоранов');
    }
  }
);
