import React, { FC, Key, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import { BackButton, OtherPosts, Post } from 'components/blogPost';

interface Location extends Path {
  state: {
    id: number;
    title: string;
    image: string;
    description: string;
    published_at: string;
  };
  key: Key;
}

const BlogPost: FC = () => {
  const location = useLocation() as Location;
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate('/blog', { replace: true });
    }
  }, [location.state]);

  if (!location.state) {
    return null;
  }

  return (
    <>
      <Box sx={{ padding: { xs: '30px 0', sm: '40px 0' } }}>
        <Container>
          <Box sx={{ marginBottom: '75px' }}>
            <Box sx={{ marginBottom: { xs: '10px', sm: '24px' } }}>
              <BackButton />
            </Box>
            <Post
              src={location.state.image}
              title={location.state.title}
              description={location.state.description}
              published_at={location.state.published_at}
            />
          </Box>
          <OtherPosts />
        </Container>
      </Box>
    </>
  );
};

export default BlogPost;
