import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  appSettingsReducer,
  serverTimeReducer,
  menuReducer,
  authReducer,
  accountReducer,
  addressReducer,
  geocodeReducer,
  cityReducer,
  promotionReducer,
  basketReducer,
  orderReducer,
  deliveryReducer,
  restaurantReducer,
  timeReducer,
  pagesReducer,
  bookingReducer,
  socialMediaReducer,
  blogReducer,
} from 'store/reducers';

const basketPersistConfig = {
  key: 'basket',
  storage,
  version: 1,
};

const orderPersistConfig = {
  key: 'order',
  storage,
  version: 1,
};

const apiPersistConfig = {
  key: 'api_key',
  storage,
  whitelist: ['api_key'],
};

const rootReducer = combineReducers({
  appSettingsReducer,
  serverTimeReducer,
  authReducer: persistReducer(apiPersistConfig, authReducer),
  menuReducer,
  accountReducer,
  addressReducer,
  geocodeReducer,
  cityReducer,
  promotionReducer,
  basketReducer: persistReducer(basketPersistConfig, basketReducer),
  orderReducer: persistReducer(orderPersistConfig, orderReducer),
  deliveryReducer,
  restaurantReducer,
  timeReducer,
  pagesReducer,
  bookingReducer,
  socialMediaReducer,
  blogReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
