import React, { FC, useMemo, memo } from 'react';
import MapBoxGl, { Marker } from 'react-map-gl';
// import { useAppSelector } from 'store/hooks/useAppSelector';
import { MapPin } from 'components/generic';
import { useMediaQuery } from 'react-responsive';
import { Box } from '@mui/material';
// import { selectAppSettings } from 'store/selectors/appSelectors';
import { Restaurant } from 'types/Restaurant';

interface MapBoxMapProps {
  zoom: number;
  longitude: number;
  latitude: number;
  restaurants: Restaurant[];
}

const MapBoxMap: FC<MapBoxMapProps> = ({
  zoom,
  longitude,
  latitude,
  restaurants,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // const { MAPBOX_ACCESS_TOKEN } =
  //   useAppSelector<{ MAPBOX_ACCESS_TOKEN: string }>(selectAppSettings);

  const long = useMemo(() => {
    return longitude;
  }, [longitude, latitude]);

  const lat = useMemo(() => {
    return latitude;
  }, [longitude, latitude]);

  const pins = useMemo(
    () =>
      restaurants.map((restaurant) => (
        <Marker
          key={`marker-${restaurant.id}`}
          longitude={restaurant.longitude}
          latitude={restaurant.latitude}
        >
          <MapPin />
        </Marker>
      )),
    [restaurants]
  );

  if (!longitude && !latitude) {
    return null;
  }

  return (
    <>
      {!isMobile && (
        <Box sx={{ height: '400px' }}>
          <MapBoxGl
            initialViewState={{
              latitude: lat,
              longitude: long,
              zoom,
            }}
            style={{ width: '100%', height: 400 }}
            mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxAccessToken="pk.eyJ1IjoibWFyYWthbmEwMTEyIiwiYSI6ImNrd25hYmh5ajB0eG4ydWxuaGp4ZW1zMXgifQ.Y0kC1GLmTTrHVG0kmZBdUg"
          >
            {pins}
          </MapBoxGl>
        </Box>
      )}
    </>
  );
};

export default memo(MapBoxMap);
