import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCitiesDataForSelectField,
  selectCityNameById,
} from 'store/selectors/citySelectors';

interface FormFields {
  [x: string]: any;
}

const useAddressView = (formValues: FormFields) => {
  const cities = useAppSelector(selectCitiesDataForSelectField);
  const cityName = useAppSelector(selectCityNameById(formValues.city));
  const [addressView, setAddressView] = useState<string>('');

  useEffect(() => {
    const city = cityName || '';
    const street = formValues.street ? `${formValues.street}, ` : '';
    const area = formValues.area ? `${formValues.area}, ` : '';
    const house = formValues.house ? `${formValues.house}, ` : '';
    const apartment = formValues.apartment ? `${formValues.apartment}, ` : '';
    const floor = formValues.floor ? `${formValues.floor}, ` : '';
    const finalItem = street + area + house + apartment + floor + city;

    setAddressView(finalItem);
  }, [cities, formValues]);

  return addressView;
};

export default useAddressView;
