import React, { FC, useEffect } from 'react';
import { Box, Fade } from '@mui/material';
import { SubTitle, TextArea } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import {
  ClientForm,
  AddressForm,
  DeliveryTimeForm,
} from 'components/basket/Receiving';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectAddressIsLoading } from 'store/selectors/isLoadingSelectors';
import { selectAddressForCalculateBasket } from 'store/selectors/addressSelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';

interface DeliveryProps {
  onAddressModalOpen: () => void;
  onAuthModalOpen: () => void;
}

const Delivery: FC<DeliveryProps> = ({
  onAddressModalOpen,
  onAuthModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { addAddress, removeRestaurant } = orderSlice.actions;
  const { control } = useFormContext();
  const { t } = useTranslation();
  const isLoading = useAppSelector(selectAddressIsLoading);
  const selectedAddressData = useAppSelector(selectAddressForCalculateBasket);

  useEffect(() => {
    dispatch(addAddress(selectedAddressData));
    dispatch(removeRestaurant());
  }, [selectedAddressData]);

  return (
    <Fade in timeout={400}>
      <Box sx={{ maxWidth: '620px' }}>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <ClientForm />
        </Box>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <SubTitle>{t('address_for_delivery')}</SubTitle>
        </Box>
        <Box sx={{ marginBottom: { xs: '14px', sm: '50px' } }}>
          {!isLoading && (
            <AddressForm
              onAddressModalOpen={onAddressModalOpen}
              onAuthModalOpen={onAuthModalOpen}
            />
          )}
        </Box>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <SubTitle>{t('delivery_time')}</SubTitle>
        </Box>
        <DeliveryTimeForm />
        <Box>
          <TextArea
            control={control}
            name="comment"
            label={t('comment_on_the_order')}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default Delivery;
