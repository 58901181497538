import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ListItem } from 'components/modals/SelectCityModal/Modal/CitiesList';
import { useCookies } from 'react-cookie';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { appSettingsSlice } from 'store/reducers/AppSettingsSlice/AppSettingsSlice';

interface FilteredCity {
  label: string;
  value: number;
}

interface CitiesListProps {
  cities: FilteredCity[];
  onClose: () => void;
}

const CitiesList: FC<CitiesListProps> = ({ cities, onClose }) => {
  const dispatch = useAppDispatch();
  const { selectCityId } = appSettingsSlice.actions;
  const [cookies, setCookie] = useCookies(['locality']);
  const handleSelectCity = (cityId: number) => () => {
    setCookie('locality', cityId);
    dispatch(selectCityId(cityId));
    if (cookies.locality) {
      onClose();
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '25px 0px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: 7,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey.400',
          borderRadius: '20px',
        },
      }}
    >
      {cities.map((city) => (
        <ListItem
          key={city.value}
          label={city.label}
          value={city.value}
          handleClick={handleSelectCity}
        />
      ))}
    </Box>
  );
};

export default CitiesList;
