import React, { FC, memo } from 'react';
import {
  Banner,
  Map,
  Welcome,
  Info,
  Advantages,
  Contacts,
} from 'components/about';
import { selectAboutUsPageData } from 'store/selectors/pageSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAvailableRestaurants } from 'store/selectors/restaurantSelectors';
import { useScrollToBlock } from 'hooks';
import { selectSelectedCityLongLat } from 'store/selectors/citySelectors';
import { Restaurant } from 'types/Restaurant';

const About: FC = () => {
  useScrollToBlock();
  const {
    page_title,
    info_title_1,
    info_title_2,
    info_text_1,
    info_text_2,
    info_image,
    banner_image,
    advantages,
  } = useAppSelector(selectAboutUsPageData);
  const { longitude, latitude } = useAppSelector<{
    longitude: number;
    latitude: number;
  }>(selectSelectedCityLongLat);
  const restaurants = useAppSelector<Restaurant[]>(selectAvailableRestaurants);

  return (
    <>
      <Banner title={page_title} src={banner_image} />
      <Welcome title={info_title_1} text={info_text_1} />
      <Info title={info_title_2} text={info_text_2} src={info_image} />
      <Advantages advantages={advantages} />
      <Contacts />
      <Map
        zoom={7}
        longitude={longitude}
        latitude={latitude}
        restaurants={restaurants}
      />
    </>
  );
};

export default memo(About);
