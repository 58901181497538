import React, { memo } from 'react';
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { Links } from 'components/generic/Footer';
import footerImg from 'assets/images/footerbg.png';
import payment from 'assets/images/payment.png';
import { SocialLinks } from 'components/generic';
import { selectFirstAvailableRestaurant } from 'store/selectors/restaurantSelectors';
import Parser from 'html-react-parser';

const Footer = () => {
  const { LOGO_LIGHT } = useAppSelector(selectAppSettings);
  const { address, description, phone, name } = useAppSelector(
    selectFirstAvailableRestaurant
  );

  return (
    <Box
      sx={{
        paddingBottom: { xs: '0px', sm: '30px' },
        paddingTop: { xs: '30px', sm: '60px' },
        minWidth: '100%',
        position: 'relative',
      }}
      component="footer"
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          position: 'absolute',
          top: '0px',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          opacity: '0.3',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          opacity: '0.5',
        }}
      >
        <Box
          sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          component="img"
          src={footerImg}
        />
      </Box>
      <Container>
        <Stack
          sx={{
            alignItems: { xs: 'flex-start', md: 'center' },
            paddingBottom: { xs: '20px', sm: '28px' },
          }}
          direction={{ xs: 'column', md: 'row' }}
        >
          <NavLink to="/">
            <Box component="img" src={LOGO_LIGHT} />
          </NavLink>
          <Box sx={{ flexGrow: 1, marginBottom: { xs: '20px', sm: '0' } }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '300',
                lineHeight: '20px',
                maxWidth: '80%',
                paddingLeft: { xs: '0', md: '20px' },
                marginBottom: { xs: '15px', md: '0px' },
              }}
            >
              {description}
            </Typography>
          </Box>

          <Stack
            sx={{ alignItems: { xs: 'flex-start', md: 'flex-end' } }}
            spacing={1}
          >
            <Link
              sx={{
                fontSize: { xs: '20px', sm: '26px' },
                lineHeight: '31px',
                fontWeight: '500',
                whiteSpace: 'nowrap',
              }}
              href={`tel:${phone}`}
              underline="none"
              color="secondary"
            >
              {phone}
            </Link>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '300',
                lineHeight: '20px',
                textAlign: { xs: 'left', md: 'right' },
              }}
            >
              {address}
            </Typography>
          </Stack>
        </Stack>
        <Divider
          sx={{
            bgcolor: 'secondary.main',
            marginBottom: { xs: '12px', sm: '22px' },
          }}
        />
        <Stack
          sx={{
            marginBottom: { xs: '20px', sm: '30px' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
          direction="row"
        >
          <Links />
          <Box sx={{ marginBottom: { xs: '30px', sm: '0' } }}>
            <SocialLinks />
          </Box>
        </Stack>
        <Box
          sx={{ display: { xs: 'none', sm: 'block' }, marginBottom: '8px' }}
          component="img"
          src={payment}
        />
        <Typography
          sx={{
            marginBottom: { xs: '20px', sm: 0 },
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          © 2022 {Parser(name || '')}
        </Typography>
      </Container>
    </Box>
  );
};

export default memo(Footer);
