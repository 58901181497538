import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface SubTitleProps {
  children: React.ReactNode;
}

const SubTitle: FC<SubTitleProps> = ({ children }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: '20px', sm: '24px' },
        lineHeight: { xs: '22px', sm: '25px' },
        fontWeight: '400',
        color: 'text.primary',
      }}
      variant="h2"
    >
      {children}
    </Typography>
  );
};

export default SubTitle;
