import React, { FC } from 'react';
import { OrderList } from 'components/payment/PaymentInfo';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectGeneratedOrder } from 'store/selectors/orderSelectors';
import { useTranslation } from 'react-i18next';

const PaymentInfo: FC = () => {
  const { deliveryPriceKop, benefitKop, resultTotalPriceKop, items } =
    useAppSelector(selectGeneratedOrder);
  const { t } = useTranslation();

  return (
    <OrderList
      deliveryPriceKop={deliveryPriceKop}
      benefitKop={benefitKop}
      title={t('my_order')}
      resultTotalPriceKop={resultTotalPriceKop}
      items={items}
    />
  );
};

export default PaymentInfo;
