import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setupStore } from 'store/store';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { AuthProvider } from 'hooks/useAuth';
import ErrorBoundary from 'components/generic/ErrorBoundary/ErrorBoundary';
import { SnackbarProvider } from 'notistack';
import './i18n';

const store = setupStore();
const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <CookiesProvider>
              <SnackbarProvider maxSnack={3}>
                <Suspense fallback={null}>
                  <App />
                </Suspense>
              </SnackbarProvider>
            </CookiesProvider>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </ErrorBoundary>
  </Provider>
);
