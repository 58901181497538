import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Account, AccountArgs } from 'types/Account';

export const getAccount = createAsyncThunk(
  'account/getAccount',
  async (api_key: string, thunkAPI) => {
    try {
      const response = await axios.get<Account>(
        `${process.env.REACT_APP_V3}/account/`,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get account data, ${e}`);
    }
  }
);

export const updateAccountData = createAsyncThunk(
  'account/updateAccountData',
  async (data: AccountArgs, thunkAPI) => {
    const { api_key, body } = data;
    try {
      const response = await axios.patch<Account>(
        `${process.env.REACT_APP_V3}/account/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update account data, ${e}`);
    }
  }
);

export const calculateAvailablePoint = createAsyncThunk(
  'account/calculateAvailablePoint',
  async (data: any, thunkAPI) => {
    const { api_key, basket, full_sum } = data;

    const params = new FormData();
    params.append('api_key', api_key);
    params.append('full_sum', full_sum);
    params.append('basket', JSON.stringify(basket));

    try {
      const response = await axios.post<any>(
        `${process.env.REACT_APP_V2}/account/calculate_available_point/`,
        params
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Couldn't calculate available points, ${e}`
      );
    }
  }
);
