import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerTimeResponse } from 'types/ServerTime';

export const getServerTime = createAsyncThunk(
  'server_time',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ServerTimeResponse>(
        `${process.env.REACT_APP_V2}/server_time/`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Не удалось получить серверное время с ошибкой ${e}`
      );
    }
  }
);
