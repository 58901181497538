import React, { FC, useEffect, useMemo, memo } from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BackButton, TextField } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAuthIsLoading } from 'store/selectors/isLoadingSelectors';
import { useTranslation } from 'react-i18next';
import { selectAuthCodeResponse } from 'store/selectors/authSelectors';
import { selectAuthType } from 'store/selectors/appSelectors';
import { NavLink } from 'react-router-dom';

interface StepOneProps {
  onClose: () => void;
  nextStep: () => void;
}

const StepOne: FC<StepOneProps> = ({ onClose, nextStep }) => {
  const { control } = useFormContext();
  const isLoading = useAppSelector(selectAuthIsLoading);
  const { isSuccess } = useAppSelector(selectAuthCodeResponse);
  const authType = useAppSelector(selectAuthType);
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      nextStep();
    }
  }, [isSuccess]);

  const title = useMemo(() => {
    if (authType === 1) {
      return t('sign_up_with_phone');
    }
    return t('sign_up_with_email');
  }, [authType]);

  const subTitle = useMemo(() => {
    if (authType === 1) {
      return t('auth_tip_2');
    }
    return t('auth_tip_1');
  }, [authType]);

  const inputLabel = useMemo(() => {
    if (authType === 1) {
      return t('phone_number');
    }
    return t('email');
  }, [authType]);

  const pattern = useMemo(() => {
    if (authType === 1) {
      return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    }
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  }, [authType]);

  const mask = useMemo(() => {
    if (authType === 1) {
      return '+79999999999';
    }
    return '';
  }, [authType]);

  const maskPlaceholder = useMemo(() => {
    if (authType === 1) {
      return '';
    }
    return '';
  }, [authType]);

  return (
    <Box sx={{ padding: '100px 20px 20px 20px' }}>
      <Box
        sx={{ position: 'fixed', top: '24px', left: '20px', zIndex: '100' }}
        onClick={onClose}
      >
        <BackButton direction="down" />
      </Box>
      <Typography
        sx={{
          marginBottom: '11px',
        }}
        variant="h4"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: '17px',
          lineHeight: '21px',
        }}
      >
        {subTitle}
      </Typography>

      <Box sx={{ marginBottom: '40px' }}>
        <TextField
          control={control}
          name="login"
          label={inputLabel}
          pattern={pattern}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          required
          loading={isLoading}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <Button
          sx={{
            padding: '15px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            boxShadow: 'none',
            fontWeight: '300',
            color: 'secondary.main',
          }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          {t('continue')}
        </Button>
      </Box>
      <Typography
        sx={{
          marginBottom: '20px',
          lineHeight: '18px',
        }}
        variant="body2"
      >
        {t('auth.agreement_text_1')}{' '}
        <Link to="/terms_of_use" component={NavLink} onClick={onClose}>
          {t('auth.agreement_link_1')}
        </Link>{' '}
        {t('auth.agreement_text_2')}{' '}
        <Link to="/privacy_policy" component={NavLink} onClick={onClose}>
          {t('auth.agreement_link_2')}
        </Link>
      </Typography>
    </Box>
  );
};

export default memo(StepOne);
