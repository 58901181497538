import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectDeliveryData = (state: RootState) => state.deliveryReducer;
/**
 * Returns the success of delivery data loading
 */
export const selectSuccessDelivery = createSelector(
  [selectDeliveryData],
  (deliveryData) => deliveryData.checkDelivery.success
);
