import React, { FC, useCallback } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  Articles,
  Banner,
  Offers,
  Recommendations,
  Reviews,
} from 'components/home';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectHomePageData } from 'store/selectors/pageSelectors';

const Home: FC = () => {
  const {
    page_title,
    banner_image,
    banner_image_2,
    banner_image_3,
    logo,
    offer_title,
    banner_title_2,
    banner_title_3,
    offer_text,
    banner_text,
    opportunities,
    reviews,
  } = useAppSelector(selectHomePageData);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavToFlowers = useCallback(() => {
    navigate('/menu#326');
  }, []);

  const handleNavToBreakfast = useCallback(() => {
    navigate('/menu#259');
  }, []);

  return (
    <>
      <Banner title={page_title} logo={logo} src={banner_image} />
      <Offers title={offer_title} text={offer_text} />
      <Recommendations
        categoryId={150}
        title={t('home_page.recommendations_title_1')}
      />
      <Banner
        type="1"
        title={banner_title_2}
        src={banner_image_2}
        handleClick={handleNavToBreakfast}
      />
      <Recommendations
        categoryId={141}
        title={t('home_page.recommendations_title_2')}
      />
      <Reviews data={reviews} />
      <Banner
        type="2"
        title={banner_title_3}
        src={banner_image_3}
        handleClick={handleNavToFlowers}
        text={banner_text}
      />
      <Articles data={opportunities} />
    </>
  );
};

export default Home;
