import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { BackButton } from 'components/privacyPolicy';
import { SubTitle } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectTermsOfUsePageData } from 'store/selectors/pageSelectors';

const TermsOfUse: FC = () => {
  const { text } = useAppSelector(selectTermsOfUsePageData);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box sx={{ marginBottom: '20px' }}>
          <BackButton />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <SubTitle>Terms of Use</SubTitle>
        </Box>
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Container>
    </Box>
  );
};

export default TermsOfUse;
