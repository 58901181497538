import React, { FC, memo } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';
import { Card } from 'components/promotions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { MainTitle } from 'components/generic';

const Promotions: FC = () => {
  const { promotions, isLoading } = useAppSelector(
    (state) => state.promotionReducer
  );

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70vh',
              width: '100%',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box sx={{ marginBottom: '40px' }}>
              <MainTitle>Offers</MainTitle>
            </Box>
            <Box
              sx={{
                display: 'grid',
                columnGap: { xs: 3, sm: 3, md: 5 },
                rowGap: { xs: 4, md: 5 },
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                },
              }}
            >
              {promotions.map((card) => (
                <Card
                  key={card.id}
                  title={card.name}
                  description={card.description}
                  desktopImage={card.pictures[0].src}
                  mobileImage={card.thumbnail_url}
                  date={card.date}
                />
              ))}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default memo(Promotions);
