import React, { FC, memo } from 'react';
import { CssBaseline, Box, Fab } from '@mui/material';
import {
  Footer,
  Header,
  CookiesBanner,
  ScrollToTopButton,
} from 'components/generic';
import { Outlet } from 'react-router-dom';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

const Layout: FC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Box id="back-to-top-anchor" />
        <Header />
        <Box
          sx={{
            paddingTop: { xs: '100px', md: '110px' },
            flexGrow: 1,
          }}
        >
          <Outlet />
          <ScrollToTopButton>
            <Fab color="primary" size="large" aria-label="scroll back to top">
              <KeyboardArrowUpIcon sx={{ color: 'secondary.main' }} />
            </Fab>
          </ScrollToTopButton>
        </Box>
        <Footer />
      </Box>
      <CookiesBanner />
    </>
  );
};

export default memo(Layout);
