import React, { FC, useState, useCallback, useMemo } from 'react';
import { Box, Typography, Paper, Stack } from '@mui/material';
import { Button, CardProduct } from 'components/basket/Promo/Card';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { selectOrderPromotions } from 'store/selectors/orderSelectors';
import { PromoProduct } from 'types/Promotion';
import { PromoProductsModal } from 'components/modals';
import { selectActivePromo } from 'store/selectors/basketSelectors';
import { useTranslation } from 'react-i18next';
import { OrderPromotion } from 'types/Order';

interface CardProps {
  title: string;
  id: number;
  isActive: boolean;
  promoProducts: PromoProduct[];
}

const Card: FC<CardProps> = ({ title, id, isActive, promoProducts }) => {
  const dispatch = useAppDispatch();
  const { addPromotion, removePromotion } = orderSlice.actions;
  const promotions = useAppSelector(selectOrderPromotions);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isActivePromo = useAppSelector(selectActivePromo(id));
  const { t } = useTranslation();

  const isModal = useMemo(() => {
    return promoProducts.length >= 1;
  }, [promoProducts.length]);

  const isPromotion = useMemo(() => {
    return promotions.find(
      (promotion: OrderPromotion) => promotion.action_id === id
    );
  }, [promotions]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  const handleTogglePromo = useCallback(() => {
    if (isPromotion) {
      dispatch(removePromotion(id));
    } else {
      dispatch(addPromotion({ action_id: id }));
    }
  }, [isPromotion]);

  return (
    <>
      <Box
        sx={{
          margin: '2px',
          height: { xs: '160px', md: '227px' },
          padding: '16px',
          borderRadius: '20px',
          border: '2px solid',
          borderColor:
            isActivePromo || isActive ? 'secondary.main' : 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: 'none',
        }}
        component={Paper}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '20px' },
            lineHeight: { xs: '16px', md: '23px' },
            fontWeight: '300',
          }}
        >
          {title}
        </Typography>
        <Stack direction="column" spacing={1}>
          {promoProducts.length > 0 &&
            promoProducts
              .slice(0, 2)
              .map((product) => (
                <CardProduct
                  key={product.id}
                  itemId={product.item}
                  categoryId={product.menu}
                />
              ))}
        </Stack>

        {!isActive ? (
          <Button
            isActive={isActivePromo}
            onClick={
              isModal && !isPromotion ? handleOpenModal : handleTogglePromo
            }
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '50px',
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                color: 'secondary.main',
                fontWeight: '300',
                lineHeight: '23px',
              }}
            >
              {t('auto_applied')}
            </Typography>
          </Box>
        )}
      </Box>

      <PromoProductsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        promoProducts={promoProducts}
        actionId={id}
      />
    </>
  );
};

export default Card;
