import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { createBooking } from 'store/reducers/BookingSlice/ActionCreators';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useDateTimeValue } from 'hooks/booking';
import { useAuth } from 'hooks';
import { authSlice } from 'store/reducers/AuthSlice/AuthSlice';
import { selectApiKey } from 'store/selectors/authSelectors';

const useBooking = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { watch, reset } = methods;
  const formValues = watch();
  const dayValue = watch('day');
  const timeValues = watch('time');
  const [isSnackbarError, setIsSnackbarError] = useState<boolean>(false);
  const [isSnackbarSuccess, setIsSnackbarSuccess] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isAuth } = useAuth();
  const { clearAuthResponse } = authSlice.actions;
  const bookingTime = useDateTimeValue(dayValue, timeValues);
  const api_key = useAppSelector(selectApiKey);

  const handleSnackbarErrorOpen = useCallback(() => {
    setIsSnackbarError(true);
  }, [isSnackbarError]);

  const handleSnackbarErrorClose = useCallback(() => {
    setIsSnackbarError(false);
  }, [isSnackbarError]);

  const handleSnackbarSuccessOpen = useCallback(() => {
    setIsSnackbarSuccess(true);
  }, [isSnackbarSuccess]);

  const handleSnackbarSuccessClose = useCallback(() => {
    setIsSnackbarSuccess(false);
  }, [isSnackbarSuccess]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    dispatch(clearAuthResponse());
  }, [isModalOpen]);

  const onSubmit = () => {
    if (isAuth) {
      dispatch(
        createBooking({
          api_key,
          body: {
            name: formValues.name,
            phone: formValues.phone,
            booking_time: bookingTime,
            quantity_people: formValues.quantity_people,
            restaurant: formValues.restaurant,
            comment: formValues.comment,
            email: formValues.email,
          },
        })
      )
        .unwrap()
        .then((result) => {
          if (result.success) {
            reset();
            handleSnackbarSuccessOpen();
          } else {
            handleSnackbarErrorOpen();
          }
        });
    } else {
      handleOpenModal();
    }
  };

  return {
    methods,
    onSubmit,
    isSnackbarError,
    isSnackbarSuccess,
    isModalOpen,
    handleSnackbarErrorClose,
    handleSnackbarSuccessClose,
    handleCloseModal,
  };
};

export default useBooking;
