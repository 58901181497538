import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServerTimeResponse } from 'types/ServerTime';
import { getServerTime } from './ActionCreators';

interface ServerTimeState {
  serverTime: string;
  isLoading: boolean;
  error: string;
}

const initialState: ServerTimeState = {
  serverTime: '',
  isLoading: false,
  error: '',
};

export const serverTimeSlice = createSlice({
  name: 'server_time',
  initialState,
  reducers: {},
  extraReducers: {
    [getServerTime.fulfilled.type]: (
      state,
      action: PayloadAction<ServerTimeResponse>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.serverTime = action.payload.result;
    },
    [getServerTime.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getServerTime.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default serverTimeSlice.reducer;
