import React, { FC, memo } from 'react';
import {
  Account,
  Cart,
  About,
  Menu,
  PageNotFoundView,
  Promotions,
  Payment,
  Reserve,
  Home,
  Blog,
  BlogPost,
  PaymentSuccess,
  ErrorPage,
  OrderSuccess,
  Delivery,
  PrivacyPolicy,
  TermsOfUse,
} from 'pages';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppLayout } from 'layout';
import { PrivateRoute, Rtl, ScrollToTop } from 'components/generic';
import { useAuth, useInitApp } from 'hooks';
import { ThemeProvider } from '@mui/material';
import { useStyles } from 'hooks/styles';
import { useCalculateBasket } from 'hooks/basket';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { selectPage } from 'store/selectors/pageSelectors';

const App: FC = () => {
  const theme = useStyles();
  useInitApp();
  useCalculateBasket();
  const location = useLocation();
  const { isAuth } = useAuth();
  const { BOOKING_TABLE } = useAppSelector(selectAppSettings);
  const isHomePage = useAppSelector(selectPage('home'));

  return (
    <Rtl>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <Routes location={location}>
          <Route path="/" element={<AppLayout />}>
            {isHomePage && <Route index element={<Home />} />}
            <Route
              index={!isHomePage}
              path={!isHomePage ? '/' : 'menu'}
              element={<Menu />}
            />
            <Route path="promotions" element={<Promotions />} />
            <Route path="about" element={<About />} />
            <Route path="cart" element={<Cart />} />
            <Route path="payment" element={<Payment />} />
            {BOOKING_TABLE && <Route path="reserve" element={<Reserve />} />}
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:postId" element={<BlogPost />} />
            <Route path="payment-success" element={<PaymentSuccess />} />
            <Route path="order-success" element={<OrderSuccess />} />
            <Route path="delivery" element={<Delivery />} />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            <Route path="terms_of_use" element={<TermsOfUse />} />
            <Route path="error" element={<ErrorPage />} />
            <Route path="*" element={<PageNotFoundView />} />
            {isAuth && (
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                }
              />
            )}
          </Route>
        </Routes>
      </ThemeProvider>
    </Rtl>
  );
};

export default memo(App);
