import React, { useState, useCallback } from 'react';
import { InputAdornment, TextField as MUITextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { checkPromoCode } from 'store/reducers/PromotionSlice/ActionCreators';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { SnackBar } from 'components/generic';

const Promocode = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const code = methods.watch('promocode');
  const [isSnackbarError, setIsSnackbarError] = useState<boolean>(false);
  const [isSnackbarSuccess, setIsSnackbarSuccess] = useState<boolean>(false);
  const { addPromotion } = orderSlice.actions;
  const { t } = useTranslation();

  const handleSnackbarErrorOpen = useCallback(() => {
    setIsSnackbarError(true);
  }, [isSnackbarError]);

  const handleSnackbarErrorClose = useCallback(() => {
    setIsSnackbarError(false);
  }, [isSnackbarError]);

  const handleSnackbarSuccessOpen = useCallback(() => {
    setIsSnackbarSuccess(true);
  }, [isSnackbarSuccess]);

  const handleSnackbarSuccessClose = useCallback(() => {
    setIsSnackbarSuccess(false);
  }, [isSnackbarSuccess]);

  const handleCheckCode = () => {
    dispatch(
      checkPromoCode({ api_key: '', platform: 2, promocode: code, brand_id: 0 })
    )
      .unwrap()
      .then((res) => {
        if (res.success && res.data.length > 0) {
          dispatch(addPromotion({ action_id: res.data[0] }));
          handleSnackbarSuccessOpen();
        }
        if (
          !res.success ||
          res.reason === 'WRONG_PROMOCODE' ||
          res.data.length <= 0
        ) {
          handleSnackbarErrorOpen();
        }
      });
  };

  return (
    <>
      <Controller
        name="promocode"
        control={methods.control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <MUITextField
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '13.5px 14px',
              },
              '& .MuiInputLabel-root': {
                color: '#BDBDBD',
                lineHeight: '20px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'secondary.main',
              },
              '& .MuiInputLabel-shrink': {
                color: 'secondary.main',
                backgroundColor: 'primary.main',
                padding: '0px 7px',
                left: '-5px',
                fontWeight: '600',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '& fieldset': {
                  border: '2px solid',
                  borderColor: 'primary.main',
                },
                '&:hover fieldset': {
                  borderColor: 'secondary.main',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'secondary.main',
                },
              },
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                top: '45px',
                left: '0',
              },
            }}
            placeholder={t('promocode')}
            hiddenLabel
            onChange={onChange}
            value={value || ''}
            size="medium"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: 'pointer' }}
                  onClick={handleCheckCode}
                >
                  <ArrowForwardIosIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <SnackBar
        autoHideDuration={2000}
        isOpen={isSnackbarError}
        onClose={handleSnackbarErrorClose}
        severity="error"
        text={t('promo_code_not_found')}
      />

      <SnackBar
        autoHideDuration={2000}
        isOpen={isSnackbarSuccess}
        onClose={handleSnackbarSuccessClose}
        severity="success"
        text={t('promo_code_successfully_applied')}
      />
    </>
  );
};

export default Promocode;
