import React, { FC, useCallback } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackButton: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isLtr = i18n.dir() === 'ltr';

  const handleBackRoute = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <>
      <IconButton
        sx={{
          display: { xs: 'inline-flex', sm: 'none' },
          width: '50px',
          height: '50px',
          '&.MuiButtonBase-root': {
            backgroundColor: 'primary.main',
          },
          border: '3px solid',
          borderColor: '#fff',
        }}
        onClick={handleBackRoute}
      >
        {isLtr ? (
          <KeyboardArrowLeft fontSize="large" color="secondary" />
        ) : (
          <KeyboardArrowRight fontSize="large" color="secondary" />
        )}
      </IconButton>
      <Button
        sx={{
          display: { xs: 'none', sm: 'inline-flex' },
          textTransform: 'inherit',
        }}
        variant="text"
        onClick={handleBackRoute}
      >
        {isLtr ? (
          <ArrowBackIos color="secondary" />
        ) : (
          <ArrowForwardIos color="secondary" />
        )}

        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            lineHeight: '21px',
            color: 'secondary.main',
            fontWeight: '500',
          }}
        >
          {t('back')}
        </Typography>
      </Button>
    </>
  );
};

export default BackButton;
