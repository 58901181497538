import React, { FC } from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import { CancelOutlined as ErrorIcon } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPhoneNumber } from 'store/selectors/appSelectors';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
  handleBackToForm: () => void;
  addressView: string;
}

const Error: FC<ErrorProps> = ({ handleBackToForm, addressView }) => {
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -30%)',
          textAlign: 'center',
          width: '70%',
        }}
      >
        <Typography>{addressView}</Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <ErrorIcon
          sx={{ fontSize: '72px', marginBottom: '15px' }}
          color="error"
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '68%',
          left: '50%',
          transform: 'translate(-50%, -68%)',
          textAlign: 'center',
          width: '70%',
        }}
      >
        <Typography
          sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '300' }}
        >
          {t('error_check_address')}
        </Typography>
        <Link
          sx={{
            fontSize: '18px',
            lineHeight: '21px',
            fontWeight: '500',
          }}
          href={`tel:${phoneNumber}`}
          color="secondary"
        >
          {phoneNumber}
        </Link>
      </Box>

      <Button
        sx={{
          textTransform: 'inherit',
          fontSize: '18px',
          fontWeight: '300',
          padding: '9px 25px',
          color: 'secondary.main',
          boxShadow: 'none',
        }}
        variant="contained"
        color="primary"
        onClick={handleBackToForm}
      >
        {t('back')}
      </Button>
    </Box>
  );
};

export default Error;
