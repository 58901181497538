import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectAuthReducer = (state: RootState) => state.authReducer;
const selectBookingReducer = (state: RootState) => state.bookingReducer;
const selectDeliveryData = (state: RootState) => state.deliveryReducer;
const selectMenuData = (state: RootState) => state.menuReducer;
const selectPagesReducer = (state: RootState) => state.pagesReducer;
const selectRestaurantReducer = (state: RootState) => state.restaurantReducer;
const selectServerTimeReducer = (state: RootState) => state.serverTimeReducer;
const selectCityReducer = (state: RootState) => state.cityReducer;
const selectOrderReducer = (state: RootState) => state.orderReducer;
const selectAccountReducer = (state: RootState) => state.accountReducer;
const selectAddressReducer = (state: RootState) => state.addressReducer;
const selectBlogReducer = (state: RootState) => state.blogReducer;

export const selectAuthIsLoading = createSelector(
  [selectAuthReducer],
  (authReducer) => authReducer.isLoading
);

export const selectBookingIsLoading = createSelector(
  [selectBookingReducer],
  (bookingReducer) => bookingReducer.isLoading
);

export const selectDelieryIsLoading = createSelector(
  [selectDeliveryData],
  (deliveryData) => deliveryData.isLoading
);

export const selectMenuIsLoading = createSelector(
  [selectMenuData],
  (menu) => menu.isLoading
);

export const selectPagesIsLoading = createSelector(
  [selectPagesReducer],
  (pagesReducer) => pagesReducer.isLoading
);

export const selectRestaurantIsLoading = createSelector(
  [selectRestaurantReducer],
  (restaurantReducer) => restaurantReducer.isLoading
);

export const selectServerTimeIsLoading = createSelector(
  [selectServerTimeReducer],
  (serverTimeReducer) => serverTimeReducer.isLoading
);

export const selectCityIsLoading = createSelector(
  [selectCityReducer],
  (cityReducer) => cityReducer.isLoading
);

export const selectOrderIsLoading = createSelector(
  [selectOrderReducer],
  (orderReducer) => orderReducer.isLoading
);

export const selectAccountIsLoading = createSelector(
  [selectAccountReducer],
  (accountReducer) => accountReducer.isLoading
);

export const selectAddressIsLoading = createSelector(
  [selectAddressReducer],
  (addressReducer) => addressReducer.isLoading
);

export const selectBlogIsLoading = createSelector(
  [selectBlogReducer],
  (blogReducer) => blogReducer.isLoading
);
