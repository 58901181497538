import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectPagesReducer = (state: RootState) => state.pagesReducer;
/**
 * Returns page data
 */
export const selectPage = (slug: string) =>
  createSelector([selectPagesReducer], (pagesReducer) =>
    pagesReducer.pages.find((page) => page.slug === slug)
  );
/**
 * Returns data for reserve page
 */
export const selectReservePageData = createSelector(
  [selectPage('reserve')],
  (page) => {
    return {
      page_title: page?.fields?.page_title || '',
      info_title: page?.fields?.info_title || '',
      info_text: page?.fields?.info_text || '',
      info_image: page?.fields?.info_image || '',
      banner_image: page?.fields?.banner_image || '',
      form_image: page?.fields?.form_image || '',
    };
  }
);
/**
 * Returns data for about us page
 */
export const selectAboutUsPageData = createSelector(
  [selectPage('about')],
  (page) => {
    return {
      page_title: page?.fields?.page_title || '',
      info_title_1: page?.fields?.info_title_1 || '',
      info_title_2: page?.fields?.info_title_2 || '',
      info_text_1: page?.fields?.info_text_1 || '',
      info_text_2: page?.fields?.info_text_2 || '',
      info_image: page?.fields?.info_image || '',
      banner_image: page?.fields?.banner_image || '',
      advantages: page?.fields?.advantages || [],
    };
  }
);
/**
 * Returns data for home page
 */
export const selectHomePageData = createSelector(
  [selectPage('home')],
  (page) => {
    return {
      page_title: page?.fields?.page_title || '',
      banner_image: page?.fields?.banner_image || '',
      banner_image_2: page?.fields?.banner_image_2 || '',
      banner_image_3: page?.fields?.banner_image_3 || '',
      logo: page?.fields?.logo || '',
      offer_title: page?.fields?.offer_title || '',
      banner_title_2: page?.fields?.banner_title_2 || '',
      banner_title_3: page?.fields?.banner_title_3 || '',
      offer_text: page?.fields?.offer_text || '',
      banner_text: page?.fields?.banner_text || '',
      opportunities: page?.fields?.opportunities || [],
      reviews: page?.fields?.reviews || [],
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectDeliveryPageData = createSelector(
  [selectPage('delivery')],
  (page) => {
    return {
      operating_hours: page?.fields?.operating_hours || '',
      delivery_time_on_weekdays: page?.fields?.delivery_time_on_weekdays || '',
      delivery_time_on_weekends_and_holidays:
        page?.fields?.delivery_time_on_weekends_and_holidays || '',
      minimum_order: page?.fields?.minimum_order || '',
      minimum_order_amount_for_free_delivery:
        page?.fields?.minimum_order_amount_for_free_delivery || '',
      contact_phone: page?.fields?.contact_phone || '',
      delivery_zones: page?.fields?.delivery_zones || '',
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectPrivacyPolicyPageData = createSelector(
  [selectPage('privacy_policy')],
  (page) => {
    return {
      text: page?.fields?.text || '',
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectTermsOfUsePageData = createSelector(
  [selectPage('terms_of_use')],
  (page) => {
    return {
      text: page?.fields?.text || '',
    };
  }
);
