import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { MainTitle } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectDeliveryPrice,
  selectForFreeDelivery,
  selectResultTotalPrice,
  selectIsDelivery,
  selectBenefit,
  selectTotalPrice,
  selectCalculatedBasketLength,
} from 'store/selectors/basketSelectors';
import { useTitleEnding } from 'hooks/basket';
import { Promocode } from 'components/basket/Total';
import { usePrice } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectIsPromoCode } from 'store/selectors/appSelectors';

const Total: FC = () => {
  const benefitPriceCop = useAppSelector(selectBenefit);
  const deliveryPriceCop = useAppSelector(selectDeliveryPrice);
  const forFreeDeliveryPriceCop = useAppSelector(selectForFreeDelivery);
  const basketLength = useAppSelector(selectCalculatedBasketLength);
  const resultTotalPriceCop = useAppSelector(selectResultTotalPrice);
  const totalPriceCop = useAppSelector(selectTotalPrice);
  const isDelivery = useAppSelector(selectIsDelivery);
  const isPromocode = useAppSelector(selectIsPromoCode);
  const ending = useTitleEnding(basketLength);
  const resultTotalPrice = usePrice(resultTotalPriceCop);
  const totalPrice = usePrice(totalPriceCop);
  const deliveryPrice = usePrice(deliveryPriceCop);
  const forFreeDeliveryPrice = usePrice(forFreeDeliveryPriceCop);
  const benefitPrice = usePrice(benefitPriceCop);
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ marginBottom: { xs: '14px', sm: '35px' } }}>
        <MainTitle>
          {t('total')}, {resultTotalPrice}
        </MainTitle>
      </Box>
      <Box
        sx={{
          padding: { xs: '0px', sm: '0 25px' },
          marginBottom: { xs: '40px', sm: '43px' },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '18px', sm: '23px ' },
            lineHeight: { xs: '21px', sm: '27px' },
            marginBottom: { xs: '11px', sm: '20px' },
          }}
        >
          {basketLength} {ending}, {totalPrice}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '18px', sm: '23px ' },
            lineHeight: { xs: '21px', sm: '27px' },
            marginBottom: { xs: '11px', sm: '20px' },
          }}
        >
          {t('promo_benefits')}, {benefitPrice}
        </Typography>

        {isDelivery && (
          <Typography
            sx={{
              fontSize: { xs: '18px', sm: '23px ' },
              lineHeight: { xs: '21px', sm: '27px' },
              marginBottom: { xs: '6px', sm: '20px' },
              color: 'text.primary',
            }}
          >
            {t('shipping_cost')}, {deliveryPrice}
          </Typography>
        )}

        {forFreeDeliveryPriceCop > 0 && deliveryPriceCop > 0 && isDelivery && (
          <Typography
            sx={{
              fontSize: { xs: '12px', sm: '20px ' },
              lineHeight: { xs: '14px', sm: '24px' },
              color: 'error.main',
              marginBottom: '30px',
            }}
          >
            {`(${t('add_something_on', { forFreeDeliveryPrice })})`}
          </Typography>
        )}
        {isPromocode && <Promocode />}
      </Box>
    </Box>
  );
};

export default Total;
