import React, { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';
import animationData from 'lotties/cartComethingWentWrong.json';
import Lottie from 'react-lottie';

interface ErrorMessageProps {
  message?: string | null;
}

/**
 * Displays error with description.
 * If message prop is passed displays it instead of predefined message.
 */
const ErrorMessage: FC<ErrorMessageProps> = ({ message = null }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '24px', sm: '30px' },
          fontWeight: '400',
          color: 'text.primary',
          marginBottom: '30px',
        }}
        variant="h3"
      >
        {message || 'Sorry, something went wrong'}
      </Typography>
      <Box sx={{ maxWidth: '300px', marginBottom: '40px' }}>
        <Lottie options={defaultOptions} />
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '20px', sm: '25px' },
          fontWeight: '400',
          color: 'text.primary',
          marginBottom: '11px',
        }}
        variant="body1"
      >
        Please call or text us
      </Typography>
      <Typography
        sx={{
          display: 'inline-block',
          fontSize: '16px',
          lineHeight: '19px',
          textDecoration: 'none',
          marginBottom: '8px',
          WebkitTapHighlightColor: 'transparent',
          color: 'secondary.main',
        }}
        component={Link}
        href="tel:+9745224444"
      >
        +9 (745) 222-44-44
      </Typography>
      <Typography
        sx={{
          display: 'inline-block',
          fontSize: '16px',
          lineHeight: '19px',
          textDecoration: 'none',
          marginBottom: '8px',
          WebkitTapHighlightColor: 'transparent',
          color: 'secondary.main',
        }}
        component={Link}
        href="/"
      >
        Send a text message
      </Typography>
    </>
  );
};

export default ErrorMessage;
