import React, { FC, useCallback, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Create as ChangeIcon } from '@mui/icons-material';
import { AddressModal } from 'components/modals';

interface AddressProps {
  addressId: number;
  addressName: string;
  addressContent: string;
}

const Address: FC<AddressProps> = ({
  addressId,
  addressName,
  addressContent,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  return (
    <>
      <Button
        sx={{
          display: 'inline-flex',
          alignItems: 'flex-start',
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          flexDirection: 'column',
          cursor: 'pointer',
          textDecoration: 'none',
          textTransform: 'inherit',
          marginBottom: { xs: '17px', sm: '25px' },
          WebkitTapHighlightColor: 'transparent',
          width: '100%',
        }}
        onClick={handleOpenModal}
      >
        {addressName && (
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '20px' },
              lineHeight: { xs: '19px', md: '26px' },
              fontWeight: { xs: '400', md: '300' },
              color: 'secondary.main',
              marginRight: '8px',
              marginBottom: { xs: '6px', md: '3px' },
            }}
          >
            {addressName}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-start',
              md: 'space-between',
            },
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '20px' },
              lineHeight: { xs: '20px', md: '26px' },
              fontWeight: '300',
              color: 'text.primary',
              marginRight: '20px',
              paddingLeft: '15px',
            }}
          >
            {addressContent}
          </Typography>
          <ChangeIcon color="secondary" />
        </Box>
      </Button>

      <AddressModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type="change"
        addressId={addressId}
      />
    </>
  );
};

export default Address;
