import React, { FC } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/ProductModal/Modal';
import { MenuProduct } from 'types/Menu';
import { FormProvider } from 'react-hook-form';

interface ModalProps {
  isOpen: boolean;
  productData: MenuProduct;
  methods: any;
  isCombo: boolean;
  selectedDescription: string;
  totalPrice: number;
  onClose: () => void;
  onSubmit: () => void;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  productData,
  methods,
  isCombo,
  selectedDescription,
  totalPrice,
  onSubmit,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          maxWidth: { xs: '440px', md: '800px' },
          maxHeight: { xs: '800px', md: '600px' },
          overflowY: 'inherit',
        },
      }}
    >
      <Box
        sx={{
          padding: '30px',
          paddingRight: '23px',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
          onClick={onClose}
          size="large"
          aria-label="close-btn"
        >
          <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
        </IconButton>

        <Box component={FormProvider} {...methods}>
          <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <Content
              product={productData}
              isCombo={isCombo}
              selectedDescription={selectedDescription}
              totalPrice={totalPrice}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
