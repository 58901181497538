import React, { FC, memo, useEffect } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import { DatePicker, TextField } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectClientDefaulValues } from 'store/selectors/accountSelectors';
import { selectAccountIsLoading } from 'store/selectors/isLoadingSelectors';
import { useTranslation } from 'react-i18next';

const AccountForm: FC = () => {
  const { control, setValue } = useFormContext();
  const { first_name, phone, email, birthday } = useAppSelector(
    selectClientDefaulValues
  );
  const isLoading = useAppSelector(selectAccountIsLoading);
  const { t } = useTranslation();

  useEffect(() => {
    if (first_name) {
      setValue('first_name', first_name);
    }
    if (phone) {
      setValue('phone', `+${phone}`);
    }
    if (email) {
      setValue('email', email);
    }
    if (birthday) {
      setValue('birthday', birthday);
    }
  }, [first_name, phone, email, birthday]);

  return (
    <Stack
      direction="column"
      spacing={{ xs: 3.2, sm: 3.7 }}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="first_name"
            label={t('name')}
            size="small"
            required
          />
        )}
      </Box>
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="phone"
            label={t('phone_number')}
            pattern={/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/}
            // mask="99999999999"
            size="small"
            required
          />
        )}
      </Box>
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="email"
            label={t('email')}
            size="small"
            required
          />
        )}
      </Box>
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <DatePicker
            control={control}
            name="birthday"
            label={t('date_of_birth')}
            size="small"
            required
          />
        )}
      </Box>
    </Stack>
  );
};

export default memo(AccountForm);
