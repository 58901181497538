import React, { FC } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/AddressModal/Modal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'add' | 'change';
  addressId?: number;
  isRuForm: boolean;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  type,
  addressId,
  isRuForm,
}) => {
  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          padding: '32px',
          overflowY: 'inherit',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={onClose}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>
      <Content
        onClose={onClose}
        addressId={addressId}
        type={type}
        isRuForm={isRuForm}
      />
    </Dialog>
  );
};

export default Modal;
