import React, { FC, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectClientDefaulValues } from 'store/selectors/accountSelectors';
import { TextField } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { selectAccountIsLoading } from 'store/selectors/isLoadingSelectors';

const ClientFrom: FC = () => {
  const { control, setValue } = useFormContext();
  const { first_name, phone } = useAppSelector(selectClientDefaulValues);
  const isLoading = useAppSelector(selectAccountIsLoading);
  const { t } = useTranslation();

  useEffect(() => {
    if (first_name) {
      setValue('name', first_name);
    }
    if (phone) {
      setValue('phone', phone);
    }
  }, [first_name, phone]);

  return (
    <>
      <Box sx={{ marginBottom: { xs: '13px', sm: '20px' } }}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="name"
            label={t('name')}
            size="small"
            required
          />
        )}
      </Box>
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="phone"
            label={t('phone_number')}
            size="small"
            required
          />
        )}
      </Box>
    </>
  );
};

export default ClientFrom;
