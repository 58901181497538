import React, { FC, useState, useEffect, useCallback } from 'react';
import { ShoppingBagOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  Box,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectTotalPrice } from 'store/selectors/basketSelectors';
import { usePrice } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { SnackBar } from 'components/generic';

const BasketButton: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const totalPriceCop = useAppSelector<number>(selectTotalPrice);
  const { IS_DELIVERY_STOP, DELIVERY_STOP_MESSAGE } = useAppSelector<{
    IS_DELIVERY_STOP: boolean;
    DELIVERY_STOP_MESSAGE: string;
  }>(selectAppSettings);
  const [isShowPrice, setIsShowPrice] = useState<boolean>(false);
  const [stopMessageSnackbar, setStopMessageSnackbar] =
    useState<boolean>(false);
  const totalPrice = usePrice(totalPriceCop);

  const handleMessageSnackbarOpen = useCallback(() => {
    setStopMessageSnackbar(true);
  }, [stopMessageSnackbar]);

  const handleMessageSnackbarClose = useCallback(() => {
    setStopMessageSnackbar(false);
  }, [stopMessageSnackbar]);

  const handleBasketButton = useCallback(() => {
    if (IS_DELIVERY_STOP) {
      handleMessageSnackbarOpen();
    } else {
      navigate('/cart');
    }
  }, [IS_DELIVERY_STOP]);

  useEffect(() => {
    if (totalPriceCop > 0) {
      setIsShowPrice(true);
    } else {
      setIsShowPrice(false);
    }
  }, [totalPriceCop, t]);

  return (
    <>
      <Button
        sx={{
          display: { xs: 'none', md: 'flex' },
          borderRadius: '46px',
          textTransform: 'inherit',
          padding: '12px 16px 12px 20px',
          boxShadow: 'none',
        }}
        variant="contained"
        onClick={handleBasketButton}
        size="large"
      >
        <ShoppingBagOutlined sx={{ color: 'secondary.main' }} />
        <Divider
          sx={{
            backgroundColor: 'secondary.main',
            width: '2px',
            margin: '0 16px 0 9px',
          }}
          flexItem
          variant="middle"
          orientation="vertical"
        />

        <Typography
          sx={{
            color: 'secondary.main',
            fontWeight: '300',
            whiteSpace: 'nowrap',
          }}
        >
          {totalPrice}
        </Typography>
      </Button>
      <Box
        sx={{
          position: 'relative',
          display: { xs: 'flex', md: 'none' },
          alignItems: 'center',
        }}
      >
        {isShowPrice && (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              padding: '2px 4px',
              backgroundColor: 'primary.main',
              zIndex: '2',
              position: 'absolute',
              right: '20px',
              bottom: '0px',
              textDecoration: 'none',
              whiteSpace: 'nowrap',
            }}
            onClick={handleBasketButton}
          >
            <Typography
              sx={{
                color: 'secondary.main',
                fontSize: '12px',
                fontWeight: '300',
                lineHeight: '13px',
              }}
            >
              {totalPrice}
            </Typography>
          </Stack>
        )}

        <IconButton onClick={handleBasketButton}>
          <ShoppingBagOutlined fontSize="medium" color="primary" />
        </IconButton>
      </Box>
      <SnackBar
        autoHideDuration={5000}
        isOpen={stopMessageSnackbar}
        onClose={handleMessageSnackbarClose}
        text={DELIVERY_STOP_MESSAGE}
        severity="warning"
      />
    </>
  );
};

export default BasketButton;
