import React, { FC, memo } from 'react';
import {
  StepOne,
  StepTwo,
  StepThree,
} from 'components/modals/AuthModal/Modal/AuthSteps';

interface AuthStepsProps {
  isLoadingName: boolean;
  step: number;
  isTimer: boolean;
  timer: number;
  currentTypeData: string;
  onClose: () => void;
  prevStep: () => void;
  nextStep: () => void;
  handleGetCode: () => void;
}

const AuthSteps: FC<AuthStepsProps> = ({
  isLoadingName,
  step,
  isTimer,
  timer,
  currentTypeData,
  onClose,
  prevStep,
  nextStep,
  handleGetCode,
}) => {
  switch (step) {
    case 2:
      return (
        <StepTwo
          isLoadingName={isLoadingName}
          prevStep={prevStep}
          currentEmail={currentTypeData}
          isTimer={isTimer}
          timer={timer}
          handleGetCode={handleGetCode}
        />
      );
    case 3:
      return <StepThree onClose={onClose} />;
    default:
      return <StepOne nextStep={nextStep} onClose={onClose} />;
  }
};

export default memo(AuthSteps);
