import React, { FC, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BackButton, Portal } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { useWindowDimensions } from 'hooks/menu';
import { useDisableBodyScroll, useDelayUnmount } from 'hooks';
import { useTranslation } from 'react-i18next';
import { PromoProduct } from 'types/Promotion';
import { Product } from 'components/modals/PromoProductsModal/Drawer';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  promoProducts: PromoProduct[];
  actionId: number;
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  promoProducts,
  actionId,
}) => {
  useDisableBodyScroll(isOpen);
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const { t } = useTranslation();
  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
  }));
  const open = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };
  const close = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            <Box
              sx={{ position: 'fixed', top: '24px', left: '20px' }}
              onClick={close}
            >
              <BackButton direction="down" />
            </Box>
            <Box
              sx={{
                overflow: 'auto',
                height: '100vh',
                width: '100%',
              }}
            >
              <Box sx={{ padding: '100px 20px 100px 20px' }}>
                <Typography
                  sx={{
                    fontSize: '24px',
                    lineHeight: '28px',
                    fontWeight: '400',
                    marginBottom: '20px',
                  }}
                >
                  {t('choose_a_gift')}
                </Typography>
                <Stack direction="column" spacing={2}>
                  {promoProducts.map((product) => (
                    <Product
                      key={product.id}
                      itemId={product.item}
                      categoryId={product.menu}
                      itemOption={product.item_option}
                      actionId={actionId}
                      onClose={onClose}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          </animated.div>
          <animated.div
            style={{
              position: 'fixed',
              bottom: '-10px',
              left: '0px',
              right: '0px',
              touchAction: 'none',
              zIndex: '200',
              boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.25)',
              y,
            }}
          >
            <Box
              sx={{
                width: '100%',
                paddingTop: '24px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 34px)',
                zIndex: '1',
                backgroundColor: 'rgba(255, 255, 255, .15)',
                backdropFilter: 'blur(3px)',
              }}
            >
              <Button
                sx={{
                  textTransform: 'inherit',
                  width: '100%',
                  borderRadius: '6px',
                  fontSize: '16px',
                  lineHeight: '16px',
                  height: '100%',
                  padding: '14px 0',
                  color: 'secondary.main',
                  border: '2px solid',
                  '&:hover': {
                    border: '2px solid',
                  },
                  borderColor: 'primary.main',
                  whiteSpace: 'nowrap',
                  boxShadow: 'none',
                  backgroundColor: 'common.white',
                }}
                variant="outlined"
                fullWidth
                onClick={close}
              >
                {t('close')}
              </Button>
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;
