import React, { FC } from 'react';
import { Typography, Box } from '@mui/material';
import { MainTitle } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectBasketLength,
  selectTotalPrice,
} from 'store/selectors/basketSelectors';
import { useTitleEnding } from 'hooks/basket';
import { usePrice } from 'hooks';
import { useTranslation } from 'react-i18next';

const CalculateTitle: FC = () => {
  const basketLength = useAppSelector<number>(selectBasketLength);
  const totalPrice = useAppSelector<number>(selectTotalPrice);
  const ending = useTitleEnding(basketLength);
  const currentPrice = usePrice(totalPrice);
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      {basketLength > 0 ? (
        <Box>
          <MainTitle>{t('cart')}</MainTitle>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: { xs: '14px', sm: '18px' },
              lineHeight: { xs: '16px', sm: '19px' },
              fontWeight: '400',
              color: 'text.main',
            }}
          >
            ({basketLength} {ending}, {currentPrice})
          </Typography>
        </Box>
      ) : (
        <MainTitle>{t('cart')}</MainTitle>
      )}
    </Box>
  );
};

export default CalculateTitle;
