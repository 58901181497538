import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

interface FormValues {
  [x: string]: any;
}

const useAccountFormData = (formValues: FormValues) => {
  const [cookies] = useCookies();
  const { email, first_name, last_name, phone, birthday, sex } = formValues;

  const birthdayDate = useMemo(() => {
    const convertValue = new Date(birthday)
      .toLocaleString('fr-CA')
      .slice(0, 10);

    return convertValue;
  }, [formValues]);

  return {
    api_key: cookies.api_key,
    body: {
      email,
      first_name,
      last_name,
      phone,
      birthday: birthdayDate,
      sex,
    },
  };
};

export default useAccountFormData;
