import React, { FC, memo, useMemo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextField } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAuthIsLoading } from 'store/selectors/isLoadingSelectors';
import { useTranslation } from 'react-i18next';
import { selectAuthType } from 'store/selectors/appSelectors';

interface StepTwoProps {
  isLoadingName: boolean;
  isTimer: boolean;
  timer: number;
  currentEmail: string;
  prevStep: () => void;
  handleGetCode: () => void;
}

const StepTwo: FC<StepTwoProps> = ({
  isLoadingName,
  isTimer,
  timer,
  prevStep,
  currentEmail,
  handleGetCode,
}) => {
  const { control } = useFormContext();
  const isLoading = useAppSelector(selectAuthIsLoading);
  const authType = useAppSelector(selectAuthType);
  const { t } = useTranslation();

  const hint = useMemo(() => {
    if (authType === 1) {
      return t('auth_tip_4');
    }
    return t('auth_tip_3');
  }, [authType]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box>
        <Typography sx={{ marginBottom: '12px' }} variant="h4">
          {t('confirmation_code')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '45px',
            lineHeight: '21px',
          }}
        >
          {hint} {currentEmail}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '30px' }}>
        <TextField
          control={control}
          name="code"
          label={t('input_code')}
          mask="9999"
          maskPlaceholder={null}
          required
          loading={isLoadingName || isLoading}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {isTimer ? (
          <Typography sx={{ fontSize: '13px', color: 'grey.700' }}>
            {t('try_to_send_again', { timer })}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: '13px',
              lineHeight: '16px',
              color: 'secondary.main',
              cursor: 'pointer',
              alignSelf: 'flex-start',
            }}
            onClick={handleGetCode}
          >
            {t('send_again')}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
        gap={2}
      >
        <Button
          sx={{
            padding: '10px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'secondary.main',
            boxShadow: 'none',
          }}
          variant="text"
          color="inherit"
          onClick={prevStep}
          fullWidth
        >
          {t('back')}
        </Button>

        <Button
          sx={{
            padding: '15px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'secondary.main',
            boxShadow: 'none',
          }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default memo(StepTwo);
