import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LanguageButtonProps {
  handleOpenLanguageDrawer: () => void;
}

const LanguageButton: FC<LanguageButtonProps> = ({
  handleOpenLanguageDrawer,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack direction="row" spacing={1} onClick={handleOpenLanguageDrawer}>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '19px',
          fontWeight: '600',
          color: 'secondary.main',
        }}
      >
        {i18n.language.slice(-2)}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '19px',
          fontWeight: '400',
        }}
      >
        {t('language')}
      </Typography>
    </Stack>
  );
};

export default LanguageButton;
