import React, { FC, useCallback, useState, useMemo } from 'react';
import { Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectBonus, selectName } from 'store/selectors/accountSelectors';
import { AuthModal } from 'components/modals';
import { useTranslation } from 'react-i18next';
import { authSlice } from 'store/reducers/AuthSlice/AuthSlice';
import { Button, Typography } from '@mui/material';
import { selectAppSettings } from 'store/selectors/appSelectors';

const LoginButton: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  const { t } = useTranslation();
  const { clearAuthResponse } = authSlice.actions;
  const firstName = useAppSelector<string>(selectName);
  const bonuses = useAppSelector<number>(selectBonus);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { BONUS_PROGRAM } =
    useAppSelector<{ BONUS_PROGRAM: boolean }>(selectAppSettings);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    dispatch(clearAuthResponse());
  }, [isModalOpen]);

  const handleNavigate = () => {
    navigate('/account');
  };

  const buttonHandler = useMemo(() => {
    if (isAuth) {
      return handleNavigate;
    }
    return handleOpenModal;
  }, [isAuth]);

  const buttonName = useMemo(() => {
    if (isAuth && firstName) {
      return firstName;
    }
    if (isAuth && !firstName) {
      return t('account');
    }
    return t('sign_in');
  }, [isAuth, firstName, t]);

  return (
    <>
      <Button
        sx={{
          display: { xs: 'none', md: 'flex' },
          borderRadius: '46px',
          fontSize: '16px',
          fontWeight: '600',
          border: '2px solid',
          borderColor: 'primary.main',
          color: 'secondary.main',
          gap: '5px',
          '&:hover': {
            border: '2px solid',
          },
          whiteSpace: 'nowrap',
        }}
        variant="outlined"
        onClick={buttonHandler}
      >
        {buttonName}{' '}
        {BONUS_PROGRAM && isAuth && bonuses ? (
          <Typography
            sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '300' }}
          >
            ({bonuses} {t('bonuses')})
          </Typography>
        ) : (
          <Person color="inherit" fontSize="medium" />
        )}
      </Button>

      <AuthModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default LoginButton;
