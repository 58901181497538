import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { ResponseAuthCode, ResponseVerifyCode } from 'types/Auth';
import { getCode, verifyCode } from 'store/reducers/AuthSlice/ActionCreators';

const cookies = new Cookies();
interface AuthState {
  api_key: string;
  codeResponse: ResponseAuthCode;
  verifyResponse: ResponseVerifyCode;
  isLoading: boolean;
  error: string;
}

const initialState: AuthState = {
  api_key: '',
  codeResponse: {
    reason: null,
    success: false,
    uid: '',
  },
  verifyResponse: {
    reason: null,
    success: false,
    api_key: '',
  },
  isLoading: false,
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addApiKey(state, action: PayloadAction<string>) {
      state.api_key = action.payload;
    },
    removeApiKey(state) {
      state.api_key = '';
    },
    clearAuthResponse(state) {
      state.codeResponse = {
        reason: null,
        success: false,
        uid: '',
      };
      state.error = '';
    },
  },
  extraReducers: {
    [getCode.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseAuthCode>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.codeResponse = action.payload;
    },
    [getCode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getCode.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [verifyCode.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseVerifyCode>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.verifyResponse = action.payload;
      if (action.payload.success) {
        cookies.set('api_key', action.payload.api_key);
      }
    },
    [verifyCode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [verifyCode.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default authSlice.reducer;
