import React, { FC, useEffect, useRef, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useSrcImage } from 'hooks';
import { MenuProduct } from 'types/Menu';
import { Slider } from 'components/modals/ProductModal/Modal/Content/Regular';
import {
  CookingOptions,
  GroupModifiers,
  Ingredients,
  Options,
} from 'components/modals/ProductModal/Buttons';

interface RegularProps {
  data: MenuProduct;
}

const Regular: FC<RegularProps> = ({ data }) => {
  const {
    name,
    group_modifiers,
    options,
    ingredients,
    cooking_options,
    src,
    additional_images,
  } = data;
  const image = useSrcImage(src);
  const titleRef = useRef<any>(null);
  const [titleHeight, setTitleHeight] = useState<number>(0);

  useEffect(() => {
    setTitleHeight(titleRef.current.clientHeight);
  }, [titleRef]);

  return (
    <Box
      sx={{
        padding: '0',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        overflow: 'hidden',
      }}
    >
      {additional_images.length > 0 ? (
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Slider images={additional_images} />
        </Box>
      ) : (
        <Box
          sx={{
            height: { xs: '300px', md: '380px' },
            width: { xs: '100%', md: '50%' },
            minWidth: { xs: 'auto', md: '380px' },
            marginBottom: '25px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            component="img"
            src={image}
          />
        </Box>
      )}

      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          minWidth: { xs: 'auto', md: '380px' },
          paddingLeft: { xs: '0px', md: '20px' },
        }}
      >
        <Typography ref={titleRef} sx={{ fontSize: '28px', fontWeight: '600' }}>
          {name}
        </Typography>
        <Box
          sx={{
            overflow: 'auto',
            padding: '20px 0',
            maxHeight: `calc(450px - ${titleHeight}px)`,
            '&::-webkit-scrollbar': {
              width: 7,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: '20px',
            },
          }}
        >
          {options.length > 1 && <Options options={options} />}
          {cooking_options.length > 0 && (
            <CookingOptions cookingOptions={cooking_options} />
          )}
          {ingredients.length > 0 && <Ingredients ingredients={ingredients} />}
          {group_modifiers.length > 0 && (
            <GroupModifiers groupModifiers={group_modifiers} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Regular;
