import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from 'types/Account';
import {
  getAccount,
  updateAccountData,
  calculateAvailablePoint,
} from 'store/reducers/AccountSlice/ActionCreators';

interface AccountState {
  account: Account;
  isLoading: boolean;
  availablePoints: any;
  error: string;
}

const initialState: AccountState = {
  account: {
    reason: null,
    success: false,
    data: {
      id: 0,
      is_superuser: false,
      phone: '',
      email: '',
      first_name: '',
      last_name: '',
      src: '',
      is_email_notification: false,
      is_sms_notification: false,
      is_push_notification: false,
      sex: 0,
      birthday: '',
      bonus_accounts: [],
      city_id: 0,
    },
  },
  availablePoints: null,
  isLoading: false,
  error: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    removeAccountData(state) {
      state.account.data = {
        id: 0,
        is_superuser: false,
        phone: '',
        email: '',
        first_name: '',
        last_name: '',
        src: '',
        is_email_notification: false,
        is_sms_notification: false,
        is_push_notification: false,
        sex: 0,
        birthday: '',
        bonus_accounts: [],
        city_id: 0,
      };
    },
  },
  extraReducers: {
    [getAccount.fulfilled.type]: (state, action: PayloadAction<Account>) => {
      state.isLoading = false;
      state.error = '';
      state.account = action.payload;
    },
    [getAccount.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAccount.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateAccountData.fulfilled.type]: (
      state,
      action: PayloadAction<Account>
    ) => {
      state.isLoading = false;
      state.error = '';
      if (!action.payload.success) {
        state.account.reason = action.payload.reason;
      }
      if (action.payload.success) {
        state.account = action.payload;
      }
    },
    [updateAccountData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateAccountData.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [calculateAvailablePoint.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.availablePoints = action.payload;
    },
    [calculateAvailablePoint.pending.type]: (state) => {
      state.isLoading = true;
    },
    [calculateAvailablePoint.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default accountSlice.reducer;
