import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettings } from 'store/selectors/appSelectors';

const useSrcImage = (src: string) => {
  const [image, setImage] = useState<string>('');
  const { ITEM_PLACEHOLDER_IMAGE } = useAppSelector(selectAppSettings);

  useEffect(() => {
    if (src) {
      setImage(`${process.env.REACT_APP_BASE_URL}${src}`);
    } else {
      setImage(ITEM_PLACEHOLDER_IMAGE);
    }
  }, [src]);

  return image;
};

export default useSrcImage;
