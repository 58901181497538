import { useEffect } from 'react';
import { calculateBasket } from 'store/reducers/BasketSlice/ActionCreators';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectBasketData,
  selectIsDelivery,
  selectOrderPromotions,
} from 'store/selectors/orderSelectors';
import { selectAddressForCalculateBasket } from 'store/selectors/addressSelectors';
import { selectApiKey } from 'store/selectors/authSelectors';

const useCalculateBasket = () => {
  const dispatch = useAppDispatch();
  const api_key = useAppSelector(selectApiKey);
  const basketData = useAppSelector(selectBasketData);
  const selectedAddressData = useAppSelector(selectAddressForCalculateBasket);
  const isDelivery = useAppSelector(selectIsDelivery);
  const promotions = useAppSelector(selectOrderPromotions);

  useEffect(() => {
    dispatch(
      calculateBasket({
        api_key,
        platform: 2,
        brand_id: 0,
        body: {
          basket: basketData,
          is_delivery: isDelivery,
          address: selectedAddressData,
          actions: promotions,
        },
      })
    );
  }, [isDelivery, basketData, selectedAddressData, promotions]);
};

export default useCalculateBasket;
