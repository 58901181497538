import React, { FC, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SelectField, TextArea, TextField } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAvailableRestaurantsList } from 'store/selectors/restaurantSelectors';
import {
  selectAccountIsLoading,
  selectBookingIsLoading,
  selectCityIsLoading,
  selectRestaurantIsLoading,
  selectServerTimeIsLoading,
} from 'store/selectors/isLoadingSelectors';
import { selectServerTime } from 'store/selectors/appSelectors';
import { useTranslation } from 'react-i18next';
import { selectClientDefaulValues } from 'store/selectors/accountSelectors';
import { DateTimePicker } from 'components/reserve/Booking/BookingForm';

const BookingForm: FC = () => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const serverTime = useAppSelector(selectServerTime);
  const isCityLoading = useAppSelector(selectCityIsLoading);
  const isServerTimeLoading = useAppSelector(selectServerTimeIsLoading);
  const isListLoading = useAppSelector(selectRestaurantIsLoading);
  const isBookingLoading = useAppSelector(selectBookingIsLoading);
  const availableRestaurants = useAppSelector(selectAvailableRestaurantsList);
  const { first_name, phone, email, birthday } = useAppSelector(
    selectClientDefaulValues
  );
  const isAccountLoading = useAppSelector(selectAccountIsLoading);

  useEffect(() => {
    if (first_name) {
      setValue('name', first_name);
    }
    if (phone) {
      setValue('phone', `+${phone}`);
    }
    if (email) {
      setValue('email', email);
    }

    setValue('day', new Date(serverTime));
  }, [first_name, phone, email, birthday]);

  return (
    <>
      <Box sx={{ marginBottom: { xs: '26px', sm: '20px' } }}>
        {isListLoading ? (
          <Skeleton variant="rectangular" height={50} />
        ) : (
          <SelectField
            control={control}
            name="restaurant"
            label={t('restaurant')}
            required
            selectItem={availableRestaurants}
            disabled={isBookingLoading}
            defaultValue=""
          />
        )}
      </Box>

      <Box sx={{ marginBottom: { xs: '26px', sm: '20px' } }}>
        {isAccountLoading ? (
          <Skeleton variant="rectangular" height={50} />
        ) : (
          <TextField
            control={control}
            name="name"
            label={t('name')}
            required
            disabled={isBookingLoading}
          />
        )}
      </Box>
      <Box sx={{ marginBottom: { xs: '26px', sm: '20px' } }}>
        {isAccountLoading ? (
          <Skeleton variant="rectangular" height={50} />
        ) : (
          <TextField
            control={control}
            name="phone"
            label={t('phone_number')}
            pattern={/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/}
            required
            type="tel"
            disabled={isBookingLoading}
          />
        )}
      </Box>
      {isCityLoading && isServerTimeLoading ? (
        <Skeleton sx={{ width: '100%' }} variant="rectangular" height={50} />
      ) : (
        <DateTimePicker minDate={new Date(serverTime)} />
      )}
      <Box sx={{ marginBottom: { xs: '26px', sm: '20px' } }}>
        <TextField
          control={control}
          name="quantity_people"
          label={t('number_of_persons')}
          required
          mask="999"
          maskPlaceholder={null}
          disabled={isBookingLoading}
        />
      </Box>
      <Box sx={{ marginBottom: { xs: '26px', sm: '20px' } }}>
        {isAccountLoading ? (
          <Skeleton variant="rectangular" height={50} />
        ) : (
          <TextField
            control={control}
            name="email"
            label={t('email')}
            disabled={isBookingLoading}
          />
        )}
      </Box>
      <Box sx={{ marginBottom: { xs: '26px', sm: '20px' } }}>
        <TextArea
          control={control}
          name="commetary"
          label={t('comment')}
          disabled={isBookingLoading}
        />
      </Box>
    </>
  );
};

export default BookingForm;
