import { useCallback, useMemo, useState, useEffect } from 'react';
import { useAuth } from 'hooks';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';
import { authSlice } from 'store/reducers/AuthSlice/AuthSlice';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { selectCutlery } from 'store/selectors/basketSelectors';
import { HandleType } from 'types/Basket';
import { selectApiKey } from 'store/selectors/authSelectors';

const useBasket = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    setError,
    formState: { isValid },
  } = methods;
  const { isAuth } = useAuth();
  const { clearAuthResponse } = authSlice.actions;
  const { IS_CUTLERY_REQUIRED } =
    useAppSelector<{ IS_CUTLERY_REQUIRED: boolean }>(selectAppSettings);
  const api_key = useAppSelector<string>(selectApiKey);
  const cutleryCount = useAppSelector<number>(selectCutlery);
  const { MESSAGE_INFO_TYPE } =
    useAppSelector<{ MESSAGE_INFO_TYPE: number }>(selectAppSettings);
  const [authModal, setAuthModal] = useState<boolean>(false);
  const [addressModal, setAddressModal] = useState<boolean>(false);
  const [phoneSnackbar, setPhoneSnackbar] = useState<boolean>(false);
  const [cutlerySnackbar, setCutlerySnackbar] = useState<boolean>(false);
  const [formValidateSnackbar, setFormValidateSnackbar] =
    useState<boolean>(false);
  const [infoMessageSnackbar, setInfoMessageSnackbar] =
    useState<boolean>(false);

  const handleOpen = useCallback(
    (type: HandleType) => () => {
      if (type === 'phone') {
        setPhoneSnackbar(true);
      }
      if (type === 'cutlery') {
        setCutlerySnackbar(true);
      }
      if (type === 'auth') {
        setAuthModal(true);
      }
      if (type === 'address') {
        setAddressModal(true);
      }
      if (type === 'formValidate') {
        setFormValidateSnackbar(true);
      }
      if (type === 'infoMessage') {
        setInfoMessageSnackbar(true);
      }
    },
    [
      phoneSnackbar,
      cutlerySnackbar,
      authModal,
      addressModal,
      formValidateSnackbar,
      infoMessageSnackbar,
    ]
  );

  const handleClose = useCallback(
    (type: HandleType) => () => {
      if (type === 'phone') {
        setPhoneSnackbar(false);
      }
      if (type === 'cutlery') {
        setCutlerySnackbar(false);
      }
      if (type === 'auth') {
        setAuthModal(false);
        dispatch(clearAuthResponse());
      }
      if (type === 'address') {
        setAddressModal(false);
      }
      if (type === 'formValidate') {
        setFormValidateSnackbar(false);
      }
      if (type === 'infoMessage') {
        setInfoMessageSnackbar(false);
      }
    },
    [
      phoneSnackbar,
      cutlerySnackbar,
      authModal,
      addressModal,
      formValidateSnackbar,
      infoMessageSnackbar,
    ]
  );

  const onSubmit = (formData: any) => {
    if (cutleryCount === 0 && IS_CUTLERY_REQUIRED) {
      const callCutleryHandle = handleOpen('cutlery');
      callCutleryHandle();
    } else {
      dispatch(
        updateAccountData({
          api_key,
          body: {
            first_name: formData.name,
            phone: formData.phone,
          },
        })
      )
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/payment');
          } else if (res.reason === 'PHONE_ALREADY_USE') {
            setError('phone', {
              type: 'server',
            });
            const callPhoneHandle = handleOpen('phone');
            callPhoneHandle();
          }
        });
    }
  };

  const handleCheckoutButton = useMemo(() => {
    if (isAuth && !isValid) {
      return handleOpen('formValidate');
    }
    if (!isAuth) {
      return handleOpen('auth');
    }
    return () => null;
  }, [isAuth, isValid]);

  useEffect(() => {
    if (MESSAGE_INFO_TYPE === 1 || MESSAGE_INFO_TYPE === 2) {
      const callInfoMessage = handleOpen('infoMessage');
      callInfoMessage();
    }
  }, [MESSAGE_INFO_TYPE]);

  return {
    onSubmit,
    methods,
    handleOpen,
    handleClose,
    phoneSnackbar,
    cutlerySnackbar,
    formValidateSnackbar,
    infoMessageSnackbar,
    authModal,
    addressModal,
    handleCheckoutButton,
  };
};

export default useBasket;
