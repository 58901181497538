import { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';
import { useAccountFormData } from 'hooks';
import { getAddresses } from 'store/reducers/AddressSlice/ActionCreators';
import { getOrderHistory } from 'store/reducers/OrderSlice/ActionCreators';
import { selectOrderIsLoading } from 'store/selectors/isLoadingSelectors';
import { selectActiveOrdersData } from 'store/selectors/orderSelectors';
import { selectApiKey } from 'store/selectors/authSelectors';
import { OrderHistoryItem } from 'types/Order';

const useAccount = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const formValues = methods.watch();
  const patchData = useAccountFormData(formValues);
  const isLoadingOrder = useAppSelector<boolean>(selectOrderIsLoading);
  const api_key = useAppSelector<string>(selectApiKey);
  const activeOrdersData = useAppSelector<OrderHistoryItem[]>(
    selectActiveOrdersData
  );
  const [isSnackbarError, setIsSnackbarError] = useState<boolean>(false);
  const [isSnackbarSuccess, setIsSnackbarSuccess] = useState<boolean>(false);

  const handleSnackbarErrorOpen = useCallback(() => {
    setIsSnackbarError(true);
  }, [isSnackbarError]);

  const handleSnackbarErrorClose = useCallback(() => {
    setIsSnackbarError(false);
  }, [isSnackbarError]);

  const handleSnackbarSuccessOpen = useCallback(() => {
    setIsSnackbarSuccess(true);
  }, [isSnackbarSuccess]);

  const handleSnackbarSuccessClose = useCallback(() => {
    setIsSnackbarSuccess(false);
  }, [isSnackbarSuccess]);

  const onSubmit = () => {
    dispatch(updateAccountData(patchData))
      .unwrap()
      .then((res) => {
        if (res.success) {
          handleSnackbarSuccessOpen();
        } else {
          handleSnackbarErrorOpen();
        }
      });
  };

  useEffect(() => {
    dispatch(getAddresses(api_key));
    dispatch(getOrderHistory(api_key));
  }, [api_key]);

  return {
    isSnackbarError,
    isSnackbarSuccess,
    methods,
    isLoadingOrder,
    activeOrdersData,
    onSubmit,
    handleSnackbarErrorClose,
    handleSnackbarSuccessClose,
  };
};

export default useAccount;
