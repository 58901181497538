import React, { FC, useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { MenuTab } from 'types/MenuTabs';
import {
  AuthModal,
  LanguageModal,
  NavModal,
  SelectCityModal,
} from 'components/modals';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { authSlice } from 'store/reducers/AuthSlice/AuthSlice';

interface NavMobileProps {
  menuTabs: MenuTab[];
}

const NavMobile: FC<NavMobileProps> = ({ menuTabs }) => {
  const dispatch = useAppDispatch();
  const { clearAuthResponse } = authSlice.actions;
  const [isMenuDrawer, setMenuIsDrawer] = useState<boolean>(false);
  const [isAuthModal, setIsAuthModal] = useState<boolean>(false);
  const [isSelectCityModal, setIsSelectCityModal] = useState<boolean>(false);
  const [isLanguageModal, setIsLanguageModal] = useState<boolean>(false);

  const handleMenuDrawerOpen = useCallback(() => {
    setMenuIsDrawer(true);
  }, [isMenuDrawer]);
  const handleMenuDrawerClose = useCallback(() => {
    setMenuIsDrawer(false);
  }, [isMenuDrawer]);

  const handleAuthModalOpen = useCallback(() => {
    setIsAuthModal(true);
  }, [isAuthModal]);
  const handleAuthModalClose = useCallback(() => {
    setIsAuthModal(false);
    dispatch(clearAuthResponse());
  }, [isAuthModal]);

  const handleSelectCityModalOpen = useCallback(() => {
    setIsSelectCityModal(true);
  }, [isSelectCityModal]);
  const handleSelectCityModalClose = useCallback(() => {
    setIsSelectCityModal(false);
  }, [isSelectCityModal]);

  const handleLanguageModalOpen = useCallback(() => {
    setIsLanguageModal(true);
  }, [isLanguageModal]);
  const handleLanguageModalClose = useCallback(() => {
    setIsLanguageModal(false);
  }, [isLanguageModal]);

  return (
    <>
      <IconButton onClick={handleMenuDrawerOpen}>
        <MenuIcon color="primary" />
      </IconButton>
      <NavModal
        isOpen={isMenuDrawer}
        onClose={handleMenuDrawerClose}
        onAuthModalOpen={handleAuthModalOpen}
        onSelectCityModalOpen={handleSelectCityModalOpen}
        onLanguageModalOpen={handleLanguageModalOpen}
        menuTabs={menuTabs}
      />
      <AuthModal isOpen={isAuthModal} onClose={handleAuthModalClose} />
      <SelectCityModal
        isOpen={isSelectCityModal}
        onClose={handleSelectCityModalClose}
      />
      <LanguageModal
        isOpen={isLanguageModal}
        onClose={handleLanguageModalClose}
      />
    </>
  );
};

export default NavMobile;
