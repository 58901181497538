import { useMemo } from 'react';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBlogPosts } from 'store/selectors/blogSelectors';

const useMenuTabs = () => {
  const { BOOKING_TABLE } = useAppSelector(selectAppSettings);
  const blogPosts = useAppSelector(selectBlogPosts);

  const menuTabs = useMemo(() => {
    const tabs = [
      {
        label: 'home',
        path: '/',
      },
      {
        label: 'menu',
        path: '/menu',
      },
      {
        label: 'reserve',
        path: '/reserve',
      },
      {
        label: 'about',
        path: '/about',
      },
      {
        label: 'blog',
        path: '/blog',
      },
    ];
    if (!blogPosts) {
      return tabs.filter((tab) => tab.label !== 'blog');
    }
    if (!BOOKING_TABLE) {
      return tabs.filter((tab) => tab.label !== 'reserve');
    }

    return tabs;
  }, [BOOKING_TABLE]);

  return menuTabs;
};

export default useMenuTabs;
