import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { PageNotFound } from 'components/icons';
import { BackToButton } from 'components/pageNotFoundView';
import { useTranslation } from 'react-i18next';

const PageNotFoundView: FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: '40px 0px 100px 0px' }}>
      <Container>
        <BackToButton />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '30px',
          }}
        >
          <PageNotFound
            sx={{
              height: { xs: '144px', sm: '257px' },
              width: { xs: '208px', sm: '371px' },
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: '24px', sm: '96px' },
              lineHeight: { xs: '29px', sm: '115px' },
              fontWeight: '400',
            }}
          >
            404
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '28px' },
              lineHeight: { xs: '19px', sm: '36px' },
              fontWeight: '300',
            }}
          >
            {t('page_not_found')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PageNotFoundView;
