import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface CategoryTitleProps {
  children: React.ReactNode;
}

const CategoryTitle: FC<CategoryTitleProps> = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Montserrat',
        fontSize: { xs: '20px', sm: '30px' },
        lineHeight: { xs: '24px', sm: '30px' },
      }}
      variant="h2"
    >
      {children}
    </Typography>
  );
};

export default CategoryTitle;
