import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import animationData from 'lotties/cartOrderComplete.json';
import Lottie from 'react-lottie';

interface OrderResultParams {
  orderNumber: string | number | null;
  orderMessage: string | null;
}

/**
 * Displays order result element
 */
const OrderResult: FC<OrderResultParams> = ({ orderNumber, orderMessage }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '24px', sm: '30px' },
          fontWeight: '400',
          color: 'text.primary',
          marginBottom: '30px',
        }}
        variant="h3"
      >
        {orderMessage || 'Order successfully placed'}
      </Typography>
      <Box sx={{ maxWidth: '300px', marginBottom: '40px' }}>
        <Lottie options={defaultOptions} />
      </Box>
      {orderNumber && (
        <>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xs: '20px', sm: '25px' },
              fontWeight: '400',
              color: 'text.primary',
              marginBottom: '11px',
            }}
            variant="body1"
          >
            Your order number
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xs: '24px', sm: '30px' },
              fontWeight: '300',
              color: 'text.secondary',
              marginBottom: '30px',
            }}
            variant="body1"
          >
            {orderNumber}
          </Typography>
        </>
      )}
    </>
  );
};

export default OrderResult;
