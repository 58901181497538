import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettings } from 'store/selectors/appSelectors';
import i18next from 'i18next';

const usePrice = (priceCop: number) => {
  const { CURRENCY_SYMBOL } = useAppSelector(selectAppSettings);

  return Intl.NumberFormat(i18next.language || 'en-IN', {
    style: 'currency',
    currency: `${CURRENCY_SYMBOL}` || 'USD',
  }).format(priceCop / 100);
};

export default usePrice;
