import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addressGeocode } from 'types/Geocode';
import { getAddressGeocode } from './ActionCreators';

interface AddressState {
  geocode: addressGeocode;
  isLoading: boolean;
  error: string;
}

const initialState: AddressState = {
  geocode: {
    data: {
      longitude: 0,
      latitude: 0,
    },
    reason: null,
    success: false,
  },
  isLoading: false,
  error: '',
};

export const geocodeSlice = createSlice({
  name: 'geocode',
  initialState,
  reducers: {},
  extraReducers: {
    [getAddressGeocode.fulfilled.type]: (
      state,
      action: PayloadAction<addressGeocode>
    ) => {
      state.isLoading = false;
      state.error = '';
      if (action.payload.success === true) {
        state.geocode = action.payload;
      }

      if (action.payload.success === false) {
        state.geocode.reason = action.payload.reason;
        state.geocode.success = action.payload.success;
      }
    },
    [getAddressGeocode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAddressGeocode.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default geocodeSlice.reducer;
