import React, { FC } from 'react';
import { Container, Box } from '@mui/material';
import {
  Receiving,
  BasketList,
  Promo,
  Total,
  BackToMenuButton,
  CalculateTitle,
  Recomendations,
  EmptyBasket,
  CheckoutButton,
} from 'components/basket';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { MainTitle, SnackBar } from 'components/generic';
import { FormProvider } from 'react-hook-form';
import { selectIsEmptyBasket } from 'store/selectors/basketSelectors';
import { useTranslation } from 'react-i18next';
import { AddressModal, AuthModal } from 'components/modals';
import { useBasket } from 'hooks/basket';
import { selectAppSettings } from 'store/selectors/appSelectors';

const Basket: FC = () => {
  const {
    onSubmit,
    methods,
    handleOpen,
    handleClose,
    phoneSnackbar,
    cutlerySnackbar,
    formValidateSnackbar,
    infoMessageSnackbar,
    authModal,
    addressModal,
    handleCheckoutButton,
  } = useBasket();
  const isEmpty = useAppSelector<boolean>(selectIsEmptyBasket);
  const { MESSAGE_INFO_TEXT } = useAppSelector(selectAppSettings);
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        {isEmpty ? (
          <EmptyBasket />
        ) : (
          <>
            <Box sx={{ marginBottom: { xs: '6px', sm: '50px' } }}>
              <BackToMenuButton />
            </Box>
            <Box sx={{ marginBottom: { xs: '10px', sm: '30px' } }}>
              <CalculateTitle />
            </Box>
            <Box sx={{ marginBottom: { xs: '19px', sm: '55px' } }}>
              <BasketList />
            </Box>
            <Box sx={{ marginBottom: { xs: '40px', sm: '80px' } }}>
              <Recomendations />
            </Box>
            <Box sx={{ marginBottom: { xs: '14px', sm: '30px' } }}>
              <MainTitle>{t('receiving_an_order')}</MainTitle>
            </Box>
            <FormProvider {...methods}>
              <Box
                component="form"
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ marginBottom: { xs: '40px', sm: '80px' } }}>
                  <Receiving
                    onAddressModalOpen={handleOpen('address')}
                    onAuthModalOpen={handleOpen('auth')}
                  />
                </Box>
                <Box sx={{ marginBottom: { xs: '40px', sm: '80px' } }}>
                  <Promo />
                </Box>
                <Total />
                <CheckoutButton handleCheckoutButton={handleCheckoutButton} />
              </Box>
            </FormProvider>
          </>
        )}
      </Container>
      <AddressModal
        isOpen={addressModal}
        onClose={handleClose('address')}
        type="add"
      />
      <AuthModal isOpen={authModal} onClose={handleClose('auth')} />
      <SnackBar
        autoHideDuration={2000}
        isOpen={phoneSnackbar}
        onClose={handleClose('phone')}
        text={t('phone_already_use')}
        severity="error"
      />
      <SnackBar
        autoHideDuration={2000}
        isOpen={cutlerySnackbar}
        onClose={handleClose('cutlery')}
        text={t('please_specify_the_number_of_cutlery')}
        severity="warning"
      />
      <SnackBar
        autoHideDuration={2000}
        isOpen={formValidateSnackbar}
        onClose={handleClose('formValidate')}
        text={t('please_fill_in_the_required_fields')}
        severity="error"
      />
      <SnackBar
        autoHideDuration={10000}
        isOpen={infoMessageSnackbar}
        onClose={handleClose('infoMessage')}
        text={MESSAGE_INFO_TEXT}
        severity="info"
      />
    </Box>
  );
};

export default Basket;
