import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import bg1 from 'assets/images/mobileImages/bg1.png';
import bg2 from 'assets/images/mobileImages/bg2.png';
import bg3 from 'assets/images/mobileImages/bg3.png';
import image1 from 'assets/images/mobileImages/desserts1.png';
import image2 from 'assets/images/mobileImages/desserts2.png';
import image3 from 'assets/images/mobileImages/desserts3.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MobileOffers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSendFlowers = () => {
    navigate('/menu#148');
  };

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '130px',
          marginBottom: '40px',
          textAlign: 'right',
          padding: '20px 0',
        }}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              lineHeight: '22px',
              fontWeight: '600',
              color: '#D5DDF4',
              padding: '6px 20px',
              backgroundColor: '#9CA4BC',
              marginBottom: '17px',
              alignSelf: 'flex-end',
            }}
          >
            {t('home_page.offer_title_1')}
          </Typography>
          <Button
            sx={{
              fontSize: '18px',
              color: 'secondary.main',
              fontWeight: '300',
              textTransform: 'inherit',
              borderRadius: '10px',
              backgroundColor: '#ffffff',
              boxShadow: 'none',
              marginRight: '8px',
            }}
            variant="contained"
          >
            {t('order_now')}
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-55px',
            left: '0px',
            width: '220px',
            height: '220px',
            zIndex: '2',
          }}
        >
          <Box
            component="img"
            src={image1}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            zIndex: '-1',
          }}
        >
          <Box
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            component="img"
            src={bg1}
          />
        </Box>
      </Box>
      {/* ========================================================================== */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '130px',
          marginBottom: '40px',
          textAlign: 'left',
          padding: '20px 0',
        }}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              lineHeight: '22px',
              fontWeight: '600',
              color: '#ffffff',
              padding: '6px 20px',
              backgroundColor: '#B1A476',
              marginBottom: '17px',
              alignSelf: 'flex-start',
            }}
          >
            {t('home_page.offer_title_2')}
          </Typography>
          <Button
            sx={{
              fontSize: '18px',
              color: 'secondary.main',
              fontWeight: '300',
              textTransform: 'inherit',
              borderRadius: '10px',
              backgroundColor: '#ffffff',
              boxShadow: 'none',
              marginLeft: '8px',
            }}
            variant="contained"
            onClick={handleSendFlowers}
          >
            {t('home_page.send_flowers')}
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-30px',
            right: '0px',
            width: '180px',
            height: '180px',
            zIndex: '2',
          }}
        >
          <Box
            component="img"
            src={image2}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            zIndex: '-1',
          }}
        >
          <Box
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            component="img"
            src={bg2}
          />
        </Box>
      </Box>
      {/* ========================================================================== */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '130px',
          marginBottom: '40px',
          textAlign: 'right',
          padding: '20px 0',
        }}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              lineHeight: '22px',
              fontWeight: '600',
              color: '#B1A476',
              padding: '6px 20px',
              backgroundColor: '#DAD7DF',
              marginBottom: '17px',
              alignSelf: 'flex-end',
            }}
          >
            {t('home_page.offer_title_3')}
          </Typography>
          <Button
            sx={{
              fontSize: '18px',
              color: 'secondary.main',
              fontWeight: '300',
              textTransform: 'inherit',
              borderRadius: '10px',
              backgroundColor: '#ffffff',
              boxShadow: 'none',
              marginRight: '8px',
            }}
            variant="contained"
          >
            {t('pre-order')}
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-30px',
            left: '0px',
            width: '200px',
            height: '200px',
            zIndex: '2',
          }}
        >
          <Box
            component="img"
            src={image3}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            zIndex: '-1',
          }}
        >
          <Box
            sx={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            component="img"
            src={bg3}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileOffers;
