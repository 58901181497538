import { Box, Container } from '@mui/material';
import React, { FC } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import OrderResult from 'components/order/OrderResult/OrderResult';

/**
 * Page displayed on successful order payment
 */
const PaymentSuccess: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('order_id');

  if (orderId === null) {
    navigate('/error');
    throw new Error(
      'No order id passed to the PaymentSuccess page after have been order placed and payed.'
    );
  }

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <OrderResult
            orderNumber={orderId}
            orderMessage="Order paid and placed"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentSuccess;
