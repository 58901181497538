import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectApiKey } from 'store/selectors/authSelectors';
import { selectCityNameById } from 'store/selectors/citySelectors';

interface FormFields {
  [x: string]: any;
}

const useCheckDeliveryQueryData = (formValues: FormFields) => {
  const api_key = useAppSelector<string>(selectApiKey);
  const cityName = useAppSelector<string>(selectCityNameById(formValues.city));

  return {
    api_key,
    body: {
      city_id: formValues.city,
      city_name: cityName,
      street: formValues.street,
      house: formValues.house,
      building: formValues.building,
      corpus: formValues.corpus || null,
      apartment: formValues.apartment || null,
      entrance: formValues.entrance || null,
      floor: formValues.floor || null,
      latitude: 0,
      longitude: 0,
    },
  };
};

export default useCheckDeliveryQueryData;
