import React, { FC, useMemo, memo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Box, Container, Link } from '@mui/material';
import {
  Nav,
  BasketButton,
  LoginButton,
  Locality,
  Language,
} from 'components/generic/Header';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettings } from 'store/selectors/appSelectors';
import { useMediaQuery } from 'react-responsive';
import { SelectCityModal } from 'components/modals';
import { City } from 'types/City';
import { selectCities } from 'store/selectors/citySelectors';
import { useCookies } from 'react-cookie';
import { useLanguages } from 'hooks';

const Header: FC = () => {
  const { LOGO_LIGHT } = useAppSelector(selectAppSettings);
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const [cookie, setCookie] = useCookies(['locality']);
  const cities = useAppSelector<City[]>(selectCities);
  const languages = useLanguages();

  useEffect(() => {
    if (!cookie.locality && cities.length === 1) {
      setCookie('locality', cities[0]?.id);
    }
  }, [cookie.locality, cities]);

  const isWelcomeMode = useMemo(() => {
    if (cookie.locality || cities.length <= 1) {
      return false;
    }
    return true;
  }, [cookie.locality, cities]);

  return (
    <>
      <AppBar
        color="inherit"
        sx={{
          position: 'fixed',
          top: '0px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {isMobile ? (
          <Box sx={{ height: '30px' }} />
        ) : (
          <>
            {cities.length < 2 && languages.length <= 1 ? (
              <Box sx={{ height: '30px' }} />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    backgroundColor: 'primary.main',
                    zIndex: '-1',
                    opacity: '0.2',
                  }}
                />
                <Container>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '30px',
                    }}
                  >
                    {cities.length > 1 && <Locality />}
                    {languages.length > 1 && <Language />}
                  </Box>
                </Container>
              </Box>
            )}
          </>
        )}

        <Container>
          <Toolbar
            disableGutters
            sx={{
              justifyContent: 'space-between',
              position: 'relative',
              alignItems: 'center',
              minHeight: { xs: '70px', md: '80px' },
            }}
          >
            <Link
              sx={{
                position: { xs: 'absolute', md: 'relative' },
                left: 0,
                right: 0,
                margin: 'auto',
                width: { xs: '150px', md: 'auto' },
                WebkitTapHighlightColor: 'transparent',
                textDecoration: 'none',
              }}
              to="/"
              component={NavLink}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                component="img"
                src={LOGO_LIGHT}
              />
            </Link>
            <Nav />
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <LoginButton />
              <BasketButton />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SelectCityModal isOpen={isWelcomeMode} onClose={() => null} />
    </>
  );
};

export default memo(Header);
