import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DatePicker, SelectField } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { useWorkTime } from 'hooks';
import { selectLocationCity } from 'store/selectors/citySelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

interface DateTimePickerProps {
  minDate?: Date;
}

const DateTimePicker: FC<DateTimePickerProps> = ({ minDate }) => {
  const { control, watch } = useFormContext();
  const dayValue = watch('day');
  const { t } = useTranslation();
  const selectedLocationCity = useAppSelector(selectLocationCity);
  const availableTime = useWorkTime(dayValue, selectedLocationCity);

  return (
    <Stack
      direction="row"
      sx={{ marginBottom: { xs: '13px', sm: '30px' }, width: '100%' }}
      spacing={1.6}
    >
      <DatePicker
        control={control}
        name="day"
        label={t('day')}
        minDate={minDate}
        required
      />

      <SelectField
        control={control}
        name="time"
        label={t('time')}
        required
        selectItem={availableTime}
        defaultValue=""
      />
    </Stack>
  );
};

export default DateTimePicker;
