import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { Switch } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useFormContext } from 'react-hook-form';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { Delivery, Pickup } from 'components/basket/Receiving';
import { addressSlice } from 'store/reducers/AddressSlice/AddressSlice';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks';
import { selectAppVersion } from 'store/selectors/appSelectors';
import { selectIsDelivery } from 'store/selectors/basketSelectors';

interface ReceivingProps {
  onAddressModalOpen: () => void;
  onAuthModalOpen: () => void;
}

const Receiving: FC<ReceivingProps> = ({
  onAddressModalOpen,
  onAuthModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { selectDeliveryType, addClientData, addComment } = orderSlice.actions;
  const { watch } = useFormContext();
  const selectedAddressId = watch('place');
  const nameValue = watch('name');
  const phoneValue = watch('phone');
  const commentValue = watch('comment');
  const name = useDebounce(nameValue, 500);
  const phone = useDebounce(phoneValue, 500);
  const comment = useDebounce(commentValue, 500);
  const { addSelectedAddressId } = addressSlice.actions;
  const isDelivery = useAppSelector(selectIsDelivery);
  const appVersion = useAppSelector(selectAppVersion);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      addClientData({
        name,
        phone,
        app_version: appVersion,
      })
    );
  }, [phone, name]);

  useEffect(() => {
    dispatch(addSelectedAddressId(selectedAddressId));
  }, [selectedAddressId]);

  useEffect(() => {
    dispatch(addComment(comment));
  }, [comment]);

  const handleChangeReceiving = (deliveryValue: boolean) => () => {
    dispatch(selectDeliveryType(Number(deliveryValue)));
  };

  return (
    <Box sx={{ padding: { xs: '0px', md: '0 25px' } }}>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '300px' },
          marginBottom: { xs: '14px', sm: '40px' },
        }}
      >
        <Switch
          firstValue={t('delivery')}
          secondValue={t('pickup')}
          value={!isDelivery}
          setValue={handleChangeReceiving}
        />
      </Box>

      {isDelivery ? (
        <Delivery
          onAddressModalOpen={onAddressModalOpen}
          onAuthModalOpen={onAuthModalOpen}
        />
      ) : (
        <Pickup />
      )}
    </Box>
  );
};

export default Receiving;
