import React, { FC, useMemo, memo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BackButton, TextField } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAuthIsLoading } from 'store/selectors/isLoadingSelectors';
import { useTranslation } from 'react-i18next';
import { selectAuthType } from 'store/selectors/appSelectors';

interface StepTwoProps {
  isLoadingName: boolean;
  isTimer: boolean;
  timer: number;
  currentEmail: string;
  prevStep: () => void;
  handleGetCode: () => void;
}

const StepTwo: FC<StepTwoProps> = ({
  isLoadingName,
  isTimer,
  timer,
  prevStep,
  currentEmail,
  handleGetCode,
}) => {
  const { control } = useFormContext();
  const isLoading = useAppSelector(selectAuthIsLoading);
  const authType = useAppSelector(selectAuthType);
  const { t } = useTranslation();

  const hint = useMemo(() => {
    if (authType === 1) {
      return t('auth_tip_4');
    }
    return t('auth_tip_3');
  }, [authType]);

  return (
    <Box sx={{ padding: '100px 20px 20px 20px' }}>
      <Box
        sx={{ position: 'fixed', top: '24px', left: '20px', zIndex: '100' }}
        onClick={prevStep}
      >
        <BackButton direction="down" />
      </Box>
      <Box>
        <Typography sx={{ marginBottom: '11px' }} variant="h4">
          {t('confirmation_code')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '17px',
            lineHeight: '21px',
          }}
        >
          {hint} {currentEmail}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '50px' }}>
        <TextField
          control={control}
          name="code"
          label={t('input_code')}
          mask="9999"
          maskPlaceholder={null}
          required
          loading={isLoadingName || isLoading}
        />
      </Box>
      <Box sx={{ marginBottom: '25px' }}>
        {isTimer ? (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '18px',
              color: 'secondary.main',
            }}
          >
            {t('try_to_send_again', { timer })}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '18px',
              color: 'secondary.main',
              alignSelf: 'flex-start',
            }}
            onClick={handleGetCode}
          >
            {t('send_again')}
          </Typography>
        )}
      </Box>

      <Button
        sx={{
          padding: '15px 0',
          textTransform: 'inherit',
          fontSize: '18px',
          lineHeight: '21px',
          borderRadius: '6px',
          boxShadow: 'none',
          fontWeight: '300',
          color: 'secondary.main',
        }}
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
      >
        {t('continue')}
      </Button>
    </Box>
  );
};

export default memo(StepTwo);
