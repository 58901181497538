import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { AuthSteps } from 'components/modals/AuthModal/Drawer';
import { Portal } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { useWindowDimensions } from 'hooks/menu';
import { useDisableBodyScroll, useDelayUnmount } from 'hooks';
import { FormProvider } from 'react-hook-form';

interface DrawerProps {
  isLoadingName: boolean;
  step: number;
  isTimer: boolean;
  timer: number;
  currentTypeData: string;
  methods: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  nextStep: () => void;
  prevStep: () => void;
  handleGetCode: () => void;
}

const Drawer: FC<DrawerProps> = ({
  isLoadingName,
  step,
  isTimer,
  timer,
  currentTypeData,
  methods,
  isOpen,
  onClose,
  onSubmit,
  nextStep,
  prevStep,
  handleGetCode,
}) => {
  useDisableBodyScroll(isOpen);
  const isMount = useDelayUnmount(isOpen, 500);

  const { pageHeight } = useWindowDimensions();

  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
    opacity: 0,
  }));

  const open = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };

  const close = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      movement: [, my],
      cancel,
    }) => {
      if (my < 0) cancel();

      if (last) {
        if (my > pageHeight * 0.5 || (vy > 0.5 && dy > 0)) {
          close();
        } else {
          open();
        }
      } else api.start({ y: my, immediate: true });
    },
    {
      axis: 'y',
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            {...bind()}
            style={{
              touchAction: 'none',
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            <Box component={FormProvider} {...methods}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                component="form"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <AuthSteps
                  isLoadingName={isLoadingName}
                  step={step}
                  isTimer={isTimer}
                  timer={timer}
                  currentTypeData={currentTypeData}
                  onClose={onClose}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleGetCode={handleGetCode}
                />
              </Box>
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;
