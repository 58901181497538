import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBonus } from 'store/selectors/accountSelectors';
import { useAuth } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectAppSettings } from 'store/selectors/appSelectors';

const Bonuses: FC = () => {
  const bonuses = useAppSelector(selectBonus);
  const { BONUS_PROGRAM } = useAppSelector(selectAppSettings);
  const { isAuth } = useAuth();
  const { t } = useTranslation();

  if (!isAuth || !BONUS_PROGRAM) {
    return null;
  }

  return (
    <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '19px',
          fontWeight: '600',
          color: 'secondary.main',
        }}
      >
        {bonuses}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '19px',
          fontWeight: '400',
        }}
      >
        {t('bonuses')}
      </Typography>
    </Stack>
  );
};

export default Bonuses;
