import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Modal, Drawer } from 'components/modals/AuthModal';
import { useMediaQuery } from 'react-responsive';
import { useAuth, useTimer } from 'hooks';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { authSlice } from 'store/reducers/AuthSlice/AuthSlice';
import { selectAuthType } from 'store/selectors/appSelectors';
import { verifyCode } from 'store/reducers/AuthSlice/ActionCreators';
import { useTranslation } from 'react-i18next';
import {
  selectApiKey,
  selectAuthCodeResponse,
  selectAuthVerifyCodeResponse,
} from 'store/selectors/authSelectors';
import { selectName } from 'store/selectors/accountSelectors';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal: FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const dispatch = useAppDispatch();
  const { getAuthCode } = useAuth();
  const methods = useForm();
  const { watch, setError, clearErrors } = methods;
  const loginValue = watch('login');
  const codeValue = watch('code');
  const nameValue = watch('first_name');
  const [step, setStep] = useState(1);
  const { addApiKey, clearAuthResponse } = authSlice.actions;
  const authType = useAppSelector(selectAuthType);
  const { t } = useTranslation();
  const { uid } = useAppSelector(selectAuthCodeResponse);
  const name = useAppSelector(selectName);
  const { isSuccess, reason } = useAppSelector(selectAuthVerifyCodeResponse);
  const [isLoadingName, setIsLoadingName] = useState<boolean>(false);
  const { isTimer, timer, setTimer } = useTimer(30);
  const api_key = useAppSelector(selectApiKey);

  const logIn = (code: string, uidCode: string) => {
    dispatch(verifyCode({ uid: uidCode, code }))
      .unwrap()
      .then((result) => {
        if (!result.success && reason === 'WRONG_AUTH_CODE') {
          setError('code', {
            type: 'server',
            message: t('invalid_code'),
          });
        }
        if (result.success && result.api_key) {
          dispatch(addApiKey(result.api_key));
        }
      });
  };

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const prevStep = useCallback(() => {
    dispatch(clearAuthResponse());
    setStep(step - 1);
  }, [step]);

  const onSubmit = () => {
    if (step === 1) {
      getAuthCode(loginValue, authType);
    }
    if (step === 2) {
      logIn(codeValue, uid);
    }
    if (step === 3) {
      dispatch(updateAccountData({ api_key, body: { nameValue } }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            onClose();
          } else {
            setError('first_name', {
              type: 'server',
              message: 'some error',
            });
          }
        });
    }
  };

  const currentTypeData = useMemo(() => {
    return loginValue;
  }, [loginValue]);

  useEffect(() => {
    if (codeValue && codeValue.length >= 4) {
      logIn(codeValue, uid);
    }
    if (!codeValue || codeValue.length < 4) {
      clearErrors();
    }
  }, [codeValue, uid]);

  useEffect(() => {
    if (isSuccess) {
      setIsLoadingName(true);
      setTimeout(() => {
        setIsLoadingName(false);
        if (name) {
          onClose();
        } else {
          nextStep();
        }
      }, 2000);
    }
  }, [isSuccess, name]);

  const handleGetCode = () => {
    getAuthCode(loginValue, authType);
    setTimer(30);
  };

  useEffect(() => {
    if (step === 3) {
      setTimer(30);
    }
  }, [step]);

  return (
    <>
      {isDrawer ? (
        <Drawer
          isLoadingName={isLoadingName}
          step={step}
          isTimer={isTimer}
          timer={timer}
          handleGetCode={handleGetCode}
          currentTypeData={currentTypeData}
          methods={methods}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ) : (
        <Modal
          isLoadingName={isLoadingName}
          step={step}
          isTimer={isTimer}
          timer={timer}
          handleGetCode={handleGetCode}
          currentTypeData={currentTypeData}
          methods={methods}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
    </>
  );
};

export default AuthModal;
