import { createSelector } from '@reduxjs/toolkit';
import { SelectData } from 'types/SelectData';
import { RootState } from '../store';
import { selectCityId } from './appSelectors';

const selectRestaurantReducer = (state: RootState) => state.restaurantReducer;
/**
 * Returns available restaurants list for selet field
 */
export const selectAvailableRestaurantsList = createSelector(
  [selectRestaurantReducer, selectCityId],
  (restaurantReducer, cityId) => {
    const availableRestaurants = restaurantReducer.restaurantList.filter(
      (restaurant) => restaurant.city.id === cityId
    );
    return availableRestaurants.map((restaurant) => {
      return { label: restaurant.name, value: restaurant.id } as SelectData;
    });
  }
);
/**
 * Returns available restaurants
 */
export const selectAvailableRestaurants = createSelector(
  [selectRestaurantReducer, selectCityId],
  (restaurantReducer, cityId) =>
    restaurantReducer.restaurantList.filter(
      (restaurant) => restaurant.city.id === cityId
    )
);
/**
 * Returns the first available restaurant to display data
 */
export const selectFirstAvailableRestaurant = createSelector(
  [selectRestaurantReducer, selectCityId],
  (restaurantReducer, cityId) => {
    const firstRestaurant = restaurantReducer.restaurantList.filter(
      (restaurant) => restaurant.city.id === cityId
    )[0];
    return {
      name: firstRestaurant?.name,
      address: firstRestaurant?.address,
      description: firstRestaurant?.info,
      phone: firstRestaurant?.phones,
      email: firstRestaurant?.email,
      operating_time: firstRestaurant?.operating_time,
      operating_time_fri: firstRestaurant?.operating_time_fri,
      operating_time_sat: firstRestaurant?.operating_time_sat,
      operating_time_sun: firstRestaurant?.operating_time_sun,
      longitude: firstRestaurant?.longitude,
      latitude: firstRestaurant?.latitude,
    };
  }
);
